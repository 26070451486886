#AdditorLandingRoot {
  &.theme--white {
    color: $gray900;
    background: $white;

    .root__header {
      &__priority {
        color: $Brand-500;
      }
    }
  }

  &.theme--purple {
    color: $white;
    background: $Brand-500;

    .root__header {
      &__priority {
        color: $white;
      }
      &__namelogo > svg path {
        fill: $white;
      }
    }
  }
}

@media (max-width: $sm-break-max) {
  #AdditorLandingRoot {
    &.theme--purple {
      color: $gray900;
      background: $white;

      .root__header {
        &__priority {
          color: $Brand-500;
        }
        &__namelogo > svg path {
          fill: $Brand-500;
        }
      }
    }
  }
}
