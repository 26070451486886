.col {
  float: left;
  box-sizing: border-box;
}
.col-right {
  float: right;
  box-sizing: border-box;
}
.col-1 {
  width: 8.33333%;
}
.col-2 {
  width: 16.66667%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33333%;
}
.col-5 {
  width: 41.66667%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33333%;
}
.col-8 {
  width: 66.66667%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33333%;
}
.col-11 {
  width: 91.66667%;
}
.col-12 {
  width: 100%;
}
.width-full {
  width: 100%;
}
.height-full {
  height: 100%;
}
.width-fifth {
  width: 20%;
}

@media (max-width: 39.99rem) {
  .xs-col {
    float: left;
    box-sizing: border-box;
  }
  .xs-col-right {
    float: right;
    box-sizing: border-box;
  }
  .xs-col-1 {
    width: 8.33333%;
  }
  .xs-col-2 {
    width: 16.66667%;
  }
  .xs-col-3 {
    width: 25%;
  }
  .xs-col-4 {
    width: 33.33333%;
  }
  .xs-col-5 {
    width: 41.66667%;
  }
  .xs-col-6 {
    width: 50%;
  }
  .xs-col-7 {
    width: 58.33333%;
  }
  .xs-col-8 {
    width: 66.66667%;
  }
  .xs-col-9 {
    width: 75%;
  }
  .xs-col-10 {
    width: 83.33333%;
  }
  .xs-col-11 {
    width: 91.66667%;
  }
  .xs-col-12 {
    width: 100%;
  }
  .xs-width-full {
    width: 100%;
  }
  .xs-height-full {
    height: 100%;
  }
  .xs-width-fifth {
    width: 20%;
  }
}

@media (min-width: 40rem) {
  .sm-col {
    float: left;
    box-sizing: border-box;
  }
  .sm-col-right {
    float: right;
    box-sizing: border-box;
  }
  .sm-col-1 {
    width: 8.33333%;
  }
  .sm-col-2 {
    width: 16.66667%;
  }
  .sm-col-3 {
    width: 25%;
  }
  .sm-col-4 {
    width: 33.33333%;
  }
  .sm-col-5 {
    width: 41.66667%;
  }
  .sm-col-6 {
    width: 50%;
  }
  .sm-col-7 {
    width: 58.33333%;
  }
  .sm-col-8 {
    width: 66.66667%;
  }
  .sm-col-9 {
    width: 75%;
  }
  .sm-col-10 {
    width: 83.33333%;
  }
  .sm-col-11 {
    width: 91.66667%;
  }
  .sm-col-12 {
    width: 100%;
  }
  .sm-width-full {
    width: 100%;
  }
  .sm-height-full {
    height: 100%;
  }
  .sm-width-fifth {
    width: 20%;
  }
}
@media (min-width: 52rem) {
  .md-col {
    float: left;
    box-sizing: border-box;
  }
  .md-col-right {
    float: right;
    box-sizing: border-box;
  }
  .md-col-1 {
    width: 8.33333%;
  }
  .md-col-2 {
    width: 16.66667%;
  }
  .md-col-3 {
    width: 25%;
  }
  .md-col-4 {
    width: 33.33333%;
  }
  .md-col-5 {
    width: 41.66667%;
  }
  .md-col-6 {
    width: 50%;
  }
  .md-col-7 {
    width: 58.33333%;
  }
  .md-col-8 {
    width: 66.66667%;
  }
  .md-col-9 {
    width: 75%;
  }
  .md-col-10 {
    width: 83.33333%;
  }
  .md-col-11 {
    width: 91.66667%;
  }
  .md-col-12 {
    width: 100%;
  }
  .md-width-full {
    width: 100%;
  }
  .md-height-full {
    height: 100%;
  }
  .md-width-fifth {
    width: 20%;
  }
}
@media (min-width: 72rem) {
  .lg-col {
    float: left;
    box-sizing: border-box;
  }
  .lg-col-right {
    float: right;
    box-sizing: border-box;
  }
  .lg-col-1 {
    width: 8.33333%;
  }
  .lg-col-2 {
    width: 16.66667%;
  }
  .lg-col-3 {
    width: 25%;
  }
  .lg-col-4 {
    width: 33.33333%;
  }
  .lg-col-5 {
    width: 41.66667%;
  }
  .lg-col-6 {
    width: 50%;
  }
  .lg-col-7 {
    width: 58.33333%;
  }
  .lg-col-8 {
    width: 66.66667%;
  }
  .lg-col-9 {
    width: 75%;
  }
  .lg-col-10 {
    width: 83.33333%;
  }
  .lg-col-11 {
    width: 91.66667%;
  }
  .lg-col-12 {
    width: 100%;
  }
  .lg-width-full {
    width: 100%;
  }
  .lg-height-full {
    height: 100%;
  }
  .lg-width-fifth {
    width: 20%;
  }
}
.p0 {
  padding: 0;
}
.pt0 {
  padding-top: 0;
}
.pr0 {
  padding-right: 0;
}
.pb0 {
  padding-bottom: 0;
}
.pl0 {
  padding-left: 0;
}
.px0 {
  padding-left: 0;
  padding-right: 0;
}
.py0 {
  padding-top: 0;
  padding-bottom: 0;
}
.p-half {
  padding: 0.25rem;
}
.pt-half {
  padding-top: 0.25rem;
}
.pr-half {
  padding-right: 0.25rem;
}
.pb-half {
  padding-bottom: 0.25rem;
}
.pl-half {
  padding-left: 0.25rem;
}
.px-half {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.py-half {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.p1 {
  padding: 0.5rem;
}
.pt1 {
  padding-top: 0.5rem;
}
.pr1 {
  padding-right: 0.5rem;
}
.pb1 {
  padding-bottom: 0.5rem;
}
.pl1 {
  padding-left: 0.5rem;
}
.py1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p2 {
  padding: 1rem;
}
.pt2 {
  padding-top: 1rem;
}
.pr2 {
  padding-right: 1rem;
}
.pb2 {
  padding-bottom: 1rem;
}
.pl2 {
  padding-left: 1rem;
}
.py2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px2 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.p3 {
  padding: 2rem;
}
.pt3 {
  padding-top: 2rem;
}
.pr3 {
  padding-right: 2rem;
}
.pb3 {
  padding-bottom: 2rem;
}
.pl3 {
  padding-left: 2rem;
}
.py3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px3 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.p4 {
  padding: 4rem;
}
.pt4 {
  padding-top: 4rem;
}
.pr4 {
  padding-right: 4rem;
}
.pb4 {
  padding-bottom: 4rem;
}
.pl4 {
  padding-left: 4rem;
}
.py4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.px4 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.p5 {
  padding: 8rem;
}
.pt5 {
  padding-top: 8rem;
}
.pr5 {
  padding-right: 8rem;
}
.pb5 {
  padding-bottom: 8rem;
}
.pl5 {
  padding-left: 8rem;
}
.py5 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.px5 {
  padding-left: 8rem;
  padding-right: 8rem;
}
.m0 {
  margin: 0;
}
.mt0 {
  margin-top: 0;
}
.mr0 {
  margin-right: 0;
}
.mb0 {
  margin-bottom: 0;
}
.ml0 {
  margin-left: 0;
}
.mx0 {
  margin-left: 0;
  margin-right: 0;
}
.my0 {
  margin-top: 0;
  margin-bottom: 0;
}
.m-half {
  margin: 0.25rem;
}
.mt-half {
  margin-top: 0.25rem;
}
.mr-half {
  margin-right: 0.25rem;
}
.mb-half {
  margin-bottom: 0.25rem;
}
.ml-half {
  margin-left: 0.25rem;
}
.mx-half {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.my-half {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.m1 {
  margin: 0.5rem;
}
.mt1 {
  margin-top: 0.5rem;
}
.mr1 {
  margin-right: 0.5rem;
}
.mb1 {
  margin-bottom: 0.5rem;
}
.ml1 {
  margin-left: 0.5rem;
}
.mx1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.m2 {
  margin: 1rem;
}
.mt2 {
  margin-top: 1rem;
}
.mr2 {
  margin-right: 1rem;
}
.mb2 {
  margin-bottom: 1rem;
}
.ml2 {
  margin-left: 1rem;
}
.mx2 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m3 {
  margin: 2rem;
}
.mt3 {
  margin-top: 2rem;
}
.mr3 {
  margin-right: 2rem;
}
.mb3 {
  margin-bottom: 2rem;
}
.ml3 {
  margin-left: 2rem;
}
.mx3 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.my3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.m4 {
  margin: 4rem;
}
.mt4 {
  margin-top: 4rem;
}
.mr4 {
  margin-right: 4rem;
}
.mb4 {
  margin-bottom: 4rem;
}
.ml4 {
  margin-left: 4rem;
}
.mx4 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.my4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.m5 {
  margin: 8rem;
}
.mt5 {
  margin-top: 8rem;
}
.mr5 {
  margin-right: 8rem;
}
.mb5 {
  margin-bottom: 8rem;
}
.ml5 {
  margin-left: 8rem;
}
.mx5 {
  margin-left: 8rem;
  margin-right: 8rem;
}
.my5 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.mln1 {
  margin-left: -0.5rem;
}
.mln2 {
  margin-left: -1rem;
}
.mln3 {
  margin-left: -2rem;
}
.mln4 {
  margin-left: -4rem;
}
.mln5 {
  margin-top: -8rem;
}
.mtn1 {
  margin-top: -0.5rem;
}
.mtn2 {
  margin-top: -1rem;
}
.mtn3 {
  margin-top: -2rem;
}
.mtn4 {
  margin-top: -4rem;
}
.mtn5 {
  margin-top: -8rem;
}
.mxn1 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.mxn2 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.mxn3 {
  margin-left: -2rem;
  margin-right: -2rem;
}
.mxn4 {
  margin-left: -4rem;
  margin-right: -4rem;
}
.mxn5 {
  margin-left: -8rem;
  margin-right: -8rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 39.99rem) {
  .xs-p0 {
    padding: 0;
  }
  .xs-pt0 {
    padding-top: 0;
  }
  .xs-pr0 {
    padding-right: 0;
  }
  .xs-pb0 {
    padding-bottom: 0;
  }
  .xs-pl0 {
    padding-left: 0;
  }
  .xs-px0 {
    padding-left: 0;
    padding-right: 0;
  }
  .xs-py0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .xs-p-half {
    padding: 0.25rem;
  }
  .xs-pt-half {
    padding-top: 0.25rem;
  }
  .xs-pr-half {
    padding-right: 0.25rem;
  }
  .xs-pb-half {
    padding-bottom: 0.25rem;
  }
  .xs-pl-half {
    padding-left: 0.25rem;
  }
  .xs-px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .xs-py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .xs-p1 {
    padding: 0.5rem;
  }
  .xs-pt1 {
    padding-top: 0.5rem;
  }
  .xs-pr1 {
    padding-right: 0.5rem;
  }
  .xs-pb1 {
    padding-bottom: 0.5rem;
  }
  .xs-pl1 {
    padding-left: 0.5rem;
  }
  .xs-py1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .xs-px1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .xs-p2 {
    padding: 1rem;
  }
  .xs-pt2 {
    padding-top: 1rem;
  }
  .xs-pr2 {
    padding-right: 1rem;
  }
  .xs-pb2 {
    padding-bottom: 1rem;
  }
  .xs-pl2 {
    padding-left: 1rem;
  }
  .xs-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .xs-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xs-p3 {
    padding: 2rem;
  }
  .xs-pt3 {
    padding-top: 2rem;
  }
  .xs-pr3 {
    padding-right: 2rem;
  }
  .xs-pb3 {
    padding-bottom: 2rem;
  }
  .xs-pl3 {
    padding-left: 2rem;
  }
  .xs-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .xs-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .xs-p4 {
    padding: 4rem;
  }
  .xs-pt4 {
    padding-top: 4rem;
  }
  .xs-pr4 {
    padding-right: 4rem;
  }
  .xs-pb4 {
    padding-bottom: 4rem;
  }
  .xs-pl4 {
    padding-left: 4rem;
  }
  .xs-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .xs-px4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .xs-p5 {
    padding: 8rem;
  }
  .xs-pt5 {
    padding-top: 8rem;
  }
  .xs-pr5 {
    padding-right: 8rem;
  }
  .xs-pb5 {
    padding-bottom: 8rem;
  }
  .xs-pl5 {
    padding-left: 8rem;
  }
  .xs-py5 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .xs-px5 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .xs-m0 {
    margin: 0;
  }
  .xs-mt0 {
    margin-top: 0;
  }
  .xs-mr0 {
    margin-right: 0;
  }
  .xs-mb0 {
    margin-bottom: 0;
  }
  .xs-ml0 {
    margin-left: 0;
  }
  .xs-mx0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xs-my0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xs-m-half {
    margin: 0.25rem;
  }
  .xs-mt-half {
    margin-top: 0.25rem;
  }
  .xs-mr-half {
    margin-right: 0.25rem;
  }
  .xs-mb-half {
    margin-bottom: 0.25rem;
  }
  .xs-ml-half {
    margin-left: 0.25rem;
  }
  .xs-mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .xs-my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .xs-m1 {
    margin: 0.5rem;
  }
  .xs-mt1 {
    margin-top: 0.5rem;
  }
  .xs-mr1 {
    margin-right: 0.5rem;
  }
  .xs-mb1 {
    margin-bottom: 0.5rem;
  }
  .xs-ml1 {
    margin-left: 0.5rem;
  }
  .xs-mx1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .xs-my1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .xs-m2 {
    margin: 1rem;
  }
  .xs-mt2 {
    margin-top: 1rem;
  }
  .xs-mr2 {
    margin-right: 1rem;
  }
  .xs-mb2 {
    margin-bottom: 1rem;
  }
  .xs-ml2 {
    margin-left: 1rem;
  }
  .xs-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .xs-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .xs-m3 {
    margin: 2rem;
  }
  .xs-mt3 {
    margin-top: 2rem;
  }
  .xs-mr3 {
    margin-right: 2rem;
  }
  .xs-mb3 {
    margin-bottom: 2rem;
  }
  .xs-ml3 {
    margin-left: 2rem;
  }
  .xs-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .xs-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .xs-m4 {
    margin: 4rem;
  }
  .xs-mt4 {
    margin-top: 4rem;
  }
  .xs-mr4 {
    margin-right: 4rem;
  }
  .xs-mb4 {
    margin-bottom: 4rem;
  }
  .xs-ml4 {
    margin-left: 4rem;
  }
  .xs-mx4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .xs-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .xs-m5 {
    margin: 8rem;
  }
  .xs-mt5 {
    margin-top: 8rem;
  }
  .xs-mr5 {
    margin-right: 8rem;
  }
  .xs-mb5 {
    margin-bottom: 8rem;
  }
  .xs-ml5 {
    margin-left: 8rem;
  }
  .xs-mx5 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .xs-my5 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .xs-mln1 {
    margin-left: -0.5rem;
  }
  .xs-mln2 {
    margin-left: -1rem;
  }
  .xs-mln3 {
    margin-left: -2rem;
  }
  .xs-mln4 {
    margin-left: -4rem;
  }
  .xs-mln5 {
    margin-top: -8rem;
  }
  .xs-mtn1 {
    margin-top: -0.5rem;
  }
  .xs-mtn2 {
    margin-top: -1rem;
  }
  .xs-mtn3 {
    margin-top: -2rem;
  }
  .xs-mtn4 {
    margin-top: -4rem;
  }
  .xs-mtn5 {
    margin-top: -8rem;
  }
  .xs-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .xs-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .xs-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .xs-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .xs-mxn5 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .xs-ml-auto {
    margin-left: auto;
  }
  .xs-mr-auto {
    margin-right: auto;
  }
  .xs-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 40rem) and (max-width: 51.99rem) {
  .sm-p0 {
    padding: 0;
  }
  .sm-pt0 {
    padding-top: 0;
  }
  .sm-pr0 {
    padding-right: 0;
  }
  .sm-pb0 {
    padding-bottom: 0;
  }
  .sm-pl0 {
    padding-left: 0;
  }
  .sm-px0 {
    padding-left: 0;
    padding-right: 0;
  }
  .sm-py0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sm-p-half {
    padding: 0.25rem;
  }
  .sm-pt-half {
    padding-top: 0.25rem;
  }
  .sm-pr-half {
    padding-right: 0.25rem;
  }
  .sm-pb-half {
    padding-bottom: 0.25rem;
  }
  .sm-pl-half {
    padding-left: 0.25rem;
  }
  .sm-px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .sm-py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .sm-p1 {
    padding: 0.5rem;
  }
  .sm-pt1 {
    padding-top: 0.5rem;
  }
  .sm-pr1 {
    padding-right: 0.5rem;
  }
  .sm-pb1 {
    padding-bottom: 0.5rem;
  }
  .sm-pl1 {
    padding-left: 0.5rem;
  }
  .sm-py1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm-px1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm-p2 {
    padding: 1rem;
  }
  .sm-pt2 {
    padding-top: 1rem;
  }
  .sm-pr2 {
    padding-right: 1rem;
  }
  .sm-pb2 {
    padding-bottom: 1rem;
  }
  .sm-pl2 {
    padding-left: 1rem;
  }
  .sm-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm-p3 {
    padding: 2rem;
  }
  .sm-pt3 {
    padding-top: 2rem;
  }
  .sm-pr3 {
    padding-right: 2rem;
  }
  .sm-pb3 {
    padding-bottom: 2rem;
  }
  .sm-pl3 {
    padding-left: 2rem;
  }
  .sm-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sm-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .sm-p4 {
    padding: 4rem;
  }
  .sm-pt4 {
    padding-top: 4rem;
  }
  .sm-pr4 {
    padding-right: 4rem;
  }
  .sm-pb4 {
    padding-bottom: 4rem;
  }
  .sm-pl4 {
    padding-left: 4rem;
  }
  .sm-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .sm-px4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .sm-p5 {
    padding: 8rem;
  }
  .sm-pt5 {
    padding-top: 8rem;
  }
  .sm-pr5 {
    padding-right: 8rem;
  }
  .sm-pb5 {
    padding-bottom: 8rem;
  }
  .sm-pl5 {
    padding-left: 8rem;
  }
  .sm-py5 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .sm-px5 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .sm-m0 {
    margin: 0;
  }
  .sm-mt0 {
    margin-top: 0;
  }
  .sm-mr0 {
    margin-right: 0;
  }
  .sm-mb0 {
    margin-bottom: 0;
  }
  .sm-ml0 {
    margin-left: 0;
  }
  .sm-mx0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm-my0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm-m-half {
    margin: 0.25rem;
  }
  .sm-mt-half {
    margin-top: 0.25rem;
  }
  .sm-mr-half {
    margin-right: 0.25rem;
  }
  .sm-mb-half {
    margin-bottom: 0.25rem;
  }
  .sm-ml-half {
    margin-left: 0.25rem;
  }
  .sm-mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .sm-my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .sm-m1 {
    margin: 0.5rem;
  }
  .sm-mt1 {
    margin-top: 0.5rem;
  }
  .sm-mr1 {
    margin-right: 0.5rem;
  }
  .sm-mb1 {
    margin-bottom: 0.5rem;
  }
  .sm-ml1 {
    margin-left: 0.5rem;
  }
  .sm-mx1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sm-my1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .sm-m2 {
    margin: 1rem;
  }
  .sm-mt2 {
    margin-top: 1rem;
  }
  .sm-mr2 {
    margin-right: 1rem;
  }
  .sm-mb2 {
    margin-bottom: 1rem;
  }
  .sm-ml2 {
    margin-left: 1rem;
  }
  .sm-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sm-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .sm-m3 {
    margin: 2rem;
  }
  .sm-mt3 {
    margin-top: 2rem;
  }
  .sm-mr3 {
    margin-right: 2rem;
  }
  .sm-mb3 {
    margin-bottom: 2rem;
  }
  .sm-ml3 {
    margin-left: 2rem;
  }
  .sm-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .sm-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .sm-m4 {
    margin: 4rem;
  }
  .sm-mt4 {
    margin-top: 4rem;
  }
  .sm-mr4 {
    margin-right: 4rem;
  }
  .sm-mb4 {
    margin-bottom: 4rem;
  }
  .sm-ml4 {
    margin-left: 4rem;
  }
  .sm-mx4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .sm-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .sm-m5 {
    margin: 8rem;
  }
  .sm-mt5 {
    margin-top: 8rem;
  }
  .sm-mr5 {
    margin-right: 8rem;
  }
  .sm-mb5 {
    margin-bottom: 8rem;
  }
  .sm-ml5 {
    margin-left: 8rem;
  }
  .sm-mx5 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .sm-my5 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .sm-mln1 {
    margin-left: -0.5rem;
  }
  .sm-mln2 {
    margin-left: -1rem;
  }
  .sm-mln3 {
    margin-left: -2rem;
  }
  .sm-mln4 {
    margin-left: -4rem;
  }
  .sm-mln5 {
    margin-top: -8rem;
  }
  .sm-mtn1 {
    margin-top: -0.5rem;
  }
  .sm-mtn2 {
    margin-top: -1rem;
  }
  .sm-mtn3 {
    margin-top: -2rem;
  }
  .sm-mtn4 {
    margin-top: -4rem;
  }
  .sm-mtn5 {
    margin-top: -8rem;
  }
  .sm-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .sm-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .sm-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .sm-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .sm-mxn5 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .sm-ml-auto {
    margin-left: auto;
  }
  .sm-mr-auto {
    margin-right: auto;
  }
  .sm-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 52rem) and (max-width: 71.99rem) {
  .md-p0 {
    padding: 0;
  }
  .md-pt0 {
    padding-top: 0;
  }
  .md-pr0 {
    padding-right: 0;
  }
  .md-pb0 {
    padding-bottom: 0;
  }
  .md-pl0 {
    padding-left: 0;
  }
  .md-px0 {
    padding-left: 0;
    padding-right: 0;
  }
  .md-py0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .md-p-half {
    padding: 0.25rem;
  }
  .md-pt-half {
    padding-top: 0.25rem;
  }
  .md-pr-half {
    padding-right: 0.25rem;
  }
  .md-pb-half {
    padding-bottom: 0.25rem;
  }
  .md-pl-half {
    padding-left: 0.25rem;
  }
  .md-px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .md-py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .md-p1 {
    padding: 0.5rem;
  }
  .md-pt1 {
    padding-top: 0.5rem;
  }
  .md-pr1 {
    padding-right: 0.5rem;
  }
  .md-pb1 {
    padding-bottom: 0.5rem;
  }
  .md-pl1 {
    padding-left: 0.5rem;
  }
  .md-py1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .md-px1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .md-p2 {
    padding: 1rem;
  }
  .md-pt2 {
    padding-top: 1rem;
  }
  .md-pr2 {
    padding-right: 1rem;
  }
  .md-pb2 {
    padding-bottom: 1rem;
  }
  .md-pl2 {
    padding-left: 1rem;
  }
  .md-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .md-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .md-p3 {
    padding: 2rem;
  }
  .md-pt3 {
    padding-top: 2rem;
  }
  .md-pr3 {
    padding-right: 2rem;
  }
  .md-pb3 {
    padding-bottom: 2rem;
  }
  .md-pl3 {
    padding-left: 2rem;
  }
  .md-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .md-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .md-p4 {
    padding: 4rem;
  }
  .md-pt4 {
    padding-top: 4rem;
  }
  .md-pr4 {
    padding-right: 4rem;
  }
  .md-pb4 {
    padding-bottom: 4rem;
  }
  .md-pl4 {
    padding-left: 4rem;
  }
  .md-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .md-px4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .md-p5 {
    padding: 8rem;
  }
  .md-pt5 {
    padding-top: 8rem;
  }
  .md-pr5 {
    padding-right: 8rem;
  }
  .md-pb5 {
    padding-bottom: 8rem;
  }
  .md-pl5 {
    padding-left: 8rem;
  }
  .md-py5 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .md-px5 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .md-m0 {
    margin: 0;
  }
  .md-mt0 {
    margin-top: 0;
  }
  .md-mr0 {
    margin-right: 0;
  }
  .md-mb0 {
    margin-bottom: 0;
  }
  .md-ml0 {
    margin-left: 0;
  }
  .md-mx0 {
    margin-left: 0;
    margin-right: 0;
  }
  .md-my0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md-m-half {
    margin: 0.25rem;
  }
  .md-mt-half {
    margin-top: 0.25rem;
  }
  .md-mr-half {
    margin-right: 0.25rem;
  }
  .md-mb-half {
    margin-bottom: 0.25rem;
  }
  .md-ml-half {
    margin-left: 0.25rem;
  }
  .md-mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .md-my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .md-m1 {
    margin: 0.5rem;
  }
  .md-mt1 {
    margin-top: 0.5rem;
  }
  .md-mr1 {
    margin-right: 0.5rem;
  }
  .md-mb1 {
    margin-bottom: 0.5rem;
  }
  .md-ml1 {
    margin-left: 0.5rem;
  }
  .md-mx1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .md-my1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .md-m2 {
    margin: 1rem;
  }
  .md-mt2 {
    margin-top: 1rem;
  }
  .md-mr2 {
    margin-right: 1rem;
  }
  .md-mb2 {
    margin-bottom: 1rem;
  }
  .md-ml2 {
    margin-left: 1rem;
  }
  .md-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .md-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .md-m3 {
    margin: 2rem;
  }
  .md-mt3 {
    margin-top: 2rem;
  }
  .md-mr3 {
    margin-right: 2rem;
  }
  .md-mb3 {
    margin-bottom: 2rem;
  }
  .md-ml3 {
    margin-left: 2rem;
  }
  .md-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .md-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .md-m4 {
    margin: 4rem;
  }
  .md-mt4 {
    margin-top: 4rem;
  }
  .md-mr4 {
    margin-right: 4rem;
  }
  .md-mb4 {
    margin-bottom: 4rem;
  }
  .md-ml4 {
    margin-left: 4rem;
  }
  .md-mx4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .md-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .md-m5 {
    margin: 8rem;
  }
  .md-mt5 {
    margin-top: 8rem;
  }
  .md-mr5 {
    margin-right: 8rem;
  }
  .md-mb5 {
    margin-bottom: 8rem;
  }
  .md-ml5 {
    margin-left: 8rem;
  }
  .md-mx5 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .md-my5 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .md-mln1 {
    margin-left: -0.5rem;
  }
  .md-mln2 {
    margin-left: -1rem;
  }
  .md-mln3 {
    margin-left: -2rem;
  }
  .md-mln4 {
    margin-left: -4rem;
  }
  .md-mln5 {
    margin-top: -8rem;
  }
  .md-mtn1 {
    margin-top: -0.5rem;
  }
  .md-mtn2 {
    margin-top: -1rem;
  }
  .md-mtn3 {
    margin-top: -2rem;
  }
  .md-mtn4 {
    margin-top: -4rem;
  }
  .md-mtn5 {
    margin-top: -8rem;
  }
  .md-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .md-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .md-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .md-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .md-mxn5 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .md-ml-auto {
    margin-left: auto;
  }
  .md-mr-auto {
    margin-right: auto;
  }
  .md-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 72rem) {
  .lg-p0 {
    padding: 0;
  }
  .lg-pt0 {
    padding-top: 0;
  }
  .lg-pr0 {
    padding-right: 0;
  }
  .lg-pb0 {
    padding-bottom: 0;
  }
  .lg-pl0 {
    padding-left: 0;
  }
  .lg-px0 {
    padding-left: 0;
    padding-right: 0;
  }
  .lg-py0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .lg-p-half {
    padding: 0.25rem;
  }
  .lg-pt-half {
    padding-top: 0.25rem;
  }
  .lg-pr-half {
    padding-right: 0.25rem;
  }
  .lg-pb-half {
    padding-bottom: 0.25rem;
  }
  .lg-pl-half {
    padding-left: 0.25rem;
  }
  .lg-px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .lg-py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .lg-p1 {
    padding: 0.5rem;
  }
  .lg-pt1 {
    padding-top: 0.5rem;
  }
  .lg-pr1 {
    padding-right: 0.5rem;
  }
  .lg-pb1 {
    padding-bottom: 0.5rem;
  }
  .lg-pl1 {
    padding-left: 0.5rem;
  }
  .lg-py1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .lg-px1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .lg-p2 {
    padding: 1rem;
  }
  .lg-pt2 {
    padding-top: 1rem;
  }
  .lg-pr2 {
    padding-right: 1rem;
  }
  .lg-pb2 {
    padding-bottom: 1rem;
  }
  .lg-pl2 {
    padding-left: 1rem;
  }
  .lg-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .lg-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .lg-p3 {
    padding: 2rem;
  }
  .lg-pt3 {
    padding-top: 2rem;
  }
  .lg-pr3 {
    padding-right: 2rem;
  }
  .lg-pb3 {
    padding-bottom: 2rem;
  }
  .lg-pl3 {
    padding-left: 2rem;
  }
  .lg-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .lg-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lg-p4 {
    padding: 4rem;
  }
  .lg-pt4 {
    padding-top: 4rem;
  }
  .lg-pr4 {
    padding-right: 4rem;
  }
  .lg-pb4 {
    padding-bottom: 4rem;
  }
  .lg-pl4 {
    padding-left: 4rem;
  }
  .lg-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .lg-px4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .lg-p5 {
    padding: 8rem;
  }
  .lg-pt5 {
    padding-top: 8rem;
  }
  .lg-pr5 {
    padding-right: 8rem;
  }
  .lg-pb5 {
    padding-bottom: 8rem;
  }
  .lg-pl5 {
    padding-left: 8rem;
  }
  .lg-py5 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .lg-px5 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .lg-m0 {
    margin: 0;
  }
  .lg-mt0 {
    margin-top: 0;
  }
  .lg-mr0 {
    margin-right: 0;
  }
  .lg-mb0 {
    margin-bottom: 0;
  }
  .lg-ml0 {
    margin-left: 0;
  }
  .lg-mx0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg-my0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg-m-half {
    margin: 0.25rem;
  }
  .lg-mt-half {
    margin-top: 0.25rem;
  }
  .lg-mr-half {
    margin-right: 0.25rem;
  }
  .lg-mb-half {
    margin-bottom: 0.25rem;
  }
  .lg-ml-half {
    margin-left: 0.25rem;
  }
  .lg-mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .lg-my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .lg-m1 {
    margin: 0.5rem;
  }
  .lg-mt1 {
    margin-top: 0.5rem;
  }
  .lg-mr1 {
    margin-right: 0.5rem;
  }
  .lg-mb1 {
    margin-bottom: 0.5rem;
  }
  .lg-ml1 {
    margin-left: 0.5rem;
  }
  .lg-mx1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .lg-my1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .lg-m2 {
    margin: 1rem;
  }
  .lg-mt2 {
    margin-top: 1rem;
  }
  .lg-mr2 {
    margin-right: 1rem;
  }
  .lg-mb2 {
    margin-bottom: 1rem;
  }
  .lg-ml2 {
    margin-left: 1rem;
  }
  .lg-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .lg-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .lg-m3 {
    margin: 2rem;
  }
  .lg-mt3 {
    margin-top: 2rem;
  }
  .lg-mr3 {
    margin-right: 2rem;
  }
  .lg-mb3 {
    margin-bottom: 2rem;
  }
  .lg-ml3 {
    margin-left: 2rem;
  }
  .lg-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .lg-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .lg-m4 {
    margin: 4rem;
  }
  .lg-mt4 {
    margin-top: 4rem;
  }
  .lg-mr4 {
    margin-right: 4rem;
  }
  .lg-mb4 {
    margin-bottom: 4rem;
  }
  .lg-ml4 {
    margin-left: 4rem;
  }
  .lg-mx4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .lg-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .lg-m5 {
    margin: 8rem;
  }
  .lg-mt5 {
    margin-top: 8rem;
  }
  .lg-mr5 {
    margin-right: 8rem;
  }
  .lg-mb5 {
    margin-bottom: 8rem;
  }
  .lg-ml5 {
    margin-left: 8rem;
  }
  .lg-mx5 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .lg-my5 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .lg-mln1 {
    margin-left: -0.5rem;
  }
  .lg-mln2 {
    margin-left: -1rem;
  }
  .lg-mln3 {
    margin-left: -2rem;
  }
  .lg-mln4 {
    margin-left: -4rem;
  }
  .lg-mln5 {
    margin-top: -8rem;
  }
  .lg-mtn1 {
    margin-top: -0.5rem;
  }
  .lg-mtn2 {
    margin-top: -1rem;
  }
  .lg-mtn3 {
    margin-top: -2rem;
  }
  .lg-mtn4 {
    margin-top: -4rem;
  }
  .lg-mtn5 {
    margin-top: -8rem;
  }
  .lg-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .lg-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .lg-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .lg-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .lg-mxn5 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .lg-ml-auto {
    margin-left: auto;
  }
  .lg-mr-auto {
    margin-right: auto;
  }
  .lg-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
.unround {
  border-radius: 0;
}
.rounded {
  border-radius: 3px;
}
.rounded-right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.rounded-left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.rounded-top {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.rounded-bottom {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.rounded-big {
  border-radius: 6px;
}
.rounded-big-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.rounded-big-left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.rounded-big-top {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.rounded-big-top-left {
  border-top-left-radius: 6px;
}
.rounded-big-top-right {
  border-top-right-radius: 6px;
}
.rounded-big-bottom {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.rounded-huge {
  border-radius: 14px;
}
.rounded-huge-right {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}
.rounded-huge-left {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}
.rounded-huge-top {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.rounded-huge-top-left {
  border-top-left-radius: 14px;
}
.rounded-huge-top-right {
  border-top-right-radius: 14px;
}
.rounded-huge-bottom {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}
@media (min-width: 40rem) {
  .sm-rounded {
    border-radius: 3px;
  }
  .sm-rounded-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .sm-rounded-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .sm-rounded-top {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .sm-rounded-bottom {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .sm-rounded-big {
    border-radius: 6px;
  }
  .sm-rounded-big-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .sm-rounded-big-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .sm-rounded-big-top {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .sm-rounded-big-top-left {
    border-top-left-radius: 6px;
  }
  .sm-rounded-big-top-right {
    border-top-right-radius: 6px;
  }
  .sm-rounded-big-bottom {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .sm-rounded-huge {
    border-radius: 14px;
  }
  .sm-rounded-huge-right {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
  .sm-rounded-huge-left {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  .sm-rounded-huge-top {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
  }
  .sm-rounded-huge-top-left {
    border-top-left-radius: 14px;
  }
  .sm-rounded-huge-top-right {
    border-top-right-radius: 14px;
  }
  .sm-rounded-huge-bottom {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
  }
}
@media (min-width: 52rem) {
  .md-rounded {
    border-radius: 3px;
  }
  .md-rounded-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .md-rounded-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .md-rounded-top {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .md-rounded-bottom {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .md-rounded-big {
    border-radius: 6px;
  }
  .md-rounded-big-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .md-rounded-big-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .md-rounded-big-top {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .md-rounded-big-top-left {
    border-top-left-radius: 6px;
  }
  .md-rounded-big-top-right {
    border-top-right-radius: 6px;
  }
  .md-rounded-big-bottom {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .md-rounded-huge {
    border-radius: 14px;
  }
  .md-rounded-huge-right {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
  .md-rounded-huge-left {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  .md-rounded-huge-top {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
  }
  .md-rounded-huge-top-left {
    border-top-left-radius: 14px;
  }
  .md-rounded-huge-top-right {
    border-top-right-radius: 14px;
  }
  .md-rounded-huge-bottom {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
  }
}
@media (min-width: 72rem) {
  .lg-rounded {
    border-radius: 3px;
  }
  .lg-rounded-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .lg-rounded-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .lg-rounded-top {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .lg-rounded-bottom {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .lg-rounded-big {
    border-radius: 6px;
  }
  .lg-rounded-big-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .lg-rounded-big-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .lg-rounded-big-top {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .lg-rounded-big-top-left {
    border-top-left-radius: 6px;
  }
  .lg-rounded-big-top-right {
    border-top-right-radius: 6px;
  }
  .lg-rounded-big-bottom {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .lg-rounded-huge {
    border-radius: 14px;
  }
  .lg-rounded-huge-right {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
  .lg-rounded-huge-left {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  .lg-rounded-huge-top {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
  }
  .lg-rounded-huge-top-left {
    border-top-left-radius: 14px;
  }
  .lg-rounded-huge-top-right {
    border-top-right-radius: 14px;
  }
  .lg-rounded-huge-bottom {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
  }
}

.max-width-1 {
  max-width: 24rem;
}
.max-width-2 {
  max-width: 32rem;
}
.max-width-3 {
  max-width: 48rem;
}
.max-width-4 {
  max-width: 72rem;
}
.sm-vh-show {
  display: none;
}

@media (max-height: 600px) {
  .sm-vh-hide {
    display: none;
  }
  .sm-vh-show {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (max-width: 39.99rem) {
  .xs-top-0 {
    top: 0;
  }
  .xs-left-0 {
    left: 0;
  }
  .xs-right-0 {
    right: 0;
  }
  .xs-hide {
    display: none;
  }
  .xs-max-width-1 {
    max-width: 24rem;
  }
  .xs-max-width-2 {
    max-width: 32rem;
  }
  .xs-max-width-3 {
    max-width: 48rem;
  }
  .xs-max-width-4 {
    max-width: 72rem;
  }
}

@media (min-width: 40rem) and (max-width: 51.99rem) {
  .sm-top-0 {
    top: 0;
  }
  .sm-left-0 {
    left: 0;
  }
  .sm-right-0 {
    right: 0;
  }
  .sm-hide {
    display: none;
  }
  .sm-max-width-1 {
    max-width: 24rem;
  }
  .sm-max-width-2 {
    max-width: 32rem;
  }
  .sm-max-width-3 {
    max-width: 48rem;
  }
  .sm-max-width-4 {
    max-width: 72rem;
  }
}

@media (min-width: 52rem) and (max-width: 71.99rem) {
  .md-top-0 {
    top: 0;
  }
  .md-left-0 {
    left: 0;
  }
  .md-right-0 {
    right: 0;
  }
  .md-hide {
    display: none;
  }
  .md-max-width-1 {
    max-width: 24rem;
  }
  .md-max-width-2 {
    max-width: 32rem;
  }
  .md-max-width-3 {
    max-width: 48rem;
  }
  .md-max-width-4 {
    max-width: 72rem;
  }
}

@media (min-width: 72rem) {
  .lg-top-0 {
    top: 0;
  }
  .lg-left-0 {
    left: 0;
  }
  .lg-right-0 {
    right: 0;
  }
  .lg-hide {
    display: none;
  }
  .lg-max-width-1 {
    max-width: 24rem;
  }
  .lg-max-width-2 {
    max-width: 32rem;
  }
  .lg-max-width-3 {
    max-width: 48rem;
  }
  .lg-max-width-4 {
    max-width: 72rem;
  }
}

@media (max-width: 39.99rem) {
  .xs-h1 {
    font-size: $font-size-h1;
  }
  .xs-h2 {
    font-size: $font-size-h2;
  }
  .xs-h25 {
    font-size: $font-size-h25;
  }
  .xs-h3 {
    font-size: $font-size-h3;
  }
  .xs-h4 {
    font-size: $font-size-h4;
  }
  .xs-h5 {
    font-size: $font-size-h5;
  }
  .xs-h6 {
    font-size: $font-size-h6;
  }
  .xs-huge {
    font-size: $font-size-huge;
  }
  .xs-large {
    font-size: $font-size-large;
  }
  .xs-medium {
    font-size: $font-size-medium;
  }
  .xs-small {
    font-size: $font-size-small;
  }
  .xs-tiny {
    font-size: $font-size-tiny;
  }
  .xs-right-align {
    text-align: right;
  }
  .xs-center {
    text-align: center;
  }
}

@media (min-width: 40rem) and (max-width: 51.99rem) {
  .sm-h1 {
    font-size: $font-size-h1;
  }
  .sm-h2 {
    font-size: $font-size-h2;
  }
  .sm-h3 {
    font-size: $font-size-h3;
  }
  .sm-h4 {
    font-size: $font-size-h4;
  }
  .sm-h5 {
    font-size: $font-size-h5;
  }
  .sm-h6 {
    font-size: $font-size-h6;
  }
  .sm-huge {
    font-size: $font-size-huge;
  }
  .sm-large {
    font-size: $font-size-large;
  }
  .sm-medium {
    font-size: $font-size-medium;
  }
  .sm-small {
    font-size: $font-size-small;
  }
  .sm-tiny {
    font-size: $font-size-tiny;
  }
  .sm-right-align {
    text-align: right;
  }
  .sm-center {
    text-align: center;
  }
}

@media (min-width: 52rem) and (max-width: 71.99rem) {
  .md-h1 {
    font-size: $font-size-h1;
  }
  .md-h2 {
    font-size: $font-size-h2;
  }
  .md-h3 {
    font-size: $font-size-h3;
  }
  .md-h4 {
    font-size: $font-size-h4;
  }
  .md-h5 {
    font-size: $font-size-h5;
  }
  .md-h6 {
    font-size: $font-size-h6;
  }
  .md-huge {
    font-size: $font-size-huge;
  }
  .md-large {
    font-size: $font-size-large;
  }
  .md-medium {
    font-size: $font-size-medium;
  }
  .md-small {
    font-size: $font-size-small;
  }
  .md-tiny {
    font-size: $font-size-tiny;
  }
  .md-right-align {
    text-align: right;
  }
  .md-center {
    text-align: center;
  }
}

@media (min-width: 72rem) {
  .lg-h1 {
    font-size: $font-size-h1;
  }
  .lg-h2 {
    font-size: $font-size-h2;
  }
  .lg-h3 {
    font-size: $font-size-h3;
  }
  .lg-h4 {
    font-size: $font-size-h4;
  }
  .lg-h5 {
    font-size: $font-size-h5;
  }
  .lg-h6 {
    font-size: $font-size-h6;
  }
  .lg-huge {
    font-size: $font-size-huge;
  }
  .lg-large {
    font-size: $font-size-large;
  }
  .lg-medium {
    font-size: $font-size-medium;
  }
  .lg-small {
    font-size: $font-size-small;
  }
  .lg-tiny {
    font-size: $font-size-tiny;
  }
  .lg-right-align {
    text-align: right;
  }
  .lg-center {
    text-align: center;
  }
}

@media (max-width: 39.99rem) {
  .xs-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .xs-flex-inline {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .xs-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .xs-flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .xs-flex-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
  }
  .xs-flex-none {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .xs-flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .xs-flex-column-reverse {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .xs-items-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
  }
  .xs-items-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
  }
  .xs-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
  }
  .xs-items-baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline;
  }
  .xs-items-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }
  .xs-self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .xs-self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .xs-self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .xs-self-baseline {
    -webkit-align-self: baseline;
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .xs-self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .xs-justify-start {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .xs-justify-end {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .xs-justify-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .xs-justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .xs-justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .xs-content-start {
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .xs-content-end {
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .xs-content-center {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .xs-content-between {
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .xs-content-around {
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .xs-content-stretch {
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .xs-order-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .xs-order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .xs-order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .xs-order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .xs-order-last {
    -webkit-box-ordinal-group: 100000;
    -webkit-order: 99999;
    -ms-flex-order: 99999;
    order: 99999;
  }
}
@media (min-width: 40rem) and (max-width: 51.99rem) {
  .sm-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .sm-flex-inline {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .sm-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .sm-flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .sm-flex-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
  }
  .sm-flex-none {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .sm-flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .sm-flex-column-reverse {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .sm-items-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
  }
  .sm-items-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
  }
  .sm-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
  }
  .sm-items-baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline;
  }
  .sm-items-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }
  .sm-self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .sm-self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .sm-self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .sm-self-baseline {
    -webkit-align-self: baseline;
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .sm-self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .sm-justify-start {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .sm-justify-end {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .sm-justify-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sm-justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .sm-justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .sm-content-start {
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .sm-content-end {
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .sm-content-center {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .sm-content-between {
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .sm-content-around {
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .sm-content-stretch {
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .sm-order-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .sm-order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .sm-order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .sm-order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .sm-order-last {
    -webkit-box-ordinal-group: 100000;
    -webkit-order: 99999;
    -ms-flex-order: 99999;
    order: 99999;
  }
}
@media (min-width: 52rem) and (max-width: 71.99rem) {
  .md-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .md-flex-inline {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .md-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .md-flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .md-flex-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
  }
  .md-flex-none {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .md-flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .md-flex-column-reverse {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .md-items-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
  }
  .md-items-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
  }
  .md-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
  }
  .md-items-baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline;
  }
  .md-items-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }
  .md-self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .md-self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .md-self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .md-self-baseline {
    -webkit-align-self: baseline;
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .md-self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .md-justify-start {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .md-justify-end {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .md-justify-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .md-justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .md-justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .md-content-start {
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .md-content-end {
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .md-content-center {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .md-content-between {
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .md-content-around {
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .md-content-stretch {
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .md-order-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .md-order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .md-order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .md-order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .md-order-last {
    -webkit-box-ordinal-group: 100000;
    -webkit-order: 99999;
    -ms-flex-order: 99999;
    order: 99999;
  }
}
@media (min-width: 72rem) {
  .lg-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .lg-flex-inline {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .lg-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .lg-flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .lg-flex-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
  }
  .lg-flex-none {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
  .lg-flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .lg-flex-column-reverse {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .lg-items-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
  }
  .lg-items-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end;
  }
  .lg-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
  }
  .lg-items-baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline;
  }
  .lg-items-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch;
  }
  .lg-self-start {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .lg-self-end {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .lg-self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .lg-self-baseline {
    -webkit-align-self: baseline;
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .lg-self-stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .lg-justify-start {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .lg-justify-end {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .lg-justify-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .lg-justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .lg-justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .lg-content-start {
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .lg-content-end {
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .lg-content-center {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .lg-content-between {
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .lg-content-around {
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .lg-content-stretch {
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .lg-order-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .lg-order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .lg-order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .lg-order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  .lg-order-last {
    -webkit-box-ordinal-group: 100000;
    -webkit-order: 99999;
    -ms-flex-order: 99999;
    order: 99999;
  }
}

.display-none,
.hide {
  display: none;
}
