
.card {
  width: 100%;
  height: 7.5rem;
  border-radius: .625rem;

  &--shadow-on-hover {
    transition: box-shadow .25s ease;
    &:hover {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &--shadow {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  }
}
