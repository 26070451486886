@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700);
@charset "UTF-8";
/**
 * colors
 */
/**
 * fonts
 */
/**
 * maxWidths
 */
/**
 * break point
 */
/**
 * z-index
 */
/**
 * images
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.color-gray900 {
  color: #2e4253; }

.color-gray800 {
  color: #324251; }

.color-gray700 {
  color: #595959; }

.color-gray600 {
  color: #6c7883; }

.color-gray300 {
  color: #939ca5; }

.color-main {
  color: #7552f6; }

.fs-tiny {
  font-size: 0.75rem; }

.fs-small {
  font-size: 0.875rem; }

.fs-medium {
  font-size: 1rem; }

.fs-large {
  font-size: 1.125rem; }

.fs-huge {
  font-size: 1.25rem; }

.fw-light {
  font-weight: 300; }

.fw-normal {
  font-weight: 400; }

.fw-bold {
  font-weight: 500; }

.fw-boldest {
  font-weight: 900; }

.flex {
  display: flex;
  display: -ms-flex; }

.pointer {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mw100 {
  max-width: 72rem; }

.mw95 {
  max-width: 70rem; }

.mw90 {
  max-width: 68rem; }

.mw80 {
  max-width: 60rem; }

.text-center {
  text-align: center; }

.no-scroll {
  overflow: hidden; }

.flex-reverse {
  flex-direction: row-reverse; }

.p0 {
  padding: 0; }

.p1 {
  padding: .5rem; }

.p2 {
  padding: 1rem; }

.p3 {
  padding: 2rem; }

.p4 {
  padding: 4rem; }

.p5 {
  padding: 8rem; }

.pt0 {
  padding-top: 0; }

.pt1 {
  padding-top: .5rem; }

.pt2 {
  padding-top: 1rem; }

.pt3 {
  padding-top: 2rem; }

.pt4 {
  padding-top: 4rem; }

.pt5 {
  padding-top: 8rem; }

.pr0 {
  padding-right: 0; }

.pr1 {
  padding-right: .5rem; }

.pr2 {
  padding-right: 1rem; }

.pr3 {
  padding-right: 2rem; }

.pr4 {
  padding-right: 4rem; }

.pr5 {
  padding-right: 8rem; }

.pb0 {
  padding-bottom: 0; }

.pb1 {
  padding-bottom: .5rem; }

.pb2 {
  padding-bottom: 1rem; }

.pb3 {
  padding-bottom: 2rem; }

.pb4 {
  padding-bottom: 4rem; }

.pb5 {
  padding-bottom: 8rem; }

.pl0 {
  padding-left: 0; }

.pl1 {
  padding-left: .5rem; }

.pl2 {
  padding-left: 1rem; }

.pl3 {
  padding-left: 2rem; }

.pl4 {
  padding-left: 4rem; }

.pl5 {
  padding-left: 8rem; }

.px0 {
  padding-left: 0;
  padding-right: 0; }

.px1 {
  padding-left: .5rem;
  padding-right: .5rem; }

.px2 {
  padding-left: 1rem;
  padding-right: 1rem; }

.px3 {
  padding-left: 2rem;
  padding-right: 2rem; }

.px4 {
  padding-left: 4rem;
  padding-right: 4rem; }

.px4 {
  padding-left: 8rem;
  padding-right: 8rem; }

.py0 {
  padding-top: 0;
  padding-bottom: 0; }

.py1 {
  padding-top: .5rem;
  padding-bottom: .5rem; }

.py2 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.py3 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.py4 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.py5 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.m0 {
  margin: 0; }

.m1 {
  margin: .5rem; }

.m2 {
  margin: 1rem; }

.m3 {
  margin: 2rem; }

.m4 {
  margin: 4rem; }

.m5 {
  margin: 8rem; }

.mt0 {
  margin-top: 0; }

.mt1 {
  margin-top: .5rem; }

.mt2 {
  margin-top: 1rem; }

.mt3 {
  margin-top: 2rem; }

.mt4 {
  margin-top: 4rem; }

.mt5 {
  margin-top: 8rem; }

.mr0 {
  margin-right: 0; }

.mr1 {
  margin-right: .5rem; }

.mr2 {
  margin-right: 1rem; }

.mr3 {
  margin-right: 2rem; }

.mr4 {
  margin-right: 4rem; }

.mr5 {
  margin-right: 8rem; }

.mb0 {
  margin-bottom: 0; }

.mb1 {
  margin-bottom: .5rem; }

.mb2 {
  margin-bottom: 1rem; }

.mb3 {
  margin-bottom: 2rem; }

.mb4 {
  margin-bottom: 4rem; }

.mb5 {
  margin-bottom: 8rem; }

.ml0 {
  margin-left: 0; }

.ml1 {
  margin-left: .5rem; }

.ml2 {
  margin-left: 1rem; }

.ml3 {
  margin-left: 2rem; }

.ml4 {
  margin-left: 4rem; }

.ml5 {
  margin-left: 8rem; }

.mx0 {
  margin-left: 0;
  margin-right: 0; }

.mx1 {
  margin-left: .5rem;
  margin-right: .5rem; }

.mx2 {
  margin-left: 1rem;
  margin-right: 1rem; }

.mx3 {
  margin-left: 2rem;
  margin-right: 2rem; }

.mx4 {
  margin-left: 4rem;
  margin-right: 4rem; }

.mx5 {
  margin-left: 8rem;
  margin-right: 8rem; }

.my0 {
  margin-top: 0;
  margin-bottom: 0; }

.my1 {
  margin-top: .5rem;
  margin-bottom: .5rem; }

.my2 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.my3 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.my4 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.my5 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

/*
 * 정의되지 않은 컬러
 */
/*
 * Color 값 변수를 다룬다.
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

html {
  font-size: 1rem;
  color: #222426;
  font-family: ObjectSans, sans-serif;
  height: 100%;
  overflow: hidden; }

body {
  line-height: 1.4;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

body.scroll-snap {
  -webkit-scroll-snap-type: y mandatory;
      -ms-scroll-snap-type: y mandatory;
          scroll-snap-type: y mandatory; }
  @media (max-width: 1200px) {
    body.scroll-snap {
      -webkit-scroll-snap-type: initial;
          -ms-scroll-snap-type: initial;
              scroll-snap-type: initial; } }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5em 0 0.833em;
  font-weight: bold;
  line-height: 1.2; }

h1,
.h1 {
  font-size: 4.375rem; }

h2,
.h2 {
  font-size: 3.125rem; }

h3,
.h3 {
  font-size: 1.875rem; }

h4,
.h4 {
  font-size: 1.625rem; }

h5,
.h5 {
  font-size: 1.5rem; }

h6,
.h6 {
  font-size: 1.375rem; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

button {
  outline: 0; }

p {
  margin: 1em 0; }

small {
  font-size: 0.75rem; }

em,
i {
  font-style: italic; }

strong,
b {
  font-weight: bold; }

strong {
  color: #7552f6; }

code,
pre {
  font-family: monospace; }

a {
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

a:hover,
a:active {
  outline: none; }

.svg {
  width: 1rem; }

@font-face {
  font-family: ObjectSans;
  src: url("https://assets.additor.io/fonts/ObjectSans-Regular.eot?#iefix") format("embedded-opentype"), url(https://assets.additor.io/fonts/ObjectSans-Regular.woff) format("woff"), url(https://assets.additor.io/fonts/ObjectSans-Regular.woff2) format("woff2");
  font-weight: 400; }

@font-face {
  font-family: ObjectSans;
  src: url("https://assets.additor.io/fonts/ObjectSans-Medium.eot?#iefix") format("embedded-opentype"), url(https://assets.additor.io/fonts/ObjectSans-Medium.woff) format("woff"), url(https://assets.additor.io/fonts/ObjectSans-Medium.woff2) format("woff2");
  font-weight: 500; }

@font-face {
  font-family: ObjectSans;
  src: url("https://assets.additor.io/fonts/ObjectSans-Heavy.eot?#iefix") format("embedded-opentype"), url(https://assets.additor.io/fonts/ObjectSans-Heavy.woff) format("woff"), url(https://assets.additor.io/fonts/ObjectSans-Heavy.woff2) format("woff2");
  font-weight: 900; }

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border-radius: 4px;
  color: #fff;
  background: #7552f6;
  font-family: Avenir, 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1em;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap; }
  .button--icon {
    padding: 1px 1.5rem 2px; }
    .button--icon .icon {
      margin-right: .75rem; }
  .button.text--left {
    text-align: left; }
  .button.text--center {
    text-align: center; }
  .button.text--right {
    text-align: right; }
  .button:active {
    background: #6d48f5; }
  .button--white {
    background: #fff;
    border: 1px solid #7552f6;
    color: #7552f6; }
    .button--white:hover {
      background: #7552f6;
      color: #fff; }
    .button--white:active {
      background: #6d48f5; }
  .button--full-white {
    background: #fff;
    border: 1px solid #efeff0;
    color: #d6d6d8; }
    .button--full-white:hover {
      background: #6d48f5; }
    .button--full-white:active {
      background: #6d48f5; }
  .button--gray {
    background: #828488; }
    .button--gray:hover {
      background: #7552f6; }
    .button--gray:active {
      background: #6d48f5; }
  .button--round {
    border-radius: 2.5rem; }
  .button--minimal {
    background: #fff;
    color: #828488;
    font-weight: normal;
    justify-content: flex-start;
    padding-left: .875rem; }
    .button--minimal:hover {
      background: rgba(231, 231, 232, 0.2); }
    .button--minimal:active {
      background: rgba(231, 231, 232, 0.2); }
  .button--shadow-on-hover {
    transition: box-shadow .25s ease; }
    .button--shadow-on-hover:hover {
      box-shadow: 0 1px 7px rgba(117, 82, 246, 0.6); }
  .button--shadow-on-hover.button--full-white:hover {
    box-shadow: 0 1px 7px #e7e7e8; }

.card {
  width: 100%;
  height: 7.5rem;
  border-radius: .625rem; }
  .card--shadow-on-hover {
    transition: box-shadow .25s ease; }
    .card--shadow-on-hover:hover {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1); }
  .card--shadow {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1); }

.hero__content {
  display: flex;
  flex-direction: column; }

.input {
  height: 2.5rem;
  border-radius: 5px;
  background: #f9f9f9;
  color: #828488;
  font-size: 1em;
  font-family: Avenir, 'Open Sans', sans-serif;
  padding: 1px 1rem 2px;
  border: 1px solid transparent;
  box-shadow: none;
  outline: none;
  box-sizing: border-box;
  transition: all .15s ease;
  -webkit-appearance: none; }
  .input:focus {
    border-color: #d6d6d8; }

.input-group {
  width: 100%;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  margin-bottom: 8px; }
  .input-group__label {
    top: 0;
    left: 0;
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-size: 18px;
    font-weight: 500;
    -webkit-transform: translate(0, 24px) scale(1);
            transform: translate(0, 24px) scale(1);
    color: #d6d6d8;
    padding: 0;
    line-height: 1.2;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .input-group__label + .input-group__input-wrap {
    margin-top: 24px; }
  .input-group__input-wrap {
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    font-size: 1rem;
    line-height: 1.1875em;
    align-items: center; }
    .input-group__input-wrap:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\A0";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid #f3f3f3;
      pointer-events: none; }
    .input-group__input-wrap:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid #7552f6;
      pointer-events: none; }
  .input-group__input-wrap__input {
    font: inherit;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    color: #404346;
    border: 0;
    margin: 0;
    padding: 6px 10px 9px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    outline: none; }
  .input-group.is-filled .input-group__input-wrap:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    border-bottom: 2px solid #e7e7e8; }
  .input-group.is-filled .input-group__label {
    -webkit-transform: translate(0, 0) scale(0.778);
            transform: translate(0, 0) scale(0.778); }
  .input-group.is-focused .input-group__input-wrap:after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    border-bottom: 2px solid #7552f6; }
  .input-group.is-focused .input-group__label {
    -webkit-transform: translate(0, 0) scale(0.778);
            transform: translate(0, 0) scale(0.778);
    color: #7552f6; }

.link {
  color: #7552f6;
  font-weight: 700; }
  .link--gray {
    color: #828488;
    border-bottom: 1px solid #e7e7e8; }
    .link--gray:hover {
      color: #7552f6;
      text-decoration: none; }

.loading-view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  .loading-view .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px; }
  .loading-view .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #7552f6;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0); }
  .loading-view .lds-ellipsis div:nth-child(1) {
    left: 6px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite; }
  .loading-view .lds-ellipsis div:nth-child(2) {
    left: 6px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite; }
  .loading-view .lds-ellipsis div:nth-child(3) {
    left: 26px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite; }
  .loading-view .lds-ellipsis div:nth-child(4) {
    left: 45px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite; }

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

.list {
  display: flex; }

.list-col {
  flex-direction: column; }

.page {
  -webkit-animation: fade-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation: fade-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.section .wrap-center {
  text-align: center;
  justify-content: center;
  align-items: center; }

.shape {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px; }

.symbol-logo {
  width: 50px;
  height: 50px;
  border-radius: 6px; }

input.form__control {
  height: 45px;
  min-width: 120px;
  object-fit: contain;
  border-radius: 3px;
  border: solid 1px #e7e7e8;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 10px 15px;
  font-size: 18px;
  caret-color: #7552f6;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  transition-timing-function: ease;
  -webkit-appearance: none;
  background-clip: padding-box; }
  input.form__control::-webkit-input-placeholder {
    color: #bfc0c2;
    opacity: 1; }
  input.form__control::-ms-input-placeholder {
    color: #bfc0c2;
    opacity: 1; }
  input.form__control::placeholder {
    color: #bfc0c2;
    opacity: 1; }
  input.form__control:-ms-input-placeholder {
    color: #bfc0c2; }
  input.form__control::-ms-input-placeholder {
    color: #bfc0c2; }
  input.form__control:active, input.form__control:focus {
    border-color: #7552f6;
    outline: none; }
  input.form__control--error {
    border-color: #fd8c91;
    outline: none;
    background: #fff8f9; }
    input.form__control--error:active, input.form__control--error:focus {
      border-color: #fd8c91; }

.form__group {
  position: relative; }
  .form__group--error__wrap {
    margin-bottom: -26px; }

.form__label {
  font-size: 14px;
  font-weight: 900;
  line-height: 14px;
  color: #222426; }

.form__error {
  color: #fa5760;
  line-height: 18px;
  font-size: 13px;
  font-weight: 500;
  height: 18px;
  margin: 8px 0 0; }

.form__checkbox > span {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: solid 1px #d6d5d8;
  display: inline-block;
  transition: .2s ease-in-out;
  transition-property: background-color, border-color;
  color: white; }
  .form__checkbox > span:hover, .form__checkbox > span:focus {
    background-color: #beadff;
    border-color: #beadff; }

.form__checkbox > input:focus + span {
  box-shadow: 0 0 2px 3px #bee2fd; }

.form__checkbox > input:active + span, .form__checkbox input:checked + span {
  background-color: #7552f6;
  border-color: #7552f6; }

.form__checkbox > input {
  position: absolute;
  left: -10000000px; }

#modal-parent-div {
  position: relative;
  z-index: 101; }

#AdditorLandingRoot {
  display: flex;
  flex-direction: column; }
  #AdditorLandingRoot .root__header,
  #AdditorLandingRoot .root__footer {
    font-family: Avenir, 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR', sans-serif;
    flex: 0 0 auto; }
  #AdditorLandingRoot main {
    flex: 1 0 auto; }
  #AdditorLandingRoot.slack-page .mw100 {
    max-width: 80rem; }

.root__header {
  z-index: 3; }
  .root__header > *,
  .root__header * {
    color: inherit; }
  .root__header__container {
    display: flex;
    height: 6rem;
    justify-content: space-between;
    align-items: center; }
  .root__header__namelogo {
    display: flex; }
  .root__header__list {
    display: flex; }
    @media (max-width: 40rem) {
      .root__header__list {
        display: none; } }
  .root__header__listitem {
    height: 36px;
    display: flex;
    align-items: center;
    transition: opacity 0.15s ease; }
    .root__header__listitem__link {
      white-space: nowrap;
      font-weight: 700; }
      .root__header__listitem__link:hover {
        text-decoration: none; }
    .root__header__listitem--signup {
      height: 36px;
      display: flex;
      align-items: center;
      transition: opacity 0.15s ease;
      padding: 0 15px;
      border-radius: 18px;
      background-color: #fff; }
    .root__header__listitem__link--signup {
      color: #7552f6;
      white-space: nowrap;
      font-weight: 700; }
      .root__header__listitem__link--signup:hover {
        text-decoration: none; }
    .root__header__listitem__link--signin {
      white-space: nowrap;
      font-weight: 700; }
      .root__header__listitem__link--signin:hover {
        text-decoration: none; }
  .root__header__listitem:last-child {
    margin-right: 0; }
  .root__header__list--mobile {
    display: none; }
    @media (max-width: 40rem) {
      .root__header__list--mobile {
        display: flex; } }
    .root__header__list--mobile .root__header__listitem {
      margin-right: 10px; }
      .root__header__list--mobile .root__header__listitem:last-child {
        display: flex;
        align-items: center; }
      .root__header__list--mobile .root__header__listitem__link--signup {
        display: block;
        font-weight: 700;
        color: #fff;
        background-color: #7552f6;
        padding: 0 24px;
        height: 36px;
        line-height: 36px;
        border-radius: 18px; }
        .root__header__list--mobile .root__header__listitem__link--signup:hover {
          text-decoration: none; }
  .root__header__menu-btn {
    width: 2rem;
    fill: #828488; }
    .root__header__menu-btn:hover {
      fill: #222426; }
  .root__header__close-btn {
    width: 2rem;
    fill: #828488;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
    .root__header__close-btn:hover {
      fill: #222426; }
  .root__header--mobile {
    height: 0;
    margin: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.15s ease-in;
    z-index: 1000; }
    .root__header--mobile.active {
      min-height: 100%;
      opacity: 1;
      position: relative;
      pointer-events: auto; }
  .root__header--mobile__list {
    background-color: #7552f6;
    height: 100%;
    margin: 0 auto;
    min-height: 100vh;
    overflow: scroll;
    width: 100%;
    flex-direction: column;
    font-size: 1.125rem; }
  .root__header--mobile__listitem {
    margin-bottom: 2rem; }
  .root__header--mobile__listitem__link {
    color: #fff; }

@media (min-width: 40rem) {
  .root__header--mobile {
    opacity: 0 !important;
    pointer-events: none !important; } }

@media (max-width: 39.99rem) {
  .root__header__namelogo {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); } }

.root__footer {
  font-family: 'Open Sans', sans-serif;
  background: #f7f7f7; }
  .root__footer__container {
    display: flex;
    justify-content: space-between; }
  .root__footer__nav-wrap {
    display: flex;
    flex: 80% 1; }
  .root__footer__logo-wrap {
    flex: 25% 1; }
  .root__footer__nav {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    flex: 75% 1; }
  .root__footer__nav__column {
    flex: 33.33% 1;
    font-size: 0.875rem;
    font-weight: 900; }
  .root__footer__nav__p {
    margin: 0 0 1.25rem 0;
    font-weight: 700; }
  .root__footer__nav__listitem {
    color: #828488;
    margin: 0 0 1.25rem 0;
    font-weight: 600; }
  .root__footer__social {
    flex: 20% 1;
    font-size: 0.875rem; }
  .root__footer__social__p {
    white-space: nowrap;
    margin: 0 0 0.625rem 0;
    font-weight: 600;
    color: #828488; }
  .root__footer__social__list {
    display: flex;
    justify-content: space-between;
    width: 7rem;
    margin-top: 1.5rem;
    margin-bottom: 3.75rem; }
  .root__footer__social__listitem {
    height: 30px; }
  .root__footer__social__logo {
    width: 1.25rem;
    fill: #d6d6d8; }
    .root__footer__social__logo:hover {
      fill: #828488; }
  .root__footer__social__copyright {
    white-space: nowrap;
    color: #828488; }
    .root__footer__social__copyright__link {
      color: #828488;
      font-weight: 400; }

@media (max-width: 40rem) {
  .root__footer__nav {
    grid-template-columns: repeat(2, 1fr); } }

.svg--right-arrow {
  vertical-align: -2px; }
  .svg--right-arrow path {
    fill: #7552f6; }

.hero__content {
  position: relative; }

.hero__header__h1 {
  margin-top: 7.375rem;
  margin-bottom: 2.5rem;
  font-weight: 900; }

.hero__header__form {
  margin-top: 3.1rem;
  max-width: 27.5rem; }

.hero__header__input-email {
  width: 17.813rem;
  font-weight: 700;
  font-size: 0.875rem;
  margin-bottom: 0; }

.hero__header__button-signup {
  width: 10.625rem;
  margin-bottom: 0; }

.hero__header__email-invalid {
  margin: 4px 0 -31px;
  color: #fa5760;
  font-size: 14px;
  line-height: 27px; }
  .hero__header__email-invalid--bottom {
    margin: 0; }

.hero__header__p {
  margin-top: 30px; }
  .hero__header__p__sign-in {
    margin: 0 16px 0 11px; }
    .hero__header__p__sign-in:hover {
      text-decoration: underline; }
  .hero__header__p__btn-oauth {
    border: none;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    background: #ffffff;
    margin-right: 10px; }
    .hero__header__p__btn-oauth > .icon {
      width: 23px;
      height: 23px; }

.hero__header__span {
  font-size: 0.875rem;
  margin-right: 0.3rem; }

.hero__header__a {
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 1px solid #e7e7e8; }
  .hero__header__a:hover {
    color: #7552f6;
    border-color: #7552f6; }

.hero__shape__container {
  position: absolute;
  top: 72px;
  right: -54px;
  width: 668px; }
  .hero__shape__container__img {
    width: 100%; }

@media (max-width: 71.99rem) {
  .hero__shape__container {
    position: relative;
    top: auto;
    top: initial;
    right: auto;
    right: initial;
    width: 80%;
    margin: -2px auto 0; } }

@media (max-width: 39.99rem) {
  .hero__header__h1 {
    margin-top: 2.5rem;
    font-size: 3rem; }
  .hero__shape__container {
    width: 100%; } }

.s-future {
  padding-top: 10.5rem; }
  .s-future__header-container {
    margin: 0 auto; }
  .s-future__h3 {
    margin-bottom: 1rem; }
  .s-future__h6 {
    margin-top: 0;
    font-weight: 400; }
  .s-future__logo-container {
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem auto 1rem;
    justify-content: space-evenly;
    align-items: center; }
  .s-future__companies {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    opacity: 0.8; }
    .s-future__companies .sothebys {
      width: 120px;
      padding: 10px 28px; }
      .s-future__companies .sothebys path {
        fill: #d6d6d8; }
    .s-future__companies .newrelic {
      width: 140px;
      padding: 10px 28px; }
      .s-future__companies .newrelic path {
        fill: #d6d6d8; }
    .s-future__companies .atlassian {
      width: 160px;
      padding: 5px 28px 10px; }
      .s-future__companies .atlassian * {
        fill: #d6d6d8; }
    .s-future__companies .shopify {
      width: 100px;
      height: 30px;
      padding: 20px 28px 27px; }
      .s-future__companies .shopify path {
        fill: #d6d6d8; }
    .s-future__companies .ycombinator {
      width: 148px;
      padding: 20px 28px 28px; }
      .s-future__companies .ycombinator * {
        fill: #d6d6d8; }
    .s-future__companies .rakuten {
      width: 100px;
      padding: 20px 28px 15 ´px; }
      .s-future__companies .rakuten * {
        fill: #d6d6d8; }

.s-stuck-in-middle {
  padding-top: 10.5rem; }
  .s-stuck-in-middle__illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    max-width: 37.5rem; }
  .s-stuck-in-middle__content {
    flex: 1 1; }
  .s-stuck-in-middle__header-container {
    margin: 0 auto; }
  .s-stuck-in-middle__content__h3 {
    margin-bottom: 3rem; }
  .s-stuck-in-middle__content__p {
    font-weight: 400; }

.s-fastest-way {
  padding-top: 10.5rem; }
  .s-fastest-way__header-container {
    margin: 0 auto; }
  .s-fastest-way__demo-tab__list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 700; }
  .s-fastest-way__demo-tab__listitem.selected {
    color: #7552f6; }
  .s-fastest-way__demo-viewer {
    display: none;
    height: 650px;
    margin: 0 auto; }
    .s-fastest-way__demo-viewer.active {
      display: flex;
      justify-content: center;
      align-items: center; }
  .s-fastest-way__link-wrap {
    text-align: center; }

@media (max-width: 71.99rem) {
  .s-fastest-way__demo-viewer {
    height: 540px; } }

@media (max-width: 51.99rem) {
  .s-fastest-way__demo-viewer {
    height: 480px; } }

@media (max-width: 39.99rem) {
  .s-fastest-way__demo-viewer {
    height: 240px; } }

.s-document {
  padding-top: 10.5rem; }
  .s-document__header-container {
    margin: 0 auto; }
  .s-document__content {
    display: flex; }
  .s-document__content__textblock {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 1rem;
    padding: 1rem;
    max-width: 24.5rem;
    background: #fff;
    transition: all 0.15s ease;
    z-index: 2; }
  .s-document__content__textblock:not(:first-child):before {
    content: '';
    position: absolute;
    top: -1px;
    width: calc(100% - 2rem);
    height: 1px;
    background: #efeff0;
    opacity: 1; }
  .s-document__content__textblock:after {
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -2;
    opacity: 0;
    transition: all 0.15s ease; }
  .s-document__content__textblock:nth-child(1):after {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/illustration/hover-texture-yellow.png");
    width: 173px;
    height: 170px;
    left: -5rem;
    top: -63px; }
  .s-document__content__textblock:nth-child(2):after {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/illustration/hover-texture-blue.png");
    width: 100px;
    height: 100px;
    right: -3.5rem;
    top: -50px; }
  .s-document__content__textblock:nth-child(3):after {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/illustration/hover-texture-purple.png");
    width: 203px;
    height: 181px;
    right: -4rem;
    bottom: -53px; }
  .s-document__content__textblock:hover {
    z-index: 12;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.07); }
    .s-document__content__textblock:hover:before {
      opacity: 0; }
    .s-document__content__textblock:hover:after {
      opacity: 1; }
  .s-document__content__textblock__h5:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    left: 0;
    background: #fff;
    z-index: -1; }
  .s-document__content__image-slider {
    max-height: 480px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
  .s-document__content__image {
    height: 100%;
    object-fit: contain; }
  .s-document__content__image-slider__link {
    -webkit-animation: fade-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation: fade-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

@media (max-width: 39.99rem) {
  .s-document__content__textblock:before {
    content: none !important; }
  .s-document__content__textblock:after {
    content: none !important; } }

.s-promising-team {
  padding-top: 10.5rem; }
  .s-promising-team__header-container {
    margin: 0 auto; }
  .s-promising-team__container {
    display: flex;
    flex-wrap: wrap; }
  .s-promising-team__container__wrap-card {
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700; }
  .s-promising-team__container__card {
    height: 6.25rem;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center; }
  .s-promising-team .card--mk--start {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/marketing.gif"); }
  .s-promising-team .card--mk--end {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/marketing.png"); }
    .s-promising-team .card--mk--end:hover {
      background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/marketing.gif"); }
  .s-promising-team .card--pr--start {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/product.gif"); }
  .s-promising-team .card--pr--end {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/product.png"); }
    .s-promising-team .card--pr--end:hover {
      background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/product.gif"); }
  .s-promising-team .card--pm--start {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/project_management.gif"); }
  .s-promising-team .card--pm--end {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/project_management.png"); }
    .s-promising-team .card--pm--end:hover {
      background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/project_management.gif"); }
  .s-promising-team .card--fr--start {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/freelancer.gif"); }
  .s-promising-team .card--fr--end {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/freelancer.png"); }
    .s-promising-team .card--fr--end:hover {
      background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/freelancer.gif"); }
  .s-promising-team .card--kb--start {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/knowledge_base.gif"); }
  .s-promising-team .card--kb--end {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/knowledge_base.png"); }
    .s-promising-team .card--kb--end:hover {
      background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/knowledge_base.gif"); }
  .s-promising-team .card--hr--start {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/human_resource.gif"); }
  .s-promising-team .card--hr--end {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/human_resource.png"); }
    .s-promising-team .card--hr--end:hover {
      background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/human_resource.gif"); }

.s-workflows {
  padding-top: 10.5rem; }
  .s-workflows__header-container {
    margin: 0 auto; }
  .s-workflows__illustration {
    text-align: center;
    margin-bottom: 116px; }
    .s-workflows__illustration a {
      color: #7552f6; }
    .s-workflows__illustration img {
      margin-bottom: 36px; }
    .s-workflows__illustration strong {
      font-size: 22px;
      line-height: 24px; }
    .s-workflows__illustration svg {
      vertical-align: top;
      margin-left: 4px; }

.s-potential__header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto; }

.s-potential__form {
  max-width: 27.5rem; }

.s-potential__input-email {
  width: 15rem;
  margin-right: 1rem;
  font-weight: 700;
  font-size: 0.875rem; }

.s-potential__button-signup {
  width: 10.625rem;
  vertical-align: top; }

.image-preloader {
  position: fixed;
  z-index: -1; }
  .image-preloader .mk {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/marketing.png"); }
  .image-preloader .pr {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/product.png"); }
  .image-preloader .pm {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/knowledge_base.png"); }
  .image-preloader .fr {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/freelancer.png"); }
  .image-preloader .kb {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/knowledge_base.png"); }
  .image-preloader .hr {
    background-image: url("https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image/gif/human_resource.png"); }

.hero--product__form {
  display: inline-flex; }

.hero--product__input-email {
  width: 17.813rem;
  font-weight: 700;
  font-size: 0.875rem; }

.hero--product__button-signup {
  width: 8.625rem; }

.s-devices {
  margin-top: 6.250rem; }

.s-function {
  margin-top: 10rem; }
  .s-function__container {
    display: flex; }
  .s-function:nth-child(odd) .s-function__container {
    flex-direction: row-reverse; }
  .s-function__container__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15rem; }
    .s-function__container__header__h3 {
      margin-top: 0;
      margin-bottom: 1.5rem; }
    .s-function__container__header__button {
      width: 100%; }
      .s-function__container__header__button.button--minimal {
        font-size: 1.125rem;
        margin-bottom: .625rem; }
      .s-function__container__header__button.button--round {
        height: 45px;
        margin-top: 1.25rem;
        font-size: 1rem; }
  .s-function__container__object {
    flex: 1 1; }
  .s-function__container__object__content {
    width: 100%;
    height: 100%;
    background: #f7f7f7;
    padding: 1rem; }

.s-pricing__container__card-wrap {
  display: flex;
  justify-content: center; }

.s-pricing__container__card {
  display: flex;
  justify-content: center;
  width: 17.5rem;
  height: auto;
  margin-right: 3.125rem;
  border: 1px solid #efeff0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.s-pricing__container__card:hover {
  border-color: transparent; }

.s-pricing__container__card:last-child {
  margin-right: 0; }

.s-pricing__container__card__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative; }

.s-pricing__container__card__inner__header-wrap {
  margin-bottom: 2rem; }

.s-pricing__container__card__inner__p--price {
  margin-top: .75rem;
  margin-bottom: 0; }

.s-pricing__container__card__inner__p--desc {
  margin: 0;
  line-height: 1.56; }

.s-pricing__container__card__inner__small {
  display: block;
  margin-top: -1px;
  color: #d6d6d8; }

.s-pricing__container__card:hover .s-pricing__container__card__inner__button {
  background: #7552f6;
  box-shadow: 0 1px 7px rgba(117, 82, 246, 0.6); }

.s-pricing__container__card__inner__button {
  width: 10rem;
  height: 1.875rem;
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  font-size: 0.875rem; }

.s-pricing__form {
  max-width: 27.5rem; }

.s-pricing-features {
  background: #7552f6;
  height: 10rem; }

.sign-up-page {
  height: 100vh;
  min-height: 900px;
  display: flex;
  justify-content: center;
  font-family: Avenir, 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR', sans-serif; }
  .sign-up-page__namelogo-wrap {
    width: 80%;
    position: absolute;
    top: 28px;
    max-width: 400px; }
  .sign-up-page__namelogo {
    display: flex; }
  .sign-up-page__menu-wrap {
    width: 80%;
    position: absolute;
    top: 30px;
    max-width: 890px; }
  .sign-up-page__list {
    position: absolute;
    right: 0; }
    @media (max-width: 940px) {
      .sign-up-page__list {
        display: none; } }
  .sign-up-page__list--mobile {
    position: absolute;
    right: 0;
    display: none; }
    @media (max-width: 940px) {
      .sign-up-page__list--mobile {
        display: flex; } }
    .sign-up-page__list--mobile .sign-up-page__listitem:last-child {
      margin-right: 10px; }
    .sign-up-page__list--mobile .sign-up-page__link--signin {
      display: block;
      margin-right: 0;
      font-weight: 700;
      color: #fff;
      background-color: #7552f6;
      padding: 0 24px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px; }
      .sign-up-page__list--mobile .sign-up-page__link--signin:hover {
        opacity: 1;
        background-color: #623ff0;
        text-decoration: none; }
  .sign-up-page__listitem {
    height: 38px;
    display: flex;
    align-items: center; }
  .sign-up-page__link, .sign-up-page__link--signin, .sign-up-page__link--signup {
    font-weight: 700;
    padding: 0 40px 0 0;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 18px;
    color: #383e44;
    transition: opacity .15s ease-in-out;
    white-space: nowrap; }
    .sign-up-page__link:hover, .sign-up-page__link--signin:hover, .sign-up-page__link--signup:hover {
      text-decoration: none; }
  .sign-up-page__link--signin {
    color: #7552f6; }
  .sign-up-page__link--signup {
    background-color: #7552f6;
    color: #fff;
    padding: 0 20px; }
  .sign-up-page__title {
    position: relative;
    font-size: 32px;
    font-weight: 900;
    color: #222426;
    margin: 0;
    z-index: 3; }
  .sign-up-page__desc {
    font-size: 18px; }
  .sign-up-page__title__deco {
    width: 134px;
    position: absolute;
    left: -2px;
    top: 5px;
    z-index: -1; }
  .sign-up-page__panel-wrap, .sign-up-page__main-image-wrap {
    display: flex;
    justify-content: center;
    align-items: center; }
  .sign-up-page__panel-wrap {
    position: relative;
    flex: 4 1; }
  .sign-up-page__main-image-wrap {
    flex: 6 1;
    position: relative;
    z-index: 1; }
    .sign-up-page__main-image-wrap img, .sign-up-page__main-image-wrap video {
      width: 80%;
      max-width: 890px; }
  .sign-up-page__main-image-wrap-bg {
    position: absolute;
    min-height: 900px;
    left: 40%;
    width: 60%;
    height: 100%;
    background: #f5f5f5; }
  .sign-up-page__sign-in-wrapper {
    margin-top: 50px;
    font-size: 14px;
    color: #828488; }
  .sign-up-page__sign-in-link {
    color: #7552f6;
    font-weight: 600;
    margin-left: 10px; }
    .sign-up-page__sign-in-link:hover {
      text-decoration: underline; }
  .sign-up-page__google-sign-in-btn {
    border: none;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 12px;
    background: #ffffff; }
    .sign-up-page__google-sign-in-btn > .icon {
      width: 14px;
      height: 14px; }

.sign-up-page__panel-wrap .sign-up-page__menu-wrap {
  display: none;
  max-width: 400px; }

.sign-up-page__panel {
  max-width: 400px;
  width: 80%; }
  .sign-up-page__panel__form-group {
    margin-bottom: 25px; }
  .sign-up-page__panel__form-group.form__group--error {
    margin-bottom: 2px; }
  .sign-up-page__panel__form-control-wrap {
    display: flex;
    position: relative;
    align-items: center; }
    .sign-up-page__panel__form-control-wrap > span {
      font-size: 20px;
      font-weight: 900;
      color: #222426; }
    .sign-up-page__panel__form-control-wrap > .valid-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      color: #dad1ff; }
      .sign-up-page__panel__form-control-wrap > .valid-icon--valid {
        color: #7552f6; }
      .sign-up-page__panel__form-control-wrap > .valid-icon--valid {
        color: #ffe8e9; }
  .sign-up-page__panel__label {
    line-height: 18px;
    font-size: 13px;
    font-weight: normal;
    margin: 0 0 8px; }
  .sign-up-page__panel__form-control {
    flex: 1 1;
    font-size: 18px; }
    .sign-up-page__panel__form-control + span {
      margin-left: 12px; }
  .sign-up-page__panel__btn-submit {
    color: #ffffff;
    background-color: #7552f6;
    padding: 0;
    font-size: 16px;
    width: 100%;
    height: 45px;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    font-family: Avenir, 'Open Sans', sans-serif;
    font-weight: 600; }
    .sign-up-page__panel__btn-submit:hover {
      background-color: #623ff0; }

.sign-up-page__panel--signup__title {
  margin: 0 -80px; }

.sign-up-page__panel--signup__url {
  line-height: 23px;
  font-size: 17px;
  text-align: center;
  color: #1065a3;
  margin: 2px 0 0; }

.sign-up-page__panel--signup__form {
  margin-top: 40px; }

.sign-up-page__panel--signup__form-group > .form__group--error__wrap {
  margin-bottom: unset; }
  .sign-up-page__panel--signup__form-group > .form__group--error__wrap .form__error {
    margin-top: 5px; }

.sign-up-page__panel--signup__form-group:last-child {
  margin-bottom: 0; }

.sign-up-page__panel--signup__error {
  margin-top: -2px;
  margin-bottom: 10px;
  line-height: 18px;
  font-size: 13px;
  font-weight: 500;
  color: #fa5760; }

.sign-up-page__panel--signup__or {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
  margin: 20px 0; }
  .sign-up-page__panel--signup__or:before, .sign-up-page__panel--signup__or:after {
    content: '';
    display: block;
    height: 1px;
    flex: 1 1;
    background: #d9d9d9; }
  .sign-up-page__panel--signup__or:before {
    margin-right: 15px; }
  .sign-up-page__panel--signup__or:after {
    margin-left: 15px; }

.sign-up-page__panel--signup__btn-submit {
  margin-top: 13px; }

.sign-up-page__panel--signup__btn-google, .sign-up-page__panel--signup__btn-slack, .sign-up-page__panel--signup__btn-apple {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: solid 1px #d9d9d9;
  border-radius: 3px;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #404346;
  width: 100%;
  height: 45px;
  padding: 0;
  margin-bottom: 16px;
  cursor: pointer;
  font-family: Avenir, 'Open Sans', sans-serif; }
  .sign-up-page__panel--signup__btn-google:last-of-type, .sign-up-page__panel--signup__btn-slack:last-of-type, .sign-up-page__panel--signup__btn-apple:last-of-type {
    margin-bottom: 0; }
  .sign-up-page__panel--signup__btn-google > .icon, .sign-up-page__panel--signup__btn-slack > .icon, .sign-up-page__panel--signup__btn-apple > .icon {
    width: 20px;
    height: 20px;
    position: relative;
    display: inline-block;
    margin-right: 8px;
    vertical-align: bottom; }
    .sign-up-page__panel--signup__btn-google > .icon > img, .sign-up-page__panel--signup__btn-slack > .icon > img, .sign-up-page__panel--signup__btn-apple > .icon > img {
      width: 100%; }
  .sign-up-page__panel--signup__btn-google:hover, .sign-up-page__panel--signup__btn-slack:hover, .sign-up-page__panel--signup__btn-apple:hover {
    background-color: #f9f9f9; }

.sign-up-page__panel--signup__error-google {
  color: #fa626a;
  line-height: 18px;
  font-size: 13px;
  font-weight: 500;
  height: 18px;
  margin: 8px 0 0; }

.sign-up-page__panel--signup__forgot {
  line-height: 22px;
  font-size: 16px;
  font-weight: 900;
  color: #7552f6;
  margin-top: 40px;
  text-align: center; }

@media (max-width: 72rem) {
  .sign-up-page__main-image-wrap-bg {
    left: 40%;
    width: 60%; } }

@media (max-width: 940px) {
  .sign-up-page__main-image-wrap {
    display: none; }
  .sign-up-page__main-image-wrap-bg {
    display: none; }
  .sign-up-page__panel-wrap .sign-up-page__menu-wrap {
    display: block; } }

@media (max-width: 26.5rem) {
  .sign-up-page__panel {
    width: calc(100% - 60px); }
  .sign-up-page__panel-wrap {
    padding-top: 120px;
    align-items: initial; } }

.start-verification {
  height: calc(100vh - 96px);
  min-height: 680px;
  position: relative; }

.start-verification__panel-wrap {
  width: 650px;
  position: absolute;
  top: calc(50% - 48px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .start-verification__panel-wrap .bg-texture {
    position: absolute;
    background-size: cover; }
    .start-verification__panel-wrap .bg-texture--triangle {
      top: -35px;
      right: -50px;
      width: 220px;
      height: 219px; }
    .start-verification__panel-wrap .bg-texture--circle {
      bottom: 103px;
      left: -125px;
      width: 299px;
      height: 299px; }
    .start-verification__panel-wrap .bg-texture--square {
      bottom: -30px;
      left: -60px;
      width: 244px;
      height: 289px; }

.start-verification__panel {
  background: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  color: #222426;
  padding: 63px 123px 10px 123px;
  z-index: 1;
  position: relative; }
  .start-verification__panel h1 {
    font-size: 32px;
    margin: 0 0 30px;
    font-weight: 900; }
  .start-verification__panel p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 5px; }
    .start-verification__panel p strong {
      color: inherit; }
  .start-verification__panel button {
    background: transparent;
    border: none;
    font-size: 14px;
    line-height: 19px;
    color: #7552f6;
    font-weight: 900;
    text-decoration: underline;
    padding: 0;
    font-family: Avenir, Open Sans, sans-serif;
    cursor: pointer; }
    .start-verification__panel button:hover {
      color: #5634e0; }
  .start-verification__panel__banner {
    display: block;
    max-width: 329px;
    height: 325px;
    position: relative;
    margin: 32px auto 0;
    overflow: hidden; }
    .start-verification__panel__banner > video {
      position: absolute;
      margin-top: -35px;
      width: 100%; }
  .start-verification__panel__send-again {
    width: 100%;
    position: relative;
    margin: 7px 0 0; }
    .start-verification__panel__send-again > span {
      position: absolute;
      line-height: 19px;
      z-index: 1; }

.start-verification__create-ws {
  text-align: center;
  margin: 30px 0 0;
  line-height: 22px;
  font-size: 14px;
  color: #dad1ff;
  position: absolute;
  width: 100%; }
  .start-verification__create-ws > a {
    margin-left: 8px;
    color: #fff;
    font-weight: bold; }

@media (max-width: 39.99rem) {
  .start-verification__panel-wrap {
    width: 100%;
    position: relative;
    top: auto;
    top: initial;
    left: auto;
    left: initial;
    -webkit-transform: none;
            transform: none;
    padding-top: 40px; }
    .start-verification__panel-wrap .bg-texture {
      display: none; }
  .start-verification__panel {
    padding: 0 30px 10px;
    margin-bottom: 24px; }
  .start-verification__create-ws {
    margin-top: 0;
    margin-bottom: 73px;
    color: #222426; }
    .start-verification__create-ws > a {
      display: block;
      color: #222426;
      margin: 0; } }

.sign-in-page {
  height: calc(100vh - 96px);
  font-family: Avenir, 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR', sans-serif;
  min-height: 680px;
  position: relative; }
  .sign-in-page * {
    font-family: Avenir, 'Open Sans', sans-serif; }
  .sign-in-page__panel-wrap {
    position: absolute;
    width: 560px;
    top: calc(50% - 48px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .sign-in-page__panel-wrap .bg-texture {
      position: absolute;
      background-size: cover;
      z-index: 0; }
      .sign-in-page__panel-wrap .bg-texture--triangle {
        top: -48px;
        right: -50px;
        width: 222px;
        height: 220px; }
      .sign-in-page__panel-wrap .bg-texture--circle {
        bottom: -34px;
        left: -91px;
        width: 233px;
        height: 267px; }
      .sign-in-page__panel-wrap .bg-texture--square {
        bottom: -40px;
        left: -40px;
        width: 209px;
        height: 249px; }
    .sign-in-page__panel-wrap .bg-girl {
      position: absolute;
      background-size: cover;
      z-index: 1; }
      .sign-in-page__panel-wrap .bg-girl--body {
        width: 228px;
        height: 166px;
        top: 12px;
        right: -146px; }
      .sign-in-page__panel-wrap .bg-girl--right-hand {
        z-index: 5;
        width: 76px;
        height: 49px;
        top: -28px;
        right: 22px; }
      .sign-in-page__panel-wrap .bg-girl--left-hand {
        z-index: 5;
        width: 52px;
        height: 79px;
        top: 152px;
        right: -29px; }
  .sign-in-page__panel {
    position: relative;
    z-index: 3;
    background: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    color: #222426;
    min-height: 320px; }

.sign-in-page__footer {
  text-align: center;
  margin: 30px 0 0;
  line-height: 22px;
  font-size: 16px;
  color: #dad1ff;
  position: absolute;
  width: 100%; }

.sign-in-page__footer__p {
  margin: 10px 0; }
  .sign-in-page__footer__p--create-ws {
    padding-left: 16px; }

.sign-in-page__footer__a {
  margin-left: 8px;
  color: #fff;
  font-weight: bold; }
  .sign-in-page__footer__a svg {
    margin-left: 4px; }

.sign-in-page__title {
  margin: 0;
  line-height: 40px;
  font-size: 32px;
  font-weight: 900;
  color: #222426;
  position: relative;
  text-align: center; }
  .sign-in-page__title > span {
    position: relative;
    z-index: 1; }
  .sign-in-page__title__deco {
    width: 134px;
    position: absolute;
    left: 116px;
    top: 6px;
    z-index: -1; }

.sign-in-page__panel__form-group {
  margin-bottom: 24px; }

.sign-in-page__panel__form-control-wrap {
  display: flex;
  position: relative;
  align-items: center; }
  .sign-in-page__panel__form-control-wrap > span {
    font-size: 20px;
    font-weight: 600;
    color: #222426; }
  .sign-in-page__panel__form-control-wrap > .valid-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #dad1ff; }
    .sign-in-page__panel__form-control-wrap > .valid-icon--valid {
      color: #7552f6; }
    .sign-in-page__panel__form-control-wrap > .valid-icon--valid {
      color: #ffe8e9; }

.sign-in-page__panel__label {
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
  margin: 0 0 8px; }

.sign-in-page__panel__form-control.form__control {
  flex: 1 1;
  font-size: 16px; }
  .sign-in-page__panel__form-control.form__control + span {
    margin-left: 12px; }

.sign-in-page__panel__btn-submit {
  color: #ffffff;
  background-color: #7552f6;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  height: 45px;
  cursor: pointer;
  border-radius: 3px;
  border: none; }
  .sign-in-page__panel__btn-submit:hover {
    background-color: #623ff0; }

.sign-in-page__panel--workspace {
  padding: 45px 80px; }
  .sign-in-page__panel--workspace__form {
    margin-top: 40px; }
  .sign-in-page__panel--workspace__form-control {
    text-align: right; }
  .sign-in-page__panel--workspace__create {
    text-align: center;
    line-height: 22px;
    font-size: 16px;
    color: #404346;
    margin: 47px 0 0; }
    .sign-in-page__panel--workspace__create__link {
      font-weight: 600;
      color: #7552f6; }

.sign-in-page__panel--signin {
  padding: 45px 80px 45px; }
  .sign-in-page__panel--signin__title {
    margin: 0 -80px; }
  .sign-in-page__panel--signin__url {
    line-height: 23px;
    font-size: 17px;
    text-align: center;
    color: #1065a3;
    margin: 4px 0 0; }
  .sign-in-page__panel--signin__form {
    margin-top: 40px; }
  .sign-in-page__panel--signin__form-group {
    margin-bottom: 10px; }
    .sign-in-page__panel--signin__form-group > .form__group--error__wrap {
      margin-bottom: unset; }
  .sign-in-page__panel--signin__error {
    margin-top: -2px;
    margin-bottom: 10px;
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
    color: #fa5760; }
  .sign-in-page__panel--signin__or {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d9d9d9;
    margin: 20px 0; }
    .sign-in-page__panel--signin__or:before, .sign-in-page__panel--signin__or:after {
      content: '';
      display: block;
      height: 1px;
      flex: 1 1;
      background: #d9d9d9; }
    .sign-in-page__panel--signin__or:before {
      margin-right: 15px; }
    .sign-in-page__panel--signin__or:after {
      margin-left: 15px; }
  .sign-in-page__panel--signin__btn-submit {
    margin-top: 18px; }
  .sign-in-page__panel--signin__btn-google, .sign-in-page__panel--signin__btn-slack, .sign-in-page__panel--signin__btn-apple {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: solid 1px #d9d9d9;
    border-radius: 3px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #404346;
    font-weight: 500;
    width: 100%;
    height: 45px;
    padding: 0;
    margin-bottom: 12px;
    cursor: pointer; }
    .sign-in-page__panel--signin__btn-google > .icon, .sign-in-page__panel--signin__btn-slack > .icon, .sign-in-page__panel--signin__btn-apple > .icon {
      width: 20px;
      height: 20px;
      position: relative;
      display: inline-block;
      margin-right: 12px;
      vertical-align: bottom; }
      .sign-in-page__panel--signin__btn-google > .icon > img, .sign-in-page__panel--signin__btn-slack > .icon > img, .sign-in-page__panel--signin__btn-apple > .icon > img {
        width: 100%; }
    .sign-in-page__panel--signin__btn-google:hover, .sign-in-page__panel--signin__btn-slack:hover, .sign-in-page__panel--signin__btn-apple:hover {
      background-color: #f9f9f9; }
  .sign-in-page__panel--signin__error-google, .sign-in-page__panel--signin__error-slack {
    color: #fa626a;
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 8px 0; }
  .sign-in-page__panel--signin__forgot {
    display: flex;
    justify-content: center;
    line-height: 22px;
    font-size: 14px;
    font-weight: 500;
    margin: 22px 0 0 0; }
    .sign-in-page__panel--signin__forgot a {
      color: #828488;
      white-space: nowrap; }
    .sign-in-page__panel--signin__forgot a:hover {
      cursor: pointer;
      text-decoration: underline; }
    .sign-in-page__panel--signin__forgot__dot {
      position: relative;
      margin: 0 14px; }
      .sign-in-page__panel--signin__forgot__dot:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #828488; }

.sign-in-page__panel--find {
  padding: 45px 75px 50px; }
  .sign-in-page__panel--find .form__group--error__wrap {
    margin-bottom: unset; }
  .sign-in-page__panel--find .sign-in-page__title__deco {
    width: 134px;
    height: 42px;
    left: -10px;
    top: 5px; }
  .sign-in-page__panel--find__title {
    margin-bottom: 10px; }
  .sign-in-page__panel--find__description {
    margin: 0 0 33px;
    font-size: 15px;
    color: #828488;
    text-align: center; }
  .sign-in-page__panel--find__form-label {
    font-size: 13px;
    margin: 0 0 8px 0;
    font-weight: 400; }
  .sign-in-page__panel--find__bnt-back {
    display: flex;
    border: none;
    position: absolute;
    padding: 4px;
    top: 20px;
    left: 22px;
    background-color: transparent;
    cursor: pointer;
    outline: 0;
    border-radius: 5px; }
    .sign-in-page__panel--find__bnt-back:hover {
      background: #f5f5f5; }
      .sign-in-page__panel--find__bnt-back:hover svg g {
        stroke: #606367; }
  .sign-in-page__panel--find__banner {
    width: 302px;
    margin: 0 auto; }
    .sign-in-page__panel--find__banner > img {
      width: 100%; }

.sign-in-page__panel--find-password {
  padding: 60px 80px 75px; }
  .sign-in-page__panel--find-password .sign-in-page__title__deco {
    width: 110px;
    height: 40px;
    left: -14px;
    top: 4px; }
  .sign-in-page__panel--find-password__form {
    margin-top: 40px; }
  .sign-in-page__panel--find-password__create {
    text-align: center;
    line-height: 22px;
    font-size: 16px;
    color: #404346;
    margin: 47px 0 0; }
    .sign-in-page__panel--find-password__create__link {
      font-weight: 600;
      color: #7552f6; }
  .sign-in-page__panel--find-password__btn-submit {
    margin-top: 13px; }
  .sign-in-page__panel--find-password__sent {
    text-align: center; }
    .sign-in-page__panel--find-password__sent p {
      margin-bottom: 22px; }
    .sign-in-page__panel--find-password__sent img {
      width: 149px;
      margin-bottom: -20px; }

@media (max-width: 51.99rem) {
  .sign-in-page__panel-wrap .bg-girl {
    display: none; } }

@media (max-width: 39.99rem) {
  .sign-in-page__panel-wrap {
    width: 100%;
    position: relative;
    top: auto;
    top: initial;
    left: auto;
    left: initial;
    -webkit-transform: none;
            transform: none;
    padding-top: 40px; }
    .sign-in-page__panel-wrap .bg-texture,
    .sign-in-page__panel-wrap .bg-girl {
      display: none; }
  .sign-in-page__panel--workspace {
    padding: 10px 30px; }
  .sign-in-page__panel--signin {
    padding: 10px 30px; }
  .sign-in-page__panel--find {
    padding: 10px 30px; }
    .sign-in-page__panel--find .sign-in-page__panel__form-group {
      margin-bottom: 40px; }
    .sign-in-page__panel--find__banner {
      margin-top: 36px; }
    .sign-in-page__panel--find__bnt-back {
      top: -34px; }
    .sign-in-page__panel--find .sign-in-page__footer {
      margin-top: 25px; }
  .sign-in-page__panel--find-password {
    padding: 10px 30px;
    margin-bottom: 150px; }
    .sign-in-page__panel--find-password__form {
      margin-top: 87px; }
    .sign-in-page__panel--find-password__sent p {
      margin-bottom: 105px; }
    .sign-in-page__panel--find-password__sent img {
      width: 209px; }
  .sign-in-page__footer {
    margin: 80px 0;
    color: #222426; }
    .sign-in-page__footer__p {
      margin: 0 0 15px; }
      .sign-in-page__footer__p--create-ws {
        padding-left: 0; }
    .sign-in-page__footer__a {
      display: block;
      color: #7552f6;
      margin: 0; } }

@media (max-width: 385px) {
  .sign-in-page__panel--signin__forgot {
    font-size: 11px; } }

div.modal-dialog.fs-modal.fs-privacy {
  padding: 0 60px; }
  div.modal-dialog.fs-modal.fs-privacy .main-wrapper .content {
    width: 100%;
    padding-bottom: 60px;
    text-align: left; }
  div.modal-dialog.fs-modal.fs-privacy .main-wrapper .content h3 {
    font-size: 18px;
    font-weight: 600; }
  div.modal-dialog.fs-modal.fs-privacy .main-wrapper .content p {
    margin: 10px 0; }
  div.modal-dialog.fs-modal.fs-privacy .main-wrapper .content a {
    color: #7552f6;
    text-decoration: none; }
    div.modal-dialog.fs-modal.fs-privacy .main-wrapper .content a:hover {
      text-decoration: underline; }

div.modal-dialog.fs-modal {
  width: 100%; }
  div.modal-dialog.fs-modal .header-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-image: linear-gradient(90deg, #925aff, #684ef2); }
  div.modal-dialog.fs-modal.fs-tab {
    position: relative; }
  div.modal-dialog.fs-modal .main-wrapper .main-logo {
    text-align: center; }
  div.modal-dialog.fs-modal .main-wrapper .main-title-msg {
    margin-top: 18px;
    margin-bottom: 32px;
    text-align: center;
    font-size: 27px; }
    div.modal-dialog.fs-modal .main-wrapper .main-title-msg span {
      font-weight: 400;
      color: #314151; }
    div.modal-dialog.fs-modal .main-wrapper .main-title-msg strong {
      color: #314151;
      font-weight: 600; }
    div.modal-dialog.fs-modal .main-wrapper .main-title-msg .main-msg {
      margin-top: 10px;
      font-size: 16px; }
      div.modal-dialog.fs-modal .main-wrapper .main-title-msg .main-msg p {
        font-size: 15px;
        color: #939ca6; }
  div.modal-dialog.fs-modal .main-wrapper .main-body {
    text-align: center; }
    div.modal-dialog.fs-modal .main-wrapper .main-body .user-form {
      position: relative;
      width: 310px;
      margin: auto;
      text-align: left; }
    div.modal-dialog.fs-modal .main-wrapper .main-body .user-form .pb-container {
      display: block;
      margin: 0 0 0 auto; }
      div.modal-dialog.fs-modal .main-wrapper .main-body .user-form .pb-container .pb-button span {
        font-size: 13px; }
    div.modal-dialog.fs-modal .main-wrapper .main-body .additor-input ~ .btn-container {
      margin-top: 30px; }
    div.modal-dialog.fs-modal .main-wrapper .main-body a {
      color: #b1b7bd;
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline; }
    div.modal-dialog.fs-modal .main-wrapper .main-body a.forget-passwd {
      position: absolute;
      left: 0;
      bottom: 14px;
      display: inline-block;
      margin-bottom: 9px;
      z-index: 25; }
      div.modal-dialog.fs-modal .main-wrapper .main-body a.forget-passwd:hover {
        color: #7552f6; }
  div.modal-dialog.fs-modal .main-footer {
    position: relative;
    text-align: center; }
    div.modal-dialog.fs-modal .main-footer .main-footer-wrapper {
      width: 310px;
      margin: 0 auto;
      text-align: left; }
      div.modal-dialog.fs-modal .main-footer .main-footer-wrapper .main-footer-msg {
        margin-top: 28px;
        text-align: left;
        color: #939ca6; }
        div.modal-dialog.fs-modal .main-footer .main-footer-wrapper .main-footer-msg a {
          color: #b1b7bd;
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline; }
        div.modal-dialog.fs-modal .main-footer .main-footer-wrapper .main-footer-msg a.forget-passwd {
          position: relative;
          bottom: auto;
          bottom: initial;
          display: inline-block;
          margin-bottom: 9px; }
        div.modal-dialog.fs-modal .main-footer .main-footer-wrapper .main-footer-msg p {
          color: #c7cbd0;
          font-size: 14px; }
        div.modal-dialog.fs-modal .main-footer .main-footer-wrapper .main-footer-msg p a {
          margin-left: 5px; }
        div.modal-dialog.fs-modal .main-footer .main-footer-wrapper .main-footer-msg a:hover {
          color: #7552f6; }
      div.modal-dialog.fs-modal .main-footer .main-footer-wrapper .privacy-policy {
        margin-top: 15px; }
  div.modal-dialog.fs-modal.fluid .modal-content {
    width: 100%; }
  div.modal-dialog.fs-modal.join-team-signin .main-wrapper .main-msg {
    line-height: 30px; }
  div.modal-dialog.fs-modal.join-team-signin .main-wrapper .main-body {
    margin-top: 60px; }

.fs-tab .tab-wrapper {
  border-bottom: solid 1px #f2f2f2; }

.fs-tab .tab-header {
  width: 170px;
  margin: 0 auto -1px;
  -webkit-font-smoothing: antialiased; }
  .fs-tab .tab-header .tab-selector {
    display: inline-block;
    width: 50px;
    height: 30px;
    margin: 0 17.5px;
    cursor: pointer;
    color: #989ea5;
    font-size: 12px;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .fs-tab .tab-header .tab-selector.selected {
    color: #7552f6;
    border-bottom: solid 1px #7552f6; }

.fs-tab .tab-body {
  min-height: 480px;
  padding-top: 35px; }

.crx-sign-in {
  width: 100%;
  max-width: 335px;
  padding: 24px;
  font-family: 'system-ui', 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR', sans-serif; }
  .crx-sign-in__title {
    font-size: 20px;
    font-weight: 600;
    color: #222426; }
  .crx-sign-in__main-img {
    width: 100%;
    margin-bottom: 47px; }
  .crx-sign-in__google-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 3px;
    background-color: #7552f6;
    cursor: pointer;
    margin-bottom: 16px; }
    .crx-sign-in__google-btn:hover {
      background-color: #623ff0; }
    .crx-sign-in__google-btn svg {
      margin-right: 3px; }
  .crx-sign-in__slack-btn, .crx-sign-in__apple-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    color: #a2a3a7;
    font-size: 13px;
    font-weight: 500;
    outline: none;
    border-radius: 3px;
    background-color: #ffffff;
    border: 1px solid #a2a3a7;
    cursor: pointer;
    margin-bottom: 16px; }
    .crx-sign-in__slack-btn:hover, .crx-sign-in__apple-btn:hover {
      border-color: #606367;
      color: #606367; }
    .crx-sign-in__slack-btn svg, .crx-sign-in__apple-btn svg {
      margin-right: 3px; }
  .crx-sign-in__email-guide {
    font-size: 13px;
    color: #606367;
    margin-bottom: 38px;
    text-align: center; }
    .crx-sign-in__email-guide a {
      color: #7552f6;
      text-decoration: underline;
      cursor: pointer; }
  .crx-sign-in__footer-guide {
    font-size: 13px;
    text-align: center;
    color: #a2a3a7; }
  .crx-sign-in__email-label {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    color: #606367; }
  .crx-sign-in__email-input {
    display: block;
    width: 100%;
    height: 36px;
    border-radius: 3px;
    background-color: #f5f5f5;
    padding: 6px 10px;
    color: #222426;
    box-sizing: border-box;
    outline: none;
    border: none; }
  .crx-sign-in__error-msg {
    font-size: 12px;
    color: #fa5760;
    margin: 0; }
  .crx-sign-in__submit-btn {
    display: block;
    width: 100%;
    height: 36px;
    border: 1px solid #7552f6;
    background-color: #fff;
    color: #7552f6;
    border-radius: 3px;
    margin: 12px 0 38px;
    cursor: pointer; }
    .crx-sign-in__submit-btn:hover {
      background-color: #7552f6;
      color: #fff; }

.email-sent {
  width: 335px;
  padding: 24px;
  color: #222426; }
  .email-sent__back-btn {
    margin-bottom: 15px; }
  .email-sent__title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 12px; }
  .email-sent__about {
    margin: 0 0 16px;
    font-size: 14px; }
  .email-sent__resend-btn {
    display: block;
    color: #7552f6;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 0 5px; }
  .email-sent__video {
    width: 100%; }
  .email-sent__timer {
    color: #a2a3a7;
    font-size: 12px;
    margin: 0 0 20px; }

#slack {
  overflow: hidden;
  position: relative; }
  #slack h2 {
    font-size: 32px;
    font-weight: 900;
    margin: 0 0 10px 0; }
  #slack .section {
    margin: 0 auto; }
    #slack .section.s-get-noti {
      margin: 0 auto 360px; }
  #slack .divider-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    position: relative;
    padding: 0 8px;
    margin: 180px auto; }
  #slack .divider {
    width: 100%;
    height: 1px;
    background-color: #606367; }
  #slack .divider-dot {
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 64px;
    height: 64px;
    background-color: #fff; }
    #slack .divider-dot:before {
      content: '';
      width: 3px;
      height: 3px;
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 1px);
      background-color: #606367;
      border-radius: 50%; }

@media (max-width: 80rem) {
  #slack .divider-wrapper {
    padding: 0 50px; } }

@media (max-width: 51.99rem) {
  #slack .divider-wrapper {
    margin: 90px auto; } }

@media (max-width: 39.99rem) {
  #slack .divider-wrapper {
    margin: 60px auto; }
  #slack h2 {
    font-size: 24px; }
  #slack .section.s-get-noti {
    margin: 0 auto 180px; } }

.hero--slack {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 150px;
  position: relative; }
  .hero--slack .hero__h1 {
    font-size: 52px;
    font-weight: 900;
    line-height: 1.35;
    margin: 0 0 20px 0; }
  .hero--slack .hero__main-phrases-wrapper {
    width: 610px;
    height: 560px;
    position: relative;
    z-index: 3;
    padding: 70px 60px;
    margin-right: 92px;
    border-radius: 5px;
    box-shadow: 3px 3px 0 0 #000000;
    border: solid 2px #222426;
    background-color: #fff; }
  .hero--slack .hero__img-wrapper {
    width: 450px; }
  .hero--slack .hero__description {
    font-size: 22px;
    line-height: 1.5;
    margin: 0 0 40px 0; }
  .hero--slack a:hover {
    text-decoration: none; }
  .hero--slack .hero__slack-button {
    display: flex;
    width: 253px;
    justify-content: center;
    align-items: center;
    height: 56px;
    font-size: 20px;
    font-weight: 900;
    border-radius: 3px;
    color: #fff;
    box-shadow: 3px 3px 0 0 #4929cc;
    background-color: #7552f6;
    cursor: pointer;
    border: none;
    outline: none;
    font-family: Avenir, 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR', sans-serif; }
    .hero--slack .hero__slack-button svg {
      width: 24px;
      height: 24px;
      margin-right: 10px; }
    .hero--slack .hero__slack-button:hover {
      background-color: #623ff0; }

.hero__block-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.hero__block1 {
  width: 400px;
  position: absolute;
  top: 658px;
  left: calc(((100% - 1680px) / 2) + 140px); }

@media (max-width: 105rem) {
  .hero__block1 {
    left: 60px; }
  .hero--slack {
    max-width: calc(1280px - (1680px - 100%)); } }

@media (max-width: 90rem) {
  .hero--slack .hero__main-phrases-wrapper {
    padding: 44px; }
  .hero--slack {
    max-width: calc(100% - 188px); } }

@media (max-width: 80rem) {
  .hero--slack .hero__main-phrases-wrapper {
    padding: 44px; } }

@media (max-width: 1140px) {
  .hero--slack {
    max-width: calc(100% - 64px); }
  .hero--slack .hero__main-phrases-wrapper {
    min-width: 426px;
    height: 460px;
    margin-right: 0;
    margin-left: 0;
    width: 100%; }
  .hero--slack .hero__h1 {
    font-size: 42px; }
  .hero--slack .hero__description {
    font-size: 22px; }
  .hero--slack .hero__img-wrapper {
    display: none; }
  .hero__block1 {
    left: 0;
    top: 560px; } }

@media (max-width: 39.99rem) {
  .hero--slack {
    max-width: calc(100% - 32px);
    margin-bottom: 82px; }
  .hero--slack .hero__main-phrases-wrapper {
    height: 468px;
    min-width: 0;
    min-width: initial; }
  .hero--slack .hero__h1 {
    font-size: 32px; }
  .hero--slack .hero__description {
    font-size: 20px;
    margin: 0 0 30px 0; }
  .hero__block1 {
    width: 335px;
    top: 582px; } }

@media (max-width: 26.5rem) {
  .hero--slack .hero__main-phrases-wrapper {
    padding: 30px; }
  .hero--slack .hero__slack-button {
    width: 237px;
    margin-left: -10px; } }

@media (max-width: 360px) {
  .hero--slack .hero__main-phrases-wrapper {
    height: 468px;
    min-width: 0;
    min-width: initial; }
  .hero--slack .hero__h1 {
    font-size: 28px; }
  .hero--slack .hero__description {
    font-size: 18px;
    margin: 0 0 30px 0; } }

.s-grab-idea__description {
  font-size: 22px;
  margin: 0 0 80px 0; }

.s-grab-idea__video-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 680px;
  position: relative;
  padding: 0 2px;
  margin-left: -8px;
  border-radius: 5px;
  box-shadow: 3px -3px 0 0 #222426;
  border: solid 2px #000000;
  background-color: #ffffff; }
  .s-grab-idea__video-wrapper:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: -20px;
    right: -20px;
    border-radius: 5px;
    border: solid 2px #000000;
    background-color: #dad1ff;
    z-index: -1; }
  .s-grab-idea__video-wrapper video {
    width: 100%; }

@media (max-width: 80rem) {
  .s-grab-idea__video-wrapper {
    padding: 10px 2px;
    height: auto; } }

@media (max-width: 39.99rem) {
  .s-grab-idea__description {
    font-size: 15px;
    margin: 0 0 40px 0; }
  .s-grab-idea__video-wrapper:before {
    top: -10px;
    right: -10px; } }

.s-message-to-document {
  position: relative; }
  .s-message-to-document__description {
    font-size: 22px;
    margin: 0 0 80px 0; }
  .s-message-to-document__img-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative; }
    .s-message-to-document__img-wrapper img {
      width: 100%; }
  .s-message-to-document__block1 {
    position: absolute;
    right: -201px;
    bottom: -97px; }

@media (max-width: 39.99rem) {
  .s-message-to-document__description {
    font-size: 15px;
    margin: 0 0 40px 0; } }

.s-note-command {
  position: relative; }
  .s-note-command__description {
    font-size: 22px;
    margin: 0 0 80px 0; }
  .s-note-command__img-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative; }
    .s-note-command__img-wrapper img {
      width: 100%; }
  .s-note-command__block1 {
    position: absolute;
    left: -199px;
    bottom: -176px; }

@media (max-width: 39.99rem) {
  .s-note-command__description {
    font-size: 15px;
    margin: 0 0 40px 0; } }

.s-launch-page__description {
  font-size: 22px;
  margin: 0 0 80px 0; }

.s-launch-page__img-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative; }
  .s-launch-page__img-wrapper img {
    width: 100%; }

@media (max-width: 39.99rem) {
  .s-launch-page__description {
    font-size: 15px;
    margin: 0 0 40px 0; } }

.s-get-noti {
  display: flex;
  justify-content: space-between;
  position: relative; }
  .s-get-noti__description {
    font-size: 22px; }
  .s-get-noti__phrase-wrapper {
    max-width: 480px;
    min-width: 320px;
    margin-right: 50px; }
  .s-get-noti__img-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 670px;
    position: relative; }
    .s-get-noti__img-wrapper img {
      width: 100%; }
  .s-get-noti__block1 {
    position: absolute;
    top: 301px;
    left: 80px; }

@media (max-width: 80rem) {
  .s-get-noti__block1 {
    display: none; } }

@media (max-width: 1024px) {
  .s-get-noti {
    display: block;
    text-align: center; }
    .s-get-noti__phrase-wrapper {
      width: 100%;
      max-width: none;
      max-width: initial; }
    .s-get-noti__description {
      margin-bottom: 30px; }
    .s-get-noti__img-wrapper {
      max-width: none;
      max-width: initial; } }

@media (max-width: 39.99rem) {
  .s-get-noti__description {
    font-size: 15px;
    margin: 0 0 40px 0; } }

.slack__footer {
  width: 100%;
  height: 450px;
  background-color: #493185;
  padding: 130px 40px 0; }
  .slack__footer__navi-wrapper {
    width: 100%;
    color: #fff;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 200px 200px 200px 200px 200px;
    grid-template-rows: auto; }
  .slack__footer__navi--logo svg path {
    fill: #fff; }
  .slack__footer__navi--product p {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-weight: 900; }
  .slack__footer__navi--product a {
    display: block;
    height: 24px;
    line-height: 24px;
    margin: 0 0 20px 0;
    font-size: 17px;
    font-weight: 500;
    color: #fff; }
  .slack__footer__navi--product a[disabled] {
    pointer-events: none; }
    .slack__footer__navi--product a[disabled]:after {
      content: 'Coming Soon';
      display: inline-block;
      padding: 0 4px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      margin-left: 24px;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.15); }
  .slack__footer__navi--social p {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 20px 0;
    text-align: right; }
  .slack__footer__navi--social a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px; }
  .slack__footer__navi--social .logo--linkedin {
    width: 20px; }
  .slack__footer__navi--social .logo--twitter {
    padding-top: 3px;
    width: 20px; }
  .slack__footer__navi--social .logo--facebook {
    width: 11px; }
  .slack__footer__navi--social .logo--producthunt {
    width: 20px; }
  .slack__footer__social-menu {
    display: flex;
    justify-content: flex-end; }

@media (max-width: 80rem) {
  .slack__footer__navi-wrapper {
    grid-template-columns: 200px 200px 0 0 200px; } }

@media (max-width: 51.99rem) {
  .slack__footer {
    padding-top: 50px; }
    .slack__footer__navi-wrapper {
      grid-template-columns: 100%;
      grid-template-rows: 70px auto 0 0 auto; }
    .slack__footer__navi--social p {
      margin-top: 10px;
      text-align: left;
      text-align: initial; }
    .slack__footer__social-menu {
      justify-content: initial; }
      .slack__footer__social-menu a:first-child {
        margin-left: 0; } }

#AdditorLandingRoot.theme--white {
  color: #222426;
  background: #fff; }
  #AdditorLandingRoot.theme--white .root__header__priority {
    color: #7552f6; }

#AdditorLandingRoot.theme--purple {
  color: #fff;
  background: #7552f6; }
  #AdditorLandingRoot.theme--purple .root__header__priority {
    color: #fff; }
  #AdditorLandingRoot.theme--purple .root__header__namelogo > svg path {
    fill: #fff; }

@media (max-width: 39.99rem) {
  #AdditorLandingRoot.theme--purple {
    color: #222426;
    background: #fff; }
    #AdditorLandingRoot.theme--purple .root__header__priority {
      color: #7552f6; }
    #AdditorLandingRoot.theme--purple .root__header__namelogo > svg path {
      fill: #7552f6; } }

.col {
  float: left;
  box-sizing: border-box; }

.col-right {
  float: right;
  box-sizing: border-box; }

.col-1 {
  width: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.col-12 {
  width: 100%; }

.width-full {
  width: 100%; }

.height-full {
  height: 100%; }

.width-fifth {
  width: 20%; }

@media (max-width: 39.99rem) {
  .xs-col {
    float: left;
    box-sizing: border-box; }
  .xs-col-right {
    float: right;
    box-sizing: border-box; }
  .xs-col-1 {
    width: 8.33333%; }
  .xs-col-2 {
    width: 16.66667%; }
  .xs-col-3 {
    width: 25%; }
  .xs-col-4 {
    width: 33.33333%; }
  .xs-col-5 {
    width: 41.66667%; }
  .xs-col-6 {
    width: 50%; }
  .xs-col-7 {
    width: 58.33333%; }
  .xs-col-8 {
    width: 66.66667%; }
  .xs-col-9 {
    width: 75%; }
  .xs-col-10 {
    width: 83.33333%; }
  .xs-col-11 {
    width: 91.66667%; }
  .xs-col-12 {
    width: 100%; }
  .xs-width-full {
    width: 100%; }
  .xs-height-full {
    height: 100%; }
  .xs-width-fifth {
    width: 20%; } }

@media (min-width: 40rem) {
  .sm-col {
    float: left;
    box-sizing: border-box; }
  .sm-col-right {
    float: right;
    box-sizing: border-box; }
  .sm-col-1 {
    width: 8.33333%; }
  .sm-col-2 {
    width: 16.66667%; }
  .sm-col-3 {
    width: 25%; }
  .sm-col-4 {
    width: 33.33333%; }
  .sm-col-5 {
    width: 41.66667%; }
  .sm-col-6 {
    width: 50%; }
  .sm-col-7 {
    width: 58.33333%; }
  .sm-col-8 {
    width: 66.66667%; }
  .sm-col-9 {
    width: 75%; }
  .sm-col-10 {
    width: 83.33333%; }
  .sm-col-11 {
    width: 91.66667%; }
  .sm-col-12 {
    width: 100%; }
  .sm-width-full {
    width: 100%; }
  .sm-height-full {
    height: 100%; }
  .sm-width-fifth {
    width: 20%; } }

@media (min-width: 52rem) {
  .md-col {
    float: left;
    box-sizing: border-box; }
  .md-col-right {
    float: right;
    box-sizing: border-box; }
  .md-col-1 {
    width: 8.33333%; }
  .md-col-2 {
    width: 16.66667%; }
  .md-col-3 {
    width: 25%; }
  .md-col-4 {
    width: 33.33333%; }
  .md-col-5 {
    width: 41.66667%; }
  .md-col-6 {
    width: 50%; }
  .md-col-7 {
    width: 58.33333%; }
  .md-col-8 {
    width: 66.66667%; }
  .md-col-9 {
    width: 75%; }
  .md-col-10 {
    width: 83.33333%; }
  .md-col-11 {
    width: 91.66667%; }
  .md-col-12 {
    width: 100%; }
  .md-width-full {
    width: 100%; }
  .md-height-full {
    height: 100%; }
  .md-width-fifth {
    width: 20%; } }

@media (min-width: 72rem) {
  .lg-col {
    float: left;
    box-sizing: border-box; }
  .lg-col-right {
    float: right;
    box-sizing: border-box; }
  .lg-col-1 {
    width: 8.33333%; }
  .lg-col-2 {
    width: 16.66667%; }
  .lg-col-3 {
    width: 25%; }
  .lg-col-4 {
    width: 33.33333%; }
  .lg-col-5 {
    width: 41.66667%; }
  .lg-col-6 {
    width: 50%; }
  .lg-col-7 {
    width: 58.33333%; }
  .lg-col-8 {
    width: 66.66667%; }
  .lg-col-9 {
    width: 75%; }
  .lg-col-10 {
    width: 83.33333%; }
  .lg-col-11 {
    width: 91.66667%; }
  .lg-col-12 {
    width: 100%; }
  .lg-width-full {
    width: 100%; }
  .lg-height-full {
    height: 100%; }
  .lg-width-fifth {
    width: 20%; } }

.p0 {
  padding: 0; }

.pt0 {
  padding-top: 0; }

.pr0 {
  padding-right: 0; }

.pb0 {
  padding-bottom: 0; }

.pl0 {
  padding-left: 0; }

.px0 {
  padding-left: 0;
  padding-right: 0; }

.py0 {
  padding-top: 0;
  padding-bottom: 0; }

.p-half {
  padding: 0.25rem; }

.pt-half {
  padding-top: 0.25rem; }

.pr-half {
  padding-right: 0.25rem; }

.pb-half {
  padding-bottom: 0.25rem; }

.pl-half {
  padding-left: 0.25rem; }

.px-half {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.py-half {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.p1 {
  padding: 0.5rem; }

.pt1 {
  padding-top: 0.5rem; }

.pr1 {
  padding-right: 0.5rem; }

.pb1 {
  padding-bottom: 0.5rem; }

.pl1 {
  padding-left: 0.5rem; }

.py1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.px1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.p2 {
  padding: 1rem; }

.pt2 {
  padding-top: 1rem; }

.pr2 {
  padding-right: 1rem; }

.pb2 {
  padding-bottom: 1rem; }

.pl2 {
  padding-left: 1rem; }

.py2 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.px2 {
  padding-left: 1rem;
  padding-right: 1rem; }

.p3 {
  padding: 2rem; }

.pt3 {
  padding-top: 2rem; }

.pr3 {
  padding-right: 2rem; }

.pb3 {
  padding-bottom: 2rem; }

.pl3 {
  padding-left: 2rem; }

.py3 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.px3 {
  padding-left: 2rem;
  padding-right: 2rem; }

.p4 {
  padding: 4rem; }

.pt4 {
  padding-top: 4rem; }

.pr4 {
  padding-right: 4rem; }

.pb4 {
  padding-bottom: 4rem; }

.pl4 {
  padding-left: 4rem; }

.py4 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.px4 {
  padding-left: 4rem;
  padding-right: 4rem; }

.p5 {
  padding: 8rem; }

.pt5 {
  padding-top: 8rem; }

.pr5 {
  padding-right: 8rem; }

.pb5 {
  padding-bottom: 8rem; }

.pl5 {
  padding-left: 8rem; }

.py5 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.px5 {
  padding-left: 8rem;
  padding-right: 8rem; }

.m0 {
  margin: 0; }

.mt0 {
  margin-top: 0; }

.mr0 {
  margin-right: 0; }

.mb0 {
  margin-bottom: 0; }

.ml0 {
  margin-left: 0; }

.mx0 {
  margin-left: 0;
  margin-right: 0; }

.my0 {
  margin-top: 0;
  margin-bottom: 0; }

.m-half {
  margin: 0.25rem; }

.mt-half {
  margin-top: 0.25rem; }

.mr-half {
  margin-right: 0.25rem; }

.mb-half {
  margin-bottom: 0.25rem; }

.ml-half {
  margin-left: 0.25rem; }

.mx-half {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.my-half {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.m1 {
  margin: 0.5rem; }

.mt1 {
  margin-top: 0.5rem; }

.mr1 {
  margin-right: 0.5rem; }

.mb1 {
  margin-bottom: 0.5rem; }

.ml1 {
  margin-left: 0.5rem; }

.mx1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.my1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.m2 {
  margin: 1rem; }

.mt2 {
  margin-top: 1rem; }

.mr2 {
  margin-right: 1rem; }

.mb2 {
  margin-bottom: 1rem; }

.ml2 {
  margin-left: 1rem; }

.mx2 {
  margin-left: 1rem;
  margin-right: 1rem; }

.my2 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.m3 {
  margin: 2rem; }

.mt3 {
  margin-top: 2rem; }

.mr3 {
  margin-right: 2rem; }

.mb3 {
  margin-bottom: 2rem; }

.ml3 {
  margin-left: 2rem; }

.mx3 {
  margin-left: 2rem;
  margin-right: 2rem; }

.my3 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.m4 {
  margin: 4rem; }

.mt4 {
  margin-top: 4rem; }

.mr4 {
  margin-right: 4rem; }

.mb4 {
  margin-bottom: 4rem; }

.ml4 {
  margin-left: 4rem; }

.mx4 {
  margin-left: 4rem;
  margin-right: 4rem; }

.my4 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.m5 {
  margin: 8rem; }

.mt5 {
  margin-top: 8rem; }

.mr5 {
  margin-right: 8rem; }

.mb5 {
  margin-bottom: 8rem; }

.ml5 {
  margin-left: 8rem; }

.mx5 {
  margin-left: 8rem;
  margin-right: 8rem; }

.my5 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

.mln1 {
  margin-left: -0.5rem; }

.mln2 {
  margin-left: -1rem; }

.mln3 {
  margin-left: -2rem; }

.mln4 {
  margin-left: -4rem; }

.mln5 {
  margin-top: -8rem; }

.mtn1 {
  margin-top: -0.5rem; }

.mtn2 {
  margin-top: -1rem; }

.mtn3 {
  margin-top: -2rem; }

.mtn4 {
  margin-top: -4rem; }

.mtn5 {
  margin-top: -8rem; }

.mxn1 {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.mxn2 {
  margin-left: -1rem;
  margin-right: -1rem; }

.mxn3 {
  margin-left: -2rem;
  margin-right: -2rem; }

.mxn4 {
  margin-left: -4rem;
  margin-right: -4rem; }

.mxn5 {
  margin-left: -8rem;
  margin-right: -8rem; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

@media (max-width: 39.99rem) {
  .xs-p0 {
    padding: 0; }
  .xs-pt0 {
    padding-top: 0; }
  .xs-pr0 {
    padding-right: 0; }
  .xs-pb0 {
    padding-bottom: 0; }
  .xs-pl0 {
    padding-left: 0; }
  .xs-px0 {
    padding-left: 0;
    padding-right: 0; }
  .xs-py0 {
    padding-top: 0;
    padding-bottom: 0; }
  .xs-p-half {
    padding: 0.25rem; }
  .xs-pt-half {
    padding-top: 0.25rem; }
  .xs-pr-half {
    padding-right: 0.25rem; }
  .xs-pb-half {
    padding-bottom: 0.25rem; }
  .xs-pl-half {
    padding-left: 0.25rem; }
  .xs-px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .xs-py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .xs-p1 {
    padding: 0.5rem; }
  .xs-pt1 {
    padding-top: 0.5rem; }
  .xs-pr1 {
    padding-right: 0.5rem; }
  .xs-pb1 {
    padding-bottom: 0.5rem; }
  .xs-pl1 {
    padding-left: 0.5rem; }
  .xs-py1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .xs-px1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .xs-p2 {
    padding: 1rem; }
  .xs-pt2 {
    padding-top: 1rem; }
  .xs-pr2 {
    padding-right: 1rem; }
  .xs-pb2 {
    padding-bottom: 1rem; }
  .xs-pl2 {
    padding-left: 1rem; }
  .xs-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .xs-px2 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .xs-p3 {
    padding: 2rem; }
  .xs-pt3 {
    padding-top: 2rem; }
  .xs-pr3 {
    padding-right: 2rem; }
  .xs-pb3 {
    padding-bottom: 2rem; }
  .xs-pl3 {
    padding-left: 2rem; }
  .xs-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .xs-px3 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .xs-p4 {
    padding: 4rem; }
  .xs-pt4 {
    padding-top: 4rem; }
  .xs-pr4 {
    padding-right: 4rem; }
  .xs-pb4 {
    padding-bottom: 4rem; }
  .xs-pl4 {
    padding-left: 4rem; }
  .xs-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .xs-px4 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .xs-p5 {
    padding: 8rem; }
  .xs-pt5 {
    padding-top: 8rem; }
  .xs-pr5 {
    padding-right: 8rem; }
  .xs-pb5 {
    padding-bottom: 8rem; }
  .xs-pl5 {
    padding-left: 8rem; }
  .xs-py5 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .xs-px5 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .xs-m0 {
    margin: 0; }
  .xs-mt0 {
    margin-top: 0; }
  .xs-mr0 {
    margin-right: 0; }
  .xs-mb0 {
    margin-bottom: 0; }
  .xs-ml0 {
    margin-left: 0; }
  .xs-mx0 {
    margin-left: 0;
    margin-right: 0; }
  .xs-my0 {
    margin-top: 0;
    margin-bottom: 0; }
  .xs-m-half {
    margin: 0.25rem; }
  .xs-mt-half {
    margin-top: 0.25rem; }
  .xs-mr-half {
    margin-right: 0.25rem; }
  .xs-mb-half {
    margin-bottom: 0.25rem; }
  .xs-ml-half {
    margin-left: 0.25rem; }
  .xs-mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .xs-my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .xs-m1 {
    margin: 0.5rem; }
  .xs-mt1 {
    margin-top: 0.5rem; }
  .xs-mr1 {
    margin-right: 0.5rem; }
  .xs-mb1 {
    margin-bottom: 0.5rem; }
  .xs-ml1 {
    margin-left: 0.5rem; }
  .xs-mx1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .xs-my1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .xs-m2 {
    margin: 1rem; }
  .xs-mt2 {
    margin-top: 1rem; }
  .xs-mr2 {
    margin-right: 1rem; }
  .xs-mb2 {
    margin-bottom: 1rem; }
  .xs-ml2 {
    margin-left: 1rem; }
  .xs-mx2 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .xs-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .xs-m3 {
    margin: 2rem; }
  .xs-mt3 {
    margin-top: 2rem; }
  .xs-mr3 {
    margin-right: 2rem; }
  .xs-mb3 {
    margin-bottom: 2rem; }
  .xs-ml3 {
    margin-left: 2rem; }
  .xs-mx3 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .xs-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .xs-m4 {
    margin: 4rem; }
  .xs-mt4 {
    margin-top: 4rem; }
  .xs-mr4 {
    margin-right: 4rem; }
  .xs-mb4 {
    margin-bottom: 4rem; }
  .xs-ml4 {
    margin-left: 4rem; }
  .xs-mx4 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .xs-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .xs-m5 {
    margin: 8rem; }
  .xs-mt5 {
    margin-top: 8rem; }
  .xs-mr5 {
    margin-right: 8rem; }
  .xs-mb5 {
    margin-bottom: 8rem; }
  .xs-ml5 {
    margin-left: 8rem; }
  .xs-mx5 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .xs-my5 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .xs-mln1 {
    margin-left: -0.5rem; }
  .xs-mln2 {
    margin-left: -1rem; }
  .xs-mln3 {
    margin-left: -2rem; }
  .xs-mln4 {
    margin-left: -4rem; }
  .xs-mln5 {
    margin-top: -8rem; }
  .xs-mtn1 {
    margin-top: -0.5rem; }
  .xs-mtn2 {
    margin-top: -1rem; }
  .xs-mtn3 {
    margin-top: -2rem; }
  .xs-mtn4 {
    margin-top: -4rem; }
  .xs-mtn5 {
    margin-top: -8rem; }
  .xs-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .xs-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .xs-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .xs-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .xs-mxn5 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .xs-ml-auto {
    margin-left: auto; }
  .xs-mr-auto {
    margin-right: auto; }
  .xs-mx-auto {
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 40rem) and (max-width: 51.99rem) {
  .sm-p0 {
    padding: 0; }
  .sm-pt0 {
    padding-top: 0; }
  .sm-pr0 {
    padding-right: 0; }
  .sm-pb0 {
    padding-bottom: 0; }
  .sm-pl0 {
    padding-left: 0; }
  .sm-px0 {
    padding-left: 0;
    padding-right: 0; }
  .sm-py0 {
    padding-top: 0;
    padding-bottom: 0; }
  .sm-p-half {
    padding: 0.25rem; }
  .sm-pt-half {
    padding-top: 0.25rem; }
  .sm-pr-half {
    padding-right: 0.25rem; }
  .sm-pb-half {
    padding-bottom: 0.25rem; }
  .sm-pl-half {
    padding-left: 0.25rem; }
  .sm-px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .sm-py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .sm-p1 {
    padding: 0.5rem; }
  .sm-pt1 {
    padding-top: 0.5rem; }
  .sm-pr1 {
    padding-right: 0.5rem; }
  .sm-pb1 {
    padding-bottom: 0.5rem; }
  .sm-pl1 {
    padding-left: 0.5rem; }
  .sm-py1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .sm-px1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .sm-p2 {
    padding: 1rem; }
  .sm-pt2 {
    padding-top: 1rem; }
  .sm-pr2 {
    padding-right: 1rem; }
  .sm-pb2 {
    padding-bottom: 1rem; }
  .sm-pl2 {
    padding-left: 1rem; }
  .sm-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .sm-px2 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .sm-p3 {
    padding: 2rem; }
  .sm-pt3 {
    padding-top: 2rem; }
  .sm-pr3 {
    padding-right: 2rem; }
  .sm-pb3 {
    padding-bottom: 2rem; }
  .sm-pl3 {
    padding-left: 2rem; }
  .sm-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .sm-px3 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .sm-p4 {
    padding: 4rem; }
  .sm-pt4 {
    padding-top: 4rem; }
  .sm-pr4 {
    padding-right: 4rem; }
  .sm-pb4 {
    padding-bottom: 4rem; }
  .sm-pl4 {
    padding-left: 4rem; }
  .sm-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .sm-px4 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .sm-p5 {
    padding: 8rem; }
  .sm-pt5 {
    padding-top: 8rem; }
  .sm-pr5 {
    padding-right: 8rem; }
  .sm-pb5 {
    padding-bottom: 8rem; }
  .sm-pl5 {
    padding-left: 8rem; }
  .sm-py5 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .sm-px5 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .sm-m0 {
    margin: 0; }
  .sm-mt0 {
    margin-top: 0; }
  .sm-mr0 {
    margin-right: 0; }
  .sm-mb0 {
    margin-bottom: 0; }
  .sm-ml0 {
    margin-left: 0; }
  .sm-mx0 {
    margin-left: 0;
    margin-right: 0; }
  .sm-my0 {
    margin-top: 0;
    margin-bottom: 0; }
  .sm-m-half {
    margin: 0.25rem; }
  .sm-mt-half {
    margin-top: 0.25rem; }
  .sm-mr-half {
    margin-right: 0.25rem; }
  .sm-mb-half {
    margin-bottom: 0.25rem; }
  .sm-ml-half {
    margin-left: 0.25rem; }
  .sm-mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .sm-my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .sm-m1 {
    margin: 0.5rem; }
  .sm-mt1 {
    margin-top: 0.5rem; }
  .sm-mr1 {
    margin-right: 0.5rem; }
  .sm-mb1 {
    margin-bottom: 0.5rem; }
  .sm-ml1 {
    margin-left: 0.5rem; }
  .sm-mx1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .sm-my1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .sm-m2 {
    margin: 1rem; }
  .sm-mt2 {
    margin-top: 1rem; }
  .sm-mr2 {
    margin-right: 1rem; }
  .sm-mb2 {
    margin-bottom: 1rem; }
  .sm-ml2 {
    margin-left: 1rem; }
  .sm-mx2 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .sm-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .sm-m3 {
    margin: 2rem; }
  .sm-mt3 {
    margin-top: 2rem; }
  .sm-mr3 {
    margin-right: 2rem; }
  .sm-mb3 {
    margin-bottom: 2rem; }
  .sm-ml3 {
    margin-left: 2rem; }
  .sm-mx3 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .sm-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .sm-m4 {
    margin: 4rem; }
  .sm-mt4 {
    margin-top: 4rem; }
  .sm-mr4 {
    margin-right: 4rem; }
  .sm-mb4 {
    margin-bottom: 4rem; }
  .sm-ml4 {
    margin-left: 4rem; }
  .sm-mx4 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .sm-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .sm-m5 {
    margin: 8rem; }
  .sm-mt5 {
    margin-top: 8rem; }
  .sm-mr5 {
    margin-right: 8rem; }
  .sm-mb5 {
    margin-bottom: 8rem; }
  .sm-ml5 {
    margin-left: 8rem; }
  .sm-mx5 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .sm-my5 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .sm-mln1 {
    margin-left: -0.5rem; }
  .sm-mln2 {
    margin-left: -1rem; }
  .sm-mln3 {
    margin-left: -2rem; }
  .sm-mln4 {
    margin-left: -4rem; }
  .sm-mln5 {
    margin-top: -8rem; }
  .sm-mtn1 {
    margin-top: -0.5rem; }
  .sm-mtn2 {
    margin-top: -1rem; }
  .sm-mtn3 {
    margin-top: -2rem; }
  .sm-mtn4 {
    margin-top: -4rem; }
  .sm-mtn5 {
    margin-top: -8rem; }
  .sm-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .sm-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .sm-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .sm-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .sm-mxn5 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .sm-ml-auto {
    margin-left: auto; }
  .sm-mr-auto {
    margin-right: auto; }
  .sm-mx-auto {
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 52rem) and (max-width: 71.99rem) {
  .md-p0 {
    padding: 0; }
  .md-pt0 {
    padding-top: 0; }
  .md-pr0 {
    padding-right: 0; }
  .md-pb0 {
    padding-bottom: 0; }
  .md-pl0 {
    padding-left: 0; }
  .md-px0 {
    padding-left: 0;
    padding-right: 0; }
  .md-py0 {
    padding-top: 0;
    padding-bottom: 0; }
  .md-p-half {
    padding: 0.25rem; }
  .md-pt-half {
    padding-top: 0.25rem; }
  .md-pr-half {
    padding-right: 0.25rem; }
  .md-pb-half {
    padding-bottom: 0.25rem; }
  .md-pl-half {
    padding-left: 0.25rem; }
  .md-px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .md-py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .md-p1 {
    padding: 0.5rem; }
  .md-pt1 {
    padding-top: 0.5rem; }
  .md-pr1 {
    padding-right: 0.5rem; }
  .md-pb1 {
    padding-bottom: 0.5rem; }
  .md-pl1 {
    padding-left: 0.5rem; }
  .md-py1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .md-px1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .md-p2 {
    padding: 1rem; }
  .md-pt2 {
    padding-top: 1rem; }
  .md-pr2 {
    padding-right: 1rem; }
  .md-pb2 {
    padding-bottom: 1rem; }
  .md-pl2 {
    padding-left: 1rem; }
  .md-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .md-px2 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .md-p3 {
    padding: 2rem; }
  .md-pt3 {
    padding-top: 2rem; }
  .md-pr3 {
    padding-right: 2rem; }
  .md-pb3 {
    padding-bottom: 2rem; }
  .md-pl3 {
    padding-left: 2rem; }
  .md-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .md-px3 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .md-p4 {
    padding: 4rem; }
  .md-pt4 {
    padding-top: 4rem; }
  .md-pr4 {
    padding-right: 4rem; }
  .md-pb4 {
    padding-bottom: 4rem; }
  .md-pl4 {
    padding-left: 4rem; }
  .md-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .md-px4 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .md-p5 {
    padding: 8rem; }
  .md-pt5 {
    padding-top: 8rem; }
  .md-pr5 {
    padding-right: 8rem; }
  .md-pb5 {
    padding-bottom: 8rem; }
  .md-pl5 {
    padding-left: 8rem; }
  .md-py5 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .md-px5 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .md-m0 {
    margin: 0; }
  .md-mt0 {
    margin-top: 0; }
  .md-mr0 {
    margin-right: 0; }
  .md-mb0 {
    margin-bottom: 0; }
  .md-ml0 {
    margin-left: 0; }
  .md-mx0 {
    margin-left: 0;
    margin-right: 0; }
  .md-my0 {
    margin-top: 0;
    margin-bottom: 0; }
  .md-m-half {
    margin: 0.25rem; }
  .md-mt-half {
    margin-top: 0.25rem; }
  .md-mr-half {
    margin-right: 0.25rem; }
  .md-mb-half {
    margin-bottom: 0.25rem; }
  .md-ml-half {
    margin-left: 0.25rem; }
  .md-mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .md-my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .md-m1 {
    margin: 0.5rem; }
  .md-mt1 {
    margin-top: 0.5rem; }
  .md-mr1 {
    margin-right: 0.5rem; }
  .md-mb1 {
    margin-bottom: 0.5rem; }
  .md-ml1 {
    margin-left: 0.5rem; }
  .md-mx1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .md-my1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .md-m2 {
    margin: 1rem; }
  .md-mt2 {
    margin-top: 1rem; }
  .md-mr2 {
    margin-right: 1rem; }
  .md-mb2 {
    margin-bottom: 1rem; }
  .md-ml2 {
    margin-left: 1rem; }
  .md-mx2 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .md-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .md-m3 {
    margin: 2rem; }
  .md-mt3 {
    margin-top: 2rem; }
  .md-mr3 {
    margin-right: 2rem; }
  .md-mb3 {
    margin-bottom: 2rem; }
  .md-ml3 {
    margin-left: 2rem; }
  .md-mx3 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .md-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .md-m4 {
    margin: 4rem; }
  .md-mt4 {
    margin-top: 4rem; }
  .md-mr4 {
    margin-right: 4rem; }
  .md-mb4 {
    margin-bottom: 4rem; }
  .md-ml4 {
    margin-left: 4rem; }
  .md-mx4 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .md-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .md-m5 {
    margin: 8rem; }
  .md-mt5 {
    margin-top: 8rem; }
  .md-mr5 {
    margin-right: 8rem; }
  .md-mb5 {
    margin-bottom: 8rem; }
  .md-ml5 {
    margin-left: 8rem; }
  .md-mx5 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .md-my5 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .md-mln1 {
    margin-left: -0.5rem; }
  .md-mln2 {
    margin-left: -1rem; }
  .md-mln3 {
    margin-left: -2rem; }
  .md-mln4 {
    margin-left: -4rem; }
  .md-mln5 {
    margin-top: -8rem; }
  .md-mtn1 {
    margin-top: -0.5rem; }
  .md-mtn2 {
    margin-top: -1rem; }
  .md-mtn3 {
    margin-top: -2rem; }
  .md-mtn4 {
    margin-top: -4rem; }
  .md-mtn5 {
    margin-top: -8rem; }
  .md-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .md-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .md-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .md-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .md-mxn5 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .md-ml-auto {
    margin-left: auto; }
  .md-mr-auto {
    margin-right: auto; }
  .md-mx-auto {
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 72rem) {
  .lg-p0 {
    padding: 0; }
  .lg-pt0 {
    padding-top: 0; }
  .lg-pr0 {
    padding-right: 0; }
  .lg-pb0 {
    padding-bottom: 0; }
  .lg-pl0 {
    padding-left: 0; }
  .lg-px0 {
    padding-left: 0;
    padding-right: 0; }
  .lg-py0 {
    padding-top: 0;
    padding-bottom: 0; }
  .lg-p-half {
    padding: 0.25rem; }
  .lg-pt-half {
    padding-top: 0.25rem; }
  .lg-pr-half {
    padding-right: 0.25rem; }
  .lg-pb-half {
    padding-bottom: 0.25rem; }
  .lg-pl-half {
    padding-left: 0.25rem; }
  .lg-px-half {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .lg-py-half {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .lg-p1 {
    padding: 0.5rem; }
  .lg-pt1 {
    padding-top: 0.5rem; }
  .lg-pr1 {
    padding-right: 0.5rem; }
  .lg-pb1 {
    padding-bottom: 0.5rem; }
  .lg-pl1 {
    padding-left: 0.5rem; }
  .lg-py1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .lg-px1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .lg-p2 {
    padding: 1rem; }
  .lg-pt2 {
    padding-top: 1rem; }
  .lg-pr2 {
    padding-right: 1rem; }
  .lg-pb2 {
    padding-bottom: 1rem; }
  .lg-pl2 {
    padding-left: 1rem; }
  .lg-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .lg-px2 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .lg-p3 {
    padding: 2rem; }
  .lg-pt3 {
    padding-top: 2rem; }
  .lg-pr3 {
    padding-right: 2rem; }
  .lg-pb3 {
    padding-bottom: 2rem; }
  .lg-pl3 {
    padding-left: 2rem; }
  .lg-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .lg-px3 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .lg-p4 {
    padding: 4rem; }
  .lg-pt4 {
    padding-top: 4rem; }
  .lg-pr4 {
    padding-right: 4rem; }
  .lg-pb4 {
    padding-bottom: 4rem; }
  .lg-pl4 {
    padding-left: 4rem; }
  .lg-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .lg-px4 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .lg-p5 {
    padding: 8rem; }
  .lg-pt5 {
    padding-top: 8rem; }
  .lg-pr5 {
    padding-right: 8rem; }
  .lg-pb5 {
    padding-bottom: 8rem; }
  .lg-pl5 {
    padding-left: 8rem; }
  .lg-py5 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .lg-px5 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .lg-m0 {
    margin: 0; }
  .lg-mt0 {
    margin-top: 0; }
  .lg-mr0 {
    margin-right: 0; }
  .lg-mb0 {
    margin-bottom: 0; }
  .lg-ml0 {
    margin-left: 0; }
  .lg-mx0 {
    margin-left: 0;
    margin-right: 0; }
  .lg-my0 {
    margin-top: 0;
    margin-bottom: 0; }
  .lg-m-half {
    margin: 0.25rem; }
  .lg-mt-half {
    margin-top: 0.25rem; }
  .lg-mr-half {
    margin-right: 0.25rem; }
  .lg-mb-half {
    margin-bottom: 0.25rem; }
  .lg-ml-half {
    margin-left: 0.25rem; }
  .lg-mx-half {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .lg-my-half {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .lg-m1 {
    margin: 0.5rem; }
  .lg-mt1 {
    margin-top: 0.5rem; }
  .lg-mr1 {
    margin-right: 0.5rem; }
  .lg-mb1 {
    margin-bottom: 0.5rem; }
  .lg-ml1 {
    margin-left: 0.5rem; }
  .lg-mx1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .lg-my1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .lg-m2 {
    margin: 1rem; }
  .lg-mt2 {
    margin-top: 1rem; }
  .lg-mr2 {
    margin-right: 1rem; }
  .lg-mb2 {
    margin-bottom: 1rem; }
  .lg-ml2 {
    margin-left: 1rem; }
  .lg-mx2 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .lg-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .lg-m3 {
    margin: 2rem; }
  .lg-mt3 {
    margin-top: 2rem; }
  .lg-mr3 {
    margin-right: 2rem; }
  .lg-mb3 {
    margin-bottom: 2rem; }
  .lg-ml3 {
    margin-left: 2rem; }
  .lg-mx3 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .lg-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .lg-m4 {
    margin: 4rem; }
  .lg-mt4 {
    margin-top: 4rem; }
  .lg-mr4 {
    margin-right: 4rem; }
  .lg-mb4 {
    margin-bottom: 4rem; }
  .lg-ml4 {
    margin-left: 4rem; }
  .lg-mx4 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .lg-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .lg-m5 {
    margin: 8rem; }
  .lg-mt5 {
    margin-top: 8rem; }
  .lg-mr5 {
    margin-right: 8rem; }
  .lg-mb5 {
    margin-bottom: 8rem; }
  .lg-ml5 {
    margin-left: 8rem; }
  .lg-mx5 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .lg-my5 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .lg-mln1 {
    margin-left: -0.5rem; }
  .lg-mln2 {
    margin-left: -1rem; }
  .lg-mln3 {
    margin-left: -2rem; }
  .lg-mln4 {
    margin-left: -4rem; }
  .lg-mln5 {
    margin-top: -8rem; }
  .lg-mtn1 {
    margin-top: -0.5rem; }
  .lg-mtn2 {
    margin-top: -1rem; }
  .lg-mtn3 {
    margin-top: -2rem; }
  .lg-mtn4 {
    margin-top: -4rem; }
  .lg-mtn5 {
    margin-top: -8rem; }
  .lg-mxn1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .lg-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .lg-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .lg-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .lg-mxn5 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .lg-ml-auto {
    margin-left: auto; }
  .lg-mr-auto {
    margin-right: auto; }
  .lg-mx-auto {
    margin-left: auto;
    margin-right: auto; } }

.unround {
  border-radius: 0; }

.rounded {
  border-radius: 3px; }

.rounded-right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.rounded-left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.rounded-top {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; }

.rounded-bottom {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.rounded-big {
  border-radius: 6px; }

.rounded-big-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }

.rounded-big-left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.rounded-big-top {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px; }

.rounded-big-top-left {
  border-top-left-radius: 6px; }

.rounded-big-top-right {
  border-top-right-radius: 6px; }

.rounded-big-bottom {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px; }

.rounded-huge {
  border-radius: 14px; }

.rounded-huge-right {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px; }

.rounded-huge-left {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px; }

.rounded-huge-top {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px; }

.rounded-huge-top-left {
  border-top-left-radius: 14px; }

.rounded-huge-top-right {
  border-top-right-radius: 14px; }

.rounded-huge-bottom {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px; }

@media (min-width: 40rem) {
  .sm-rounded {
    border-radius: 3px; }
  .sm-rounded-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .sm-rounded-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .sm-rounded-top {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px; }
  .sm-rounded-bottom {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .sm-rounded-big {
    border-radius: 6px; }
  .sm-rounded-big-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; }
  .sm-rounded-big-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; }
  .sm-rounded-big-top {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px; }
  .sm-rounded-big-top-left {
    border-top-left-radius: 6px; }
  .sm-rounded-big-top-right {
    border-top-right-radius: 6px; }
  .sm-rounded-big-bottom {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px; }
  .sm-rounded-huge {
    border-radius: 14px; }
  .sm-rounded-huge-right {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px; }
  .sm-rounded-huge-left {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px; }
  .sm-rounded-huge-top {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px; }
  .sm-rounded-huge-top-left {
    border-top-left-radius: 14px; }
  .sm-rounded-huge-top-right {
    border-top-right-radius: 14px; }
  .sm-rounded-huge-bottom {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px; } }

@media (min-width: 52rem) {
  .md-rounded {
    border-radius: 3px; }
  .md-rounded-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .md-rounded-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .md-rounded-top {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px; }
  .md-rounded-bottom {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .md-rounded-big {
    border-radius: 6px; }
  .md-rounded-big-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; }
  .md-rounded-big-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; }
  .md-rounded-big-top {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px; }
  .md-rounded-big-top-left {
    border-top-left-radius: 6px; }
  .md-rounded-big-top-right {
    border-top-right-radius: 6px; }
  .md-rounded-big-bottom {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px; }
  .md-rounded-huge {
    border-radius: 14px; }
  .md-rounded-huge-right {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px; }
  .md-rounded-huge-left {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px; }
  .md-rounded-huge-top {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px; }
  .md-rounded-huge-top-left {
    border-top-left-radius: 14px; }
  .md-rounded-huge-top-right {
    border-top-right-radius: 14px; }
  .md-rounded-huge-bottom {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px; } }

@media (min-width: 72rem) {
  .lg-rounded {
    border-radius: 3px; }
  .lg-rounded-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .lg-rounded-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .lg-rounded-top {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px; }
  .lg-rounded-bottom {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .lg-rounded-big {
    border-radius: 6px; }
  .lg-rounded-big-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; }
  .lg-rounded-big-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; }
  .lg-rounded-big-top {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px; }
  .lg-rounded-big-top-left {
    border-top-left-radius: 6px; }
  .lg-rounded-big-top-right {
    border-top-right-radius: 6px; }
  .lg-rounded-big-bottom {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px; }
  .lg-rounded-huge {
    border-radius: 14px; }
  .lg-rounded-huge-right {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px; }
  .lg-rounded-huge-left {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px; }
  .lg-rounded-huge-top {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px; }
  .lg-rounded-huge-top-left {
    border-top-left-radius: 14px; }
  .lg-rounded-huge-top-right {
    border-top-right-radius: 14px; }
  .lg-rounded-huge-bottom {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px; } }

.max-width-1 {
  max-width: 24rem; }

.max-width-2 {
  max-width: 32rem; }

.max-width-3 {
  max-width: 48rem; }

.max-width-4 {
  max-width: 72rem; }

.sm-vh-show {
  display: none; }

@media (max-height: 600px) {
  .sm-vh-hide {
    display: none; }
  .sm-vh-show {
    display: flex; } }

@media (max-width: 39.99rem) {
  .xs-top-0 {
    top: 0; }
  .xs-left-0 {
    left: 0; }
  .xs-right-0 {
    right: 0; }
  .xs-hide {
    display: none; }
  .xs-max-width-1 {
    max-width: 24rem; }
  .xs-max-width-2 {
    max-width: 32rem; }
  .xs-max-width-3 {
    max-width: 48rem; }
  .xs-max-width-4 {
    max-width: 72rem; } }

@media (min-width: 40rem) and (max-width: 51.99rem) {
  .sm-top-0 {
    top: 0; }
  .sm-left-0 {
    left: 0; }
  .sm-right-0 {
    right: 0; }
  .sm-hide {
    display: none; }
  .sm-max-width-1 {
    max-width: 24rem; }
  .sm-max-width-2 {
    max-width: 32rem; }
  .sm-max-width-3 {
    max-width: 48rem; }
  .sm-max-width-4 {
    max-width: 72rem; } }

@media (min-width: 52rem) and (max-width: 71.99rem) {
  .md-top-0 {
    top: 0; }
  .md-left-0 {
    left: 0; }
  .md-right-0 {
    right: 0; }
  .md-hide {
    display: none; }
  .md-max-width-1 {
    max-width: 24rem; }
  .md-max-width-2 {
    max-width: 32rem; }
  .md-max-width-3 {
    max-width: 48rem; }
  .md-max-width-4 {
    max-width: 72rem; } }

@media (min-width: 72rem) {
  .lg-top-0 {
    top: 0; }
  .lg-left-0 {
    left: 0; }
  .lg-right-0 {
    right: 0; }
  .lg-hide {
    display: none; }
  .lg-max-width-1 {
    max-width: 24rem; }
  .lg-max-width-2 {
    max-width: 32rem; }
  .lg-max-width-3 {
    max-width: 48rem; }
  .lg-max-width-4 {
    max-width: 72rem; } }

@media (max-width: 39.99rem) {
  .xs-h1 {
    font-size: 4.375rem; }
  .xs-h2 {
    font-size: 3.125rem; }
  .xs-h25 {
    font-size: 2.5rem; }
  .xs-h3 {
    font-size: 1.875rem; }
  .xs-h4 {
    font-size: 1.625rem; }
  .xs-h5 {
    font-size: 1.5rem; }
  .xs-h6 {
    font-size: 1.375rem; }
  .xs-huge {
    font-size: 1.25rem; }
  .xs-large {
    font-size: 1.125rem; }
  .xs-medium {
    font-size: 1rem; }
  .xs-small {
    font-size: 0.875rem; }
  .xs-tiny {
    font-size: 0.75rem; }
  .xs-right-align {
    text-align: right; }
  .xs-center {
    text-align: center; } }

@media (min-width: 40rem) and (max-width: 51.99rem) {
  .sm-h1 {
    font-size: 4.375rem; }
  .sm-h2 {
    font-size: 3.125rem; }
  .sm-h3 {
    font-size: 1.875rem; }
  .sm-h4 {
    font-size: 1.625rem; }
  .sm-h5 {
    font-size: 1.5rem; }
  .sm-h6 {
    font-size: 1.375rem; }
  .sm-huge {
    font-size: 1.25rem; }
  .sm-large {
    font-size: 1.125rem; }
  .sm-medium {
    font-size: 1rem; }
  .sm-small {
    font-size: 0.875rem; }
  .sm-tiny {
    font-size: 0.75rem; }
  .sm-right-align {
    text-align: right; }
  .sm-center {
    text-align: center; } }

@media (min-width: 52rem) and (max-width: 71.99rem) {
  .md-h1 {
    font-size: 4.375rem; }
  .md-h2 {
    font-size: 3.125rem; }
  .md-h3 {
    font-size: 1.875rem; }
  .md-h4 {
    font-size: 1.625rem; }
  .md-h5 {
    font-size: 1.5rem; }
  .md-h6 {
    font-size: 1.375rem; }
  .md-huge {
    font-size: 1.25rem; }
  .md-large {
    font-size: 1.125rem; }
  .md-medium {
    font-size: 1rem; }
  .md-small {
    font-size: 0.875rem; }
  .md-tiny {
    font-size: 0.75rem; }
  .md-right-align {
    text-align: right; }
  .md-center {
    text-align: center; } }

@media (min-width: 72rem) {
  .lg-h1 {
    font-size: 4.375rem; }
  .lg-h2 {
    font-size: 3.125rem; }
  .lg-h3 {
    font-size: 1.875rem; }
  .lg-h4 {
    font-size: 1.625rem; }
  .lg-h5 {
    font-size: 1.5rem; }
  .lg-h6 {
    font-size: 1.375rem; }
  .lg-huge {
    font-size: 1.25rem; }
  .lg-large {
    font-size: 1.125rem; }
  .lg-medium {
    font-size: 1rem; }
  .lg-small {
    font-size: 0.875rem; }
  .lg-tiny {
    font-size: 0.75rem; }
  .lg-right-align {
    text-align: right; }
  .lg-center {
    text-align: center; } }

@media (max-width: 39.99rem) {
  .xs-flex {
    display: flex; }
  .xs-flex-inline {
    display: inline-flex; }
  .xs-flex-column {
    flex-direction: column; }
  .xs-flex-wrap {
    flex-wrap: wrap; }
  .xs-flex-auto {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0; }
  .xs-flex-none {
    flex: none; }
  .xs-flex-reverse {
    flex-direction: row-reverse; }
  .xs-flex-column-reverse {
    flex-direction: column-reverse; }
  .xs-items-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start; }
  .xs-items-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end; }
  .xs-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center; }
  .xs-items-baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline; }
  .xs-items-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch; }
  .xs-self-start {
    align-self: flex-start; }
  .xs-self-end {
    align-self: flex-end; }
  .xs-self-center {
    align-self: center; }
  .xs-self-baseline {
    align-self: baseline; }
  .xs-self-stretch {
    align-self: stretch; }
  .xs-justify-start {
    justify-content: flex-start; }
  .xs-justify-end {
    justify-content: flex-end; }
  .xs-justify-center {
    justify-content: center; }
  .xs-justify-between {
    justify-content: space-between; }
  .xs-justify-around {
    justify-content: space-around; }
  .xs-content-start {
    align-content: flex-start; }
  .xs-content-end {
    align-content: flex-end; }
  .xs-content-center {
    align-content: center; }
  .xs-content-between {
    align-content: space-between; }
  .xs-content-around {
    align-content: space-around; }
  .xs-content-stretch {
    align-content: stretch; }
  .xs-order-0 {
    order: 0; }
  .xs-order-1 {
    order: 1; }
  .xs-order-2 {
    order: 2; }
  .xs-order-3 {
    order: 3; }
  .xs-order-last {
    order: 99999; } }

@media (min-width: 40rem) and (max-width: 51.99rem) {
  .sm-flex {
    display: flex; }
  .sm-flex-inline {
    display: inline-flex; }
  .sm-flex-column {
    flex-direction: column; }
  .sm-flex-wrap {
    flex-wrap: wrap; }
  .sm-flex-auto {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0; }
  .sm-flex-none {
    flex: none; }
  .sm-flex-reverse {
    flex-direction: row-reverse; }
  .sm-flex-column-reverse {
    flex-direction: column-reverse; }
  .sm-items-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start; }
  .sm-items-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end; }
  .sm-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center; }
  .sm-items-baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline; }
  .sm-items-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch; }
  .sm-self-start {
    align-self: flex-start; }
  .sm-self-end {
    align-self: flex-end; }
  .sm-self-center {
    align-self: center; }
  .sm-self-baseline {
    align-self: baseline; }
  .sm-self-stretch {
    align-self: stretch; }
  .sm-justify-start {
    justify-content: flex-start; }
  .sm-justify-end {
    justify-content: flex-end; }
  .sm-justify-center {
    justify-content: center; }
  .sm-justify-between {
    justify-content: space-between; }
  .sm-justify-around {
    justify-content: space-around; }
  .sm-content-start {
    align-content: flex-start; }
  .sm-content-end {
    align-content: flex-end; }
  .sm-content-center {
    align-content: center; }
  .sm-content-between {
    align-content: space-between; }
  .sm-content-around {
    align-content: space-around; }
  .sm-content-stretch {
    align-content: stretch; }
  .sm-order-0 {
    order: 0; }
  .sm-order-1 {
    order: 1; }
  .sm-order-2 {
    order: 2; }
  .sm-order-3 {
    order: 3; }
  .sm-order-last {
    order: 99999; } }

@media (min-width: 52rem) and (max-width: 71.99rem) {
  .md-flex {
    display: flex; }
  .md-flex-inline {
    display: inline-flex; }
  .md-flex-column {
    flex-direction: column; }
  .md-flex-wrap {
    flex-wrap: wrap; }
  .md-flex-auto {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0; }
  .md-flex-none {
    flex: none; }
  .md-flex-reverse {
    flex-direction: row-reverse; }
  .md-flex-column-reverse {
    flex-direction: column-reverse; }
  .md-items-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start; }
  .md-items-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end; }
  .md-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center; }
  .md-items-baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline; }
  .md-items-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch; }
  .md-self-start {
    align-self: flex-start; }
  .md-self-end {
    align-self: flex-end; }
  .md-self-center {
    align-self: center; }
  .md-self-baseline {
    align-self: baseline; }
  .md-self-stretch {
    align-self: stretch; }
  .md-justify-start {
    justify-content: flex-start; }
  .md-justify-end {
    justify-content: flex-end; }
  .md-justify-center {
    justify-content: center; }
  .md-justify-between {
    justify-content: space-between; }
  .md-justify-around {
    justify-content: space-around; }
  .md-content-start {
    align-content: flex-start; }
  .md-content-end {
    align-content: flex-end; }
  .md-content-center {
    align-content: center; }
  .md-content-between {
    align-content: space-between; }
  .md-content-around {
    align-content: space-around; }
  .md-content-stretch {
    align-content: stretch; }
  .md-order-0 {
    order: 0; }
  .md-order-1 {
    order: 1; }
  .md-order-2 {
    order: 2; }
  .md-order-3 {
    order: 3; }
  .md-order-last {
    order: 99999; } }

@media (min-width: 72rem) {
  .lg-flex {
    display: flex; }
  .lg-flex-inline {
    display: inline-flex; }
  .lg-flex-column {
    flex-direction: column; }
  .lg-flex-wrap {
    flex-wrap: wrap; }
  .lg-flex-auto {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0; }
  .lg-flex-none {
    flex: none; }
  .lg-flex-reverse {
    flex-direction: row-reverse; }
  .lg-flex-column-reverse {
    flex-direction: column-reverse; }
  .lg-items-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start; }
  .lg-items-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end; }
  .lg-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center; }
  .lg-items-baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline; }
  .lg-items-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch; }
  .lg-self-start {
    align-self: flex-start; }
  .lg-self-end {
    align-self: flex-end; }
  .lg-self-center {
    align-self: center; }
  .lg-self-baseline {
    align-self: baseline; }
  .lg-self-stretch {
    align-self: stretch; }
  .lg-justify-start {
    justify-content: flex-start; }
  .lg-justify-end {
    justify-content: flex-end; }
  .lg-justify-center {
    justify-content: center; }
  .lg-justify-between {
    justify-content: space-between; }
  .lg-justify-around {
    justify-content: space-around; }
  .lg-content-start {
    align-content: flex-start; }
  .lg-content-end {
    align-content: flex-end; }
  .lg-content-center {
    align-content: center; }
  .lg-content-between {
    align-content: space-between; }
  .lg-content-around {
    align-content: space-around; }
  .lg-content-stretch {
    align-content: stretch; }
  .lg-order-0 {
    order: 0; }
  .lg-order-1 {
    order: 1; }
  .lg-order-2 {
    order: 2; }
  .lg-order-3 {
    order: 3; }
  .lg-order-last {
    order: 99999; } }

.display-none,
.hide {
  display: none; }

