// color
.color-gray900 {
  color: $gray900;
}

.color-gray800 {
  color: $gray800;
}

.color-gray700 {
  color: $gray700;
}

.color-gray600 {
  color: $gray600;
}

.color-gray300 {
  color: $gray300;
}

.color-main {
  color: $main-color;
}

// font
.fs-tiny {
  font-size: $font-size-tiny;
}

.fs-small {
  font-size: $font-size-small;
}

.fs-medium {
  font-size: $font-size-medium;
}

.fs-large {
  font-size: $font-size-large;
}

.fs-huge {
  font-size: $font-size-huge;
}

.fw-light {
  font-weight: $font-weight-light;
}

.fw-normal {
  font-weight: $font-weight-normal;
}

.fw-bold {
  font-weight: $font-weight-bold;
}

.fw-boldest {
  font-weight: $font-weight-boldest;
}

// flex
.flex {
  display: flex;
  display: -ms-flex;
}

// cursor
.pointer {
  cursor: pointer;
  user-select: none;
}

// max-width
.mw100 {
  max-width: $max-width-100;
}

.mw95 {
  max-width: $max-width-95;
}

.mw90 {
  max-width: $max-width-90;
}

.mw80 {
  max-width: $max-width-80;
}

// align
.text-center {
  text-align: center;
}

// scroll
.no-scroll {
  overflow: hidden;
}

// flex
.flex-reverse {
  flex-direction: row-reverse;
}
