
.link {
  color: $main-color;
  font-weight: $font-weight-bolder;

  &--gray {
    color: $gray600;
    border-bottom: 1px solid $gray200;

    &:hover {
      color: $main-color;
      text-decoration: none;
    }
  }
}
