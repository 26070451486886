
.input {
  height: 2.5rem;
  border-radius: 5px;
  background: $gray50;
  color: $gray600;
  font-size: 1em;
  font-family: Avenir, 'Open Sans', sans-serif;
  padding: 1px 1rem 2px;
  border: 1px solid transparent;
  box-shadow: none;
  outline: none;

  box-sizing: border-box;
  transition: all .15s ease;
  -webkit-appearance: none;

  &:focus {
    border-color: $gray300;
  }
}
