html {
  //min-height: 780px;
}

#modal-parent-div {
  position: relative;
  z-index: 101;
}

#AdditorLandingRoot {
  display: flex;
  flex-direction: column;
  //height: 100%;

  .root__header,
  .root__footer {
    font-family: Avenir, 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR',
    sans-serif;
    flex: 0 0 auto;
  }

  main {
    flex: 1 0 auto;
  }

  &.slack-page {
    .mw100 {
      max-width: 80rem;
    }
  }
}

.root__header {
  z-index: 3;

  > *,
  * {
    color: inherit;
  }

  &__container {
    display: flex;
    height: 6rem;

    justify-content: space-between;
    align-items: center;
  }

  &__namelogo {
    display: flex;
  }

  &__list {
    display: flex;
    @media (max-width: $sm-break-min) {
      display: none;
    }
  }

  &__listitem {
    height: 36px;
    display: flex;
    align-items: center;
    transition: opacity 0.15s ease;

    &__link {
      &:hover {
        text-decoration: none;
      }
      white-space: nowrap;
      font-weight: $font-weight-bolder;
    }

    &--signup {
      height: 36px;
      display: flex;
      align-items: center;
      transition: opacity 0.15s ease;
      padding: 0 15px;
      border-radius: 18px;
      background-color: $white;
    }

    &__link--signup {
      &:hover {
        text-decoration: none;
      }
      color: $Brand-500;
      white-space: nowrap;
      font-weight: $font-weight-bolder;
    }

    &__link--signin {
      &:hover {
        text-decoration: none;
      }
      white-space: nowrap;
      font-weight: $font-weight-bolder;
    }
  }

  &__listitem:last-child {
    margin-right: 0;
  }

  &__list--mobile {
    display: none;
    @media (max-width: $sm-break-min) {
      display: flex;
    }

    .root__header__listitem {
      margin-right: 10px;
      &:last-child {
        display: flex;
        align-items: center;
      }
      &__link--signup {
        display: block;
        font-weight: $font-weight-bolder;
        color: $white;
        background-color: $Brand-500;
        padding: 0 24px;
        height: 36px;
        line-height: 36px;
        border-radius: 18px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__menu-btn {
    width: 2rem;
    fill: $gray600;

    &:hover {
      fill: $gray900;
    }
  }

  &__close-btn {
    width: 2rem;
    fill: $gray600;
    transform: scale(1.2);

    &:hover {
      fill: $gray900;
    }
  }

  &--mobile {
    height: 0;
    margin: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.15s ease-in;
    z-index: 1000;

    &.active {
      min-height: 100%;
      opacity: 1;
      position: relative;
      pointer-events: auto;
    }
  }

  &--mobile__list {
    background-color: $main-color;
    height: 100%;
    margin: 0 auto;
    min-height: 100vh;
    overflow: scroll;
    width: 100%;
    flex-direction: column;
    font-size: $font-size-large;
  }

  &--mobile__listitem {
    margin-bottom: 2rem;
  }

  &--mobile__listitem__link {
    color: $white;
  }
}

// 화면사이즈가 클 경우 root__header--mobile 을 띄우지 않음
@media (min-width: $sm-break-min) {
  .root__header--mobile {
    opacity: 0 !important;
    pointer-events: none !important;
  }
}

// 작은 화면에서 로고 사이즈 줄임
@media (max-width: $sm-break-max) {
  .root__header__namelogo {
    transform: scale(0.9);
  }
}

.root__footer {
  font-family: 'Open Sans', sans-serif;
  background: $gray25;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__nav-wrap {
    display: flex;
    flex: 80%;
  }

  &__logo-wrap {
    flex: 25%;
  }

  &__logo-wrap__simbollogo {
  }

  &__nav {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    flex: 75%;
  }

  &__nav__column {
    flex: 33.33%;
    font-size: $font-size-small;
    font-weight: $font-weight-boldest;
  }

  &__nav__p {
    margin: 0 0 1.25rem 0;
    font-weight: $font-weight-bolder;
  }

  &__nav__listitem {
    color: $gray600;
    margin: 0 0 1.25rem 0;
    font-weight: 600;
  }

  &__social {
    flex: 20%;
    font-size: $font-size-small;
  }

  &__social__p {
    white-space: nowrap;
    margin: 0 0 0.625rem 0;
    font-weight: 600;
    color: $gray600;
  }

  &__social__list {
    display: flex;
    justify-content: space-between;
    width: 7rem;
    margin-top: 1.5rem;
    margin-bottom: 3.75rem;
  }

  &__social__listitem {
    height: 30px;
  }

  &__social__logo {
    width: 1.25rem;
    fill: $gray300;

    &:hover {
      fill: $gray600;
    }
  }

  &__social__copyright {
    white-space: nowrap;
    color: $gray600;

    &__link {
      color: $gray600;
      font-weight: $font-weight-normal;
    }
  }
}

@media (max-width: $sm-break-min) {
  .root__footer {
    &__nav {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.svg--right-arrow {
  vertical-align: -2px;

  path {
    fill: $main-color;
  }
}
