
.hero--product {
  &__form {
    display: inline-flex;
  }

  &__input-email {
    width: 17.813rem;
    font-weight: $font-weight-bolder;
    font-size: $font-size-small;
  }

  &__button-signup {
    width: 8.625rem;
  }
}

.s-devices {
  margin-top: 6.250rem;
}

.s-function {
  margin-top: 10rem;

  &__container {
    display: flex;
  }

  // 홀수 번째는 반대로 정렬
  &:nth-child(odd) &__container {
    flex-direction: row-reverse;
  }

  &__container__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15rem;

    &__h3 {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    &__button {
      width: 100%;

      &.button--minimal {
        font-size: $font-size-large;
        margin-bottom: .625rem;
      }

      &.button--round {
        height: 45px;
        margin-top: 1.25rem;
        font-size: $font-size-medium;
      }
    }
  }

  &__container__object {
    flex: 1;
  }

  &__container__object__content {
    width: 100%;
    height: 100%;
    background: $gray25;
    padding: 1rem;
  }
}


