html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  font-size: 1rem;
  color: $gray900;
  font-family: ObjectSans, sans-serif;
  height: 100%;
  overflow: hidden;
}

body {
  line-height: 1.4;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body.scroll-snap {
  scroll-snap-type: y mandatory;

  @media (max-width: 1200px) {
    scroll-snap-type: initial;
  }
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5em 0 0.833em;
  font-weight: bold;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 4.375rem;
}
h2,
.h2 {
  font-size: 3.125rem;
}
h3,
.h3 {
  font-size: 1.875rem;
}
h4,
.h4 {
  font-size: 1.625rem;
}
h5,
.h5 {
  font-size: 1.5rem;
}
h6,
.h6 {
  font-size: 1.375rem;
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

button {
  outline: 0;
}

p {
  margin: 1em 0;
}
small {
  font-size: $font-size-tiny;
}
em,
i {
  font-style: italic;
}
strong,
b {
  font-weight: bold;
}
strong {
  color: $main-color;
}
code,
pre {
  font-family: monospace;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:hover,
a:active {
  outline: none;
}

.svg {
  width: 1rem;
}
