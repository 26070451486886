.form__checkbox {
  > span {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: solid 1px #d6d5d8;
    display: inline-block;
    transition: .2s ease-in-out;
    transition-property: background-color, border-color;
    color: white;

    &:hover, &:focus {
      background-color: $Brand-100;
      border-color: $Brand-100;
    }
  }

  > input:focus {
    // TODO Need Design
    + span {
      box-shadow: 0 0 2px 3px $Blue-200;
    }
  }

  > input:active, input:checked {
    + span {
      background-color: $Brand-500;
      border-color: $Brand-500;
    }
  }

  > input {
    position: absolute;
    left: -10000000px;
  }
}