.hero {
  &__content {
    position: relative;
  }

  &__header__h1 {
    margin-top: 7.375rem;
    margin-bottom: 2.5rem;
    font-weight: $font-weight-boldest;
  }

  &__header__form {
    margin-top: 3.1rem;
    max-width: 27.5rem;
  }

  &__header__input-email {
    width: 17.813rem;
    font-weight: $font-weight-bolder;
    font-size: $font-size-small;
    margin-bottom: 0;
  }

  &__header__button-signup {
    width: 10.625rem;
    margin-bottom: 0;
  }

  &__header__email-invalid {
    margin: 4px 0 -31px;
    color: $Red-500-2;
    font-size: 14px;
    line-height: 27px;

    &--bottom {
      margin: 0;
    }
  }

  &__header__p {
    margin-top: 30px;
    &__sign-in {
      margin: 0 16px 0 11px;
      &:hover {
        text-decoration: underline;
      }
    }

    &__btn-oauth {
      border: none;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      background: $White;
      margin-right: 10px;

      > .icon {
        width: 23px;
        height: 23px;
      }
    }
  }

  &__header__span {
    font-size: $font-size-small;
    margin-right: 0.3rem;
  }

  &__header__a {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    text-decoration: none;
    border-bottom: 1px solid $gray200;

    &:hover {
      color: $main-color;
      border-color: $main-color;
    }
  }

  &__shape__container {
    position: absolute;
    top: 72px;
    right: -54px;
    width: 668px;

    &__img {
      width: 100%;
    }
  }
}

@media (max-width: $lg-break-max) {
  .hero {
    &__shape__container {
      position: relative;
      top: initial;
      right: initial;
      width: 80%;
      margin: -2px auto 0;
    }
  }
}

@media (max-width: $sm-break-max) {
  .hero {
    &__header__h1 {
      margin-top: 2.5rem;
      font-size: 3rem;
    }
  }

  .hero {
    &__shape__container {
      width: 100%;
    }
  }
}

.s-future {
  padding-top: 10.5rem;

  &__header-container {
    //text-align: center;
    margin: 0 auto;
  }

  &__h3 {
    margin-bottom: 1rem;
  }

  &__h6 {
    margin-top: 0;
    font-weight: $font-weight-normal;
  }

  &__logo-container {
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem auto 1rem;
    justify-content: space-evenly;
    align-items: center;
  }

  &__companies {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    opacity: 0.8;

    .sothebys {
      width: 120px;
      padding: 10px 28px;

      path {
        fill: $gray300;
      }
    }

    .newrelic {
      width: 140px;
      padding: 10px 28px;

      path {
        fill: $gray300;
      }
    }

    .atlassian {
      width: 160px;
      padding: 5px 28px 10px;

      * {
        fill: $gray300;
      }
    }

    .shopify {
      width: 100px;
      height: 30px;
      padding: 20px 28px 27px;

      path {
        fill: $gray300;
      }
    }

    .ycombinator {
      width: 148px;
      padding: 20px 28px 28px;

      * {
        fill: $gray300;
      }
    }

    .rakuten {
      width: 100px;
      padding: 20px 28px 15 ´px;

      * {
        fill: $gray300;
      }
    }
  }
}

//@media (max-width: $sm-break-max) {
//  .s-future {
//    &__companies svg {
//      transform: scale(.6);
//    }
//  }
//}

.s-stuck-in-middle {
  padding-top: 10.5rem;

  &__illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 37.5rem;
  }

  &__content {
    flex: 1;
  }

  &__header-container {
    //text-align: center;
    margin: 0 auto;
  }

  &__content__h3 {
    margin-bottom: 3rem;
  }

  &__content__p {
    font-weight: $font-weight-normal;
  }
}

.s-fastest-way {
  padding-top: 10.5rem;

  &__header-container {
    //text-align: center;
    margin: 0 auto;
  }

  &__demo-tab__list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: $font-weight-bolder;
  }

  &__demo-tab__listitem.selected {
    color: $main-color;
  }

  &__demo-viewer {
    display: none;
    height: 650px;
    margin: 0 auto;

    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__link-wrap {
    text-align: center;
  }
}

// Video 반응형 조절
@media (max-width: $lg-break-max) {
  .s-fastest-way {
    &__demo-viewer {
      height: 540px;
    }
  }
}

@media (max-width: $md-break-max) {
  .s-fastest-way {
    &__demo-viewer {
      height: 480px;
    }
  }
}

@media (max-width: $sm-break-max) {
  .s-fastest-way {
    &__demo-viewer {
      height: 240px;
    }
  }
}

.s-document {
  padding-top: 10.5rem;

  &__header-container {
    //text-align: center;
    margin: 0 auto;
  }

  &__content {
    display: flex;
  }

  &__content__container {
    //flex: 1;
  }

  &__content__textblock {
    position: relative;
    cursor: pointer;
    user-select: none;
    border-radius: 1rem;
    padding: 1rem;
    max-width: 24.5rem;
    background: $white;
    transition: all 0.15s ease;
    z-index: 2;
  }

  &__content__textblock:not(:first-child):before {
    content: '';
    position: absolute;
    top: -1px;
    width: calc(100% - 2rem);
    height: 1px;
    background: $gray100;
    opacity: 1;
  }

  &__content__textblock:after {
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -2;
    opacity: 0;
    transition: all 0.15s ease;
  }

  &__content__textblock:nth-child(1):after {
    background-image: url($url-texture-yellow);
    width: 173px;
    height: 170px;
    left: -5rem;
    top: -63px;
  }

  &__content__textblock:nth-child(2):after {
    background-image: url($url-texture-blue);
    width: 100px;
    height: 100px;
    right: -3.5rem;
    top: -50px;
  }

  &__content__textblock:nth-child(3):after {
    background-image: url($url-texture-purple);
    width: 203px;
    height: 181px;
    right: -4rem;
    bottom: -53px;
  }

  &__content__textblock:hover {
    z-index: 12;
    @include shadow(0, 0, 30px, rgba(0, 0, 0, 0.07));

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }

  // texture 이미지를 가려주기 위한 트릭
  &__content__textblock__h5:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    left: 0;
    background: $white;
    z-index: -1;
  }

  &__content__image-slider {
    max-height: 480px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__content__image {
    height: 100%;
    object-fit: contain;
  }

  &__content__image-slider__link {
    animation: fade-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}

// xs 사이즈 이하에서 texture 없앰
@media (max-width: $sm-break-max) {
  .s-document {
    &__content__textblock:before {
      content: none !important;
    }

    &__content__textblock:after {
      content: none !important;
    }
  }
}

.s-promising-team {
  padding-top: 10.5rem;

  &__header-container {
    //text-align: center;
    margin: 0 auto;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__container__wrap-card {
    text-align: center;
    font-size: $font-size-small;
    font-weight: $font-weight-bolder;
  }

  &__container__card {
    height: 6.25rem;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
  }

  & .card {
    &--mk {
      &--start {
        background-image: url($url-marketing-gif);
      }

      &--end {
        background-image: url($url-marketing-png);

        &:hover {
          background-image: url($url-marketing-gif);
        }
      }
    }

    &--pr {
      &--start {
        background-image: url($url-product-gif);
      }

      &--end {
        background-image: url($url-product-png);

        &:hover {
          background-image: url($url-product-gif);
        }
      }
    }

    &--pm {
      &--start {
        background-image: url($url-project-management-gif);
      }

      &--end {
        background-image: url($url-project-management-png);

        &:hover {
          background-image: url($url-project-management-gif);
        }
      }
    }

    &--fr {
      &--start {
        background-image: url($url-freelancer-gif);
      }

      &--end {
        background-image: url($url-freelancer-png);

        &:hover {
          background-image: url($url-freelancer-gif);
        }
      }
    }

    &--kb {
      &--start {
        background-image: url($url-knowledge-base-gif);
      }

      &--end {
        background-image: url($url-knowledge-base-png);

        &:hover {
          background-image: url($url-knowledge-base-gif);
        }
      }
    }

    &--hr {
      &--start {
        background-image: url($url-human-resource-gif);
      }

      &--end {
        background-image: url($url-human-resource-png);

        &:hover {
          background-image: url($url-human-resource-gif);
        }
      }
    }
  }
}

.s-workflows {
  padding-top: 10.5rem;

  &__header-container {
    //text-align: center;
    margin: 0 auto;
  }

  &__illustration {
    text-align: center;
    margin-bottom: 116px;

    a {
      color: $main-color;
    }

    img {
      margin-bottom: 36px;
    }

    strong {
      font-size: 22px;
      line-height: 24px;
    }

    svg {
      vertical-align: top;
      margin-left: 4px;
    }
  }
}

.s-potential {
  &__header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  &__form {
    max-width: 27.5rem;
  }

  &__input-email {
    width: 15rem;
    margin-right: 1rem;
    font-weight: $font-weight-bolder;
    font-size: $font-size-small;
  }

  &__button-signup {
    width: 10.625rem;
    vertical-align: top;
  }
}

.image-preloader {
  position: fixed;
  z-index: -1;

  .mk {
    background-image: url($url-marketing-png);
  }

  .pr {
    background-image: url($url-product-png);
  }

  .pm {
    background-image: url($url-knowledge-base-png);
  }

  .fr {
    background-image: url($url-freelancer-png);
  }

  .kb {
    background-image: url($url-knowledge-base-png);
  }

  .hr {
    background-image: url($url-human-resource-png);
  }
}
