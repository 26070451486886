
.s-pricing {

  &__container__card-wrap {
    display: flex;
    justify-content: center;
  }

  &__container__card {
    display: flex;
    justify-content: center;
    width: 17.5rem;
    height: auto;
    margin-right: 3.125rem;
    border: 1px solid $gray100;
    @include pointer();
  }

  &__container__card:hover {
    border-color: transparent;
  }

  &__container__card:last-child {
    margin-right: 0;
  }

  &__container__card__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
  }

  &__container__card__inner__header-wrap {
    margin-bottom: 2rem;
  }

  &__container__card__inner__desc-wrap {

  }

  &__container__card__inner__p {

    &--price {
      margin-top: .75rem;
      margin-bottom: 0;
    }

    &--desc {
      margin: 0;
      line-height: 1.56;
    }
  }

  &__container__card__inner__small {
    display: block;
    margin-top: -1px;
    color: $gray300;
  }

  &__container__card__inner__button-wrap {

  }

  &__container__card:hover &__container__card__inner__button {
    background: $main-color;
    @include shadow(0, 1px, 7px, rgba(117, 82, 246, 0.6));
  }

  &__container__card__inner__button {
    width: 10rem;
    height: 1.875rem;
    margin-top: 5rem;
    margin-bottom: 2.5rem;
    font-size: $font-size-small;
  }

  &__form {
    max-width: 27.5rem;
  }
}

.s-pricing-features {
  background: $main-color;
  height: 10rem;
}
