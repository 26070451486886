@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700);

@font-face {
  font-family: ObjectSans;
  src: url('https://assets.additor.io/fonts/ObjectSans-Regular.eot?#iefix') format('embedded-opentype'),
  url(https://assets.additor.io/fonts/ObjectSans-Regular.woff) format('woff'),
  url(https://assets.additor.io/fonts/ObjectSans-Regular.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: ObjectSans;
  src: url('https://assets.additor.io/fonts/ObjectSans-Medium.eot?#iefix') format('embedded-opentype'),
  url(https://assets.additor.io/fonts/ObjectSans-Medium.woff) format('woff'),
  url(https://assets.additor.io/fonts/ObjectSans-Medium.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: ObjectSans;
  src: url('https://assets.additor.io/fonts/ObjectSans-Heavy.eot?#iefix') format('embedded-opentype'),
  url(https://assets.additor.io/fonts/ObjectSans-Heavy.woff) format('woff'),
  url(https://assets.additor.io/fonts/ObjectSans-Heavy.woff2) format('woff2');
  font-weight: 900;
}

