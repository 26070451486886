.crx-sign-in {
  width: 100%;
  max-width: 335px;
  padding: 24px;
  font-family: 'system-ui', 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR',
    sans-serif;

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: $gray900;
  }

  &__main-img {
    width: 100%;
    margin-bottom: 47px;
  }

  &__google-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    color: $white;
    font-size: 13px;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 3px;
    background-color: $Brand-500;
    cursor: pointer;
    margin-bottom: 16px;
    &:hover {
      background-color: $Brand-700;
    }

    svg {
      margin-right: 3px;
    }
  }

  &__slack-btn, &__apple-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    color: $Gray-500;
    font-size: 13px;
    font-weight: 500;
    outline: none;
    border-radius: 3px;
    background-color: $White;
    border: 1px solid $Gray-500;
    cursor: pointer;
    margin-bottom: 16px;
    &:hover {
      border-color: $Gray-700;
      color: $Gray-700;
    }

    svg {
      margin-right: 3px;
    }
  }

  &__email-guide {
    font-size: 13px;
    color: $gray700;
    margin-bottom: 38px;
    text-align: center;

    a {
      color: $Brand-500;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__footer-guide {
    font-size: 13px;
    text-align: center;
    color: $gray500;
  }

  &__email-label {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    color: $gray700;
  }

  &__email-input {
    display: block;
    width: 100%;
    height: 36px;
    border-radius: 3px;
    background-color: $gray80;
    padding: 6px 10px;
    color: $gray900;
    box-sizing: border-box;
    outline: none;
    border: none;
  }

  &__error-msg {
    font-size: 12px;
    color: $Red-500-2;
    margin: 0;
  }

  &__submit-btn {
    display: block;
    width: 100%;
    height: 36px;
    border: 1px solid $Brand-500;
    background-color: $white;
    color: $Brand-500;
    border-radius: 3px;
    margin: 12px 0 38px;
    cursor: pointer;

    &:hover {
      background-color: $Brand-500;
      color: $white;
    }
  }
}

.email-sent {
  width: 335px;
  padding: 24px;
  color: $gray900;

  &__back-btn {
    margin-bottom: 15px;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 12px;
  }

  &__about {
    margin: 0 0 16px;
    font-size: 14px;
  }

  &__resend-btn {
    display: block;
    color: $Brand-500;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 0 5px;
  }

  &__video {
    width: 100%;
  }

  &__timer {
    color: $gray500;
    font-size: 12px;
    margin: 0 0 20px;
  }
}
