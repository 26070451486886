.sign-in-page {
  height: calc(100vh - 96px);
  font-family: Avenir, 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR',
  sans-serif;
  min-height: 680px;
  position: relative;

  * {
    font-family: Avenir, 'Open Sans', sans-serif;
  }

  &__panel-wrap {
    position: absolute;
    width: 560px;
    top: calc(50% - 48px);
    left: 50%;
    transform: translate(-50%, -50%);

    .bg-texture {
      position: absolute;
      background-size: cover;
      z-index: 0;

      &--triangle {
        top: -48px;
        right: -50px;
        width: 222px;
        height: 220px;
      }

      &--circle {
        bottom: -34px;
        left: -91px;
        width: 233px;
        height: 267px;
      }

      &--square {
        bottom: -40px;
        left: -40px;
        width: 209px;
        height: 249px;
      }
    }

    .bg-girl {
      position: absolute;
      background-size: cover;
      z-index: 1;

      &--body {
        width: 228px;
        height: 166px;
        top: 12px;
        right: -146px;
      }

      &--right-hand {
        z-index: 5;
        width: 76px;
        height: 49px;
        top: -28px;
        right: 22px;
      }

      &--left-hand {
        z-index: 5;
        width: 52px;
        height: 79px;
        top: 152px;
        right: -29px;
      }
    }
  }

  &__panel {
    position: relative;
    z-index: 3;

    background: $white;
    box-sizing: border-box;
    border-radius: 10px;
    color: #222426;
    min-height: 320px;
  }
}

.sign-in-page {
  &__footer {
    text-align: center;
    margin: 30px 0 0;
    line-height: 22px;
    font-size: 16px;
    color: $Brand-80;
    position: absolute;
    width: 100%;
  }

  &__footer__p {
    margin: 10px 0;

    &--create-ws {
      padding-left: 16px;
    }
  }

  &__footer__a {
    margin-left: 8px;
    color: $white;
    font-weight: bold;

    svg {
      margin-left: 4px;
    }
  }
}

.sign-in-page__title {
  margin: 0;
  line-height: 40px;
  font-size: 32px;
  font-weight: 900;
  color: $Gray-900;
  position: relative;
  text-align: center;

  > span {
    position: relative;
    z-index: 1;
  }

  &__deco {
    width: 134px;
    position: absolute;
    left: 116px;
    top: 6px;
    z-index: -1;
  }
}

.sign-in-page__panel {
  &__form-group {
    margin-bottom: 24px;
  }

  &__form-control-wrap {
    display: flex;
    position: relative;
    align-items: center;

    > span {
      font-size: 20px;
      font-weight: 600;
      color: $Gray-900;
    }

    > .valid-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      color: $Brand-80;

      &--valid {
        color: $Brand-500;
      }

      &--valid {
        color: #ffe8e9;
      }
    }
  }

  &__label {
    line-height: 18px;
    font-size: 13px;
    font-weight: normal;
    margin: 0 0 8px;
  }

  &__form-control.form__control {
    flex: 1;
    font-size: 16px;

    & + span {
      margin-left: 12px;
    }
  }

  &__btn-submit {
    color: $White;
    background-color: $Brand-500;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    height: 45px;
    cursor: pointer;
    border-radius: 3px;
    border: none;

    &:hover {
      background-color: $Brand-700;
    }
  }
}

.sign-in-page__panel--workspace {
  padding: 45px 80px;

  &__form {
    margin-top: 40px;
  }

  &__form-control {
    text-align: right;
  }

  &__create {
    text-align: center;
    line-height: 22px;
    font-size: 16px;
    color: $gray800;
    margin: 47px 0 0;

    &__link {
      font-weight: 600;
      color: $Brand-500;
    }
  }
}

.sign-in-page__panel--signin {
  padding: 45px 80px 45px;

  &__title {
    margin: 0 -80px;
  }

  &__url {
    line-height: 23px;
    font-size: 17px;
    text-align: center;
    color: #1065a3;
    margin: 4px 0 0;
  }

  &__form {
    margin-top: 40px;
  }

  &__form-group {
    margin-bottom: 10px;

    > .form__group--error__wrap {
      margin-bottom: unset;
    }

    //&--email {
    //}
    //&--pw {
    //}
  }

  &__error {
    margin-top: -2px;
    margin-bottom: 10px;
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
    color: $Red-500-2;
  }

  &__or {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d9d9d9;
    margin: 20px 0;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 1px;
      flex: 1;
      background: #d9d9d9;
    }

    &:before {
      margin-right: 15px;
    }

    &:after {
      margin-left: 15px;
    }
  }

  &__btn-submit {
    margin-top: 18px;
  }

  &__btn-google,
  &__btn-slack,
  &__btn-apple {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $White;
    border: solid 1px #d9d9d9;
    border-radius: 3px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: $Gray-800;
    font-weight: 500;
    width: 100%;
    height: 45px;
    padding: 0;
    margin-bottom: 12px;
    cursor: pointer;

    > .icon {
      width: 20px;
      height: 20px;
      position: relative;
      display: inline-block;
      margin-right: 12px;
      vertical-align: bottom;

      > img {
        width: 100%;
      }
    }

    &:hover {
      background-color: $Gray-50;
    }
  }

  &__error-google,
  &__error-slack {
    color: $Red-500;
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 8px 0;
  }

  &__forgot {
    display: flex;
    justify-content: center;
    line-height: 22px;
    font-size: 14px;
    font-weight: 500;
    margin: 22px 0 0 0;

    a {
      color: $Gray-600;
      white-space: nowrap;
    }

    a:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &__dot {
      position: relative;
      margin: 0 14px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: $Gray-600;
      }
    }
  }
}

.sign-in-page__panel--find {
  padding: 45px 75px 50px;

  .form__group--error__wrap {
    margin-bottom: unset;
  }

  .sign-in-page__title__deco {
    width: 134px;
    height: 42px;
    left: -10px;
    top: 5px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    margin: 0 0 33px;
    font-size: 15px;
    color: $gray600;
    text-align: center;
  }

  &__form-label {
    font-size: 13px;
    margin: 0 0 8px 0;
    font-weight: 400;
  }

  &__bnt-back {
    display: flex;
    border: none;
    position: absolute;
    padding: 4px;
    top: 20px;
    left: 22px;
    background-color: transparent;
    cursor: pointer;
    outline: 0;
    border-radius: 5px;

    &:hover {
      background: $Gray-80;

      svg g {
        stroke: $Gray-700;
      }
    }
  }

  &__banner {
    width: 302px;
    margin: 0 auto;

    > img {
      width: 100%;
    }
  }
}

.sign-in-page__panel--find-password {
  padding: 60px 80px 75px;

  .sign-in-page__title__deco {
    width: 110px;
    height: 40px;
    left: -14px;
    top: 4px;
  }

  &__form {
    margin-top: 40px;
  }

  &__create {
    text-align: center;
    line-height: 22px;
    font-size: 16px;
    color: $gray800;
    margin: 47px 0 0;

    &__link {
      font-weight: 600;
      color: $Brand-500;
    }
  }

  &__btn-submit {
    margin-top: 13px;
  }

  &__sent {
    text-align: center;
    p {
      margin-bottom: 22px;
    }
    img {
      width: 149px;
      margin-bottom: -20px;
    }
  }
}

@media (max-width: $md-break-max) {
  .sign-in-page__panel-wrap {
    .bg-girl {
      display: none;
    }
  }
}

@media (max-width: $sm-break-max) {
  .sign-in-page__panel-wrap {
    width: 100%;
    position: relative;
    top: initial;
    left: initial;
    transform: none;
    padding-top: 40px;

    .bg-texture,
    .bg-girl {
      display: none;
    }
  }

  .sign-in-page__panel--workspace {
    padding: 10px 30px;
  }

  .sign-in-page__panel--signin {
    padding: 10px 30px;
  }

  .sign-in-page__panel--find {
    padding: 10px 30px;

    .sign-in-page__panel__form-group {
      margin-bottom: 40px;
    }

    &__banner {
      margin-top: 36px;
    }

    &__bnt-back {
      top: -34px;
    }

    .sign-in-page__footer {
      margin-top: 25px;
    }
  }

  .sign-in-page__panel--find-password {
    padding: 10px 30px;
    margin-bottom: 150px;

    &__form {
      margin-top: 87px;
    }

    &__sent p {
      margin-bottom: 105px;
    }

    &__sent img {
      width: 209px;
    }
  }

  .sign-in-page__footer {
    margin: 80px 0;
    color: $gray900;

    &__p {
      margin: 0 0 15px;

      &--create-ws {
        padding-left: 0;
      }
    }

    &__a {
      display: block;
      color: $Brand-500;
      margin: 0;
    }
  }
}

@media (max-width: 385px) {
  .sign-in-page__panel--signin__forgot {
    font-size: 11px;
  }
}
