/**
 * colors
 */
$main-color: #7552f6;
$main-color50: #b1a0f6;
$white: #fff;

$gray900: #2e4253;
$gray800: #324251;
$gray700: #595959;
$gray600: #6c7883;
$gray300: #939ca5;
$gray200: #d8d8d8;
$gray100: #eaeaea;
$gray50: #efefef;
$gray25: #f7f7f7;

/**
 * fonts
 */
$font-size-h1: 4.375rem;
$font-size-h2: 3.125rem;
$font-size-h25: 2.5rem;
$font-size-h3: 1.875rem;
$font-size-h4: 1.625rem;
$font-size-h5: 1.5rem;
$font-size-h6: 1.375rem;

$font-size-tiny: 0.75rem;
$font-size-small: 0.875rem;
$font-size-medium: 1rem;
$font-size-large: 1.125rem;
$font-size-huge: 1.25rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$font-weight-boldest: 900;

/**
 * maxWidths
 */
$max-width-100: 72rem;
$max-width-95: 70rem;
$max-width-90: 68rem;
$max-width-80: 60rem;

/**
 * break point
 */
$xs-break-max: 26.5rem;
$sm-break-max: 39.99rem;
$sm-break-min: 40rem;
$md-break-max: 51.99rem;
$md-break-min: 52rem;
$lg-break-max: 71.99rem;
$lg-break-min: 72rem;

$screen-1280: 80rem;
$screen-1440: 90rem;
$screen-1680: 105rem;

/**
 * z-index
 */

/**
 * images
 */
$url-image: 'https://s3.ap-northeast-2.amazonaws.com/resource-additor.io/image';
$url-texture-yellow: $url-image + '/illustration/hover-texture-yellow.png';
$url-texture-blue: $url-image + '/illustration/hover-texture-blue.png';
$url-texture-purple: $url-image + '/illustration/hover-texture-purple.png';

$url-marketing-gif: $url-image + '/gif/marketing.gif';
$url-marketing-png: $url-image + '/gif/marketing.png';
$url-product-gif: $url-image + '/gif/product.gif';
$url-product-png: $url-image + '/gif/product.png';
$url-project-management-gif: $url-image + '/gif/project_management.gif';
$url-project-management-png: $url-image + '/gif/project_management.png';
$url-freelancer-gif: $url-image + '/gif/freelancer.gif';
$url-freelancer-png: $url-image + '/gif/freelancer.png';
$url-knowledge-base-gif: $url-image + '/gif/knowledge_base.gif';
$url-knowledge-base-png: $url-image + '/gif/knowledge_base.png';
$url-human-resource-gif: $url-image + '/gif/human_resource.gif';
$url-human-resource-png: $url-image + '/gif/human_resource.png';
