#slack {
  overflow: hidden;
  position: relative;

  h2 {
    font-size: 32px;
    font-weight: 900;
    margin: 0 0 10px 0;
  }

  .section {
    margin: 0 auto;

    &.s-get-noti {
      margin: 0 auto 360px;
    }
  }

  .divider-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    position: relative;
    padding: 0 8px;
    margin: 180px auto;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #606367;
  }

  .divider-dot {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 64px;
    height: 64px;
    background-color: $white;

    &:before {
      content: '';
      width: 3px;
      height: 3px;
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 1px);
      background-color: #606367;
      border-radius: 50%;
    }
  }
}

@media (max-width: $screen-1280) {
  #slack .divider-wrapper {
    padding: 0 50px;
  }
}

@media (max-width: $md-break-max) {
  #slack .divider-wrapper {
    margin: 90px auto;
  }
}

@media (max-width: $sm-break-max) {
  #slack {
    .divider-wrapper {
      margin: 60px auto;
    }

    h2 {
      font-size: 24px;
    }

    .section.s-get-noti {
      margin: 0 auto 180px;
    }
  }
}

.hero--slack {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 150px;
  position: relative;

  .hero__h1 {
    font-size: 52px;
    font-weight: 900;
    line-height: 1.35;
    margin: 0 0 20px 0;
  }

  .hero__main-phrases-wrapper {
    width: 610px;
    height: 560px;
    position: relative;
    z-index: 3;
    padding: 70px 60px;
    margin-right: 92px;
    border-radius: 5px;
    box-shadow: 3px 3px 0 0 #000000;
    border: solid 2px #222426;
    background-color: $white;
  }

  .hero__img-wrapper {
    width: 450px;
  }

  .hero__description {
    font-size: 22px;
    line-height: 1.5;
    margin: 0 0 40px 0;
  }

  a:hover {
    text-decoration: none;
  }

  .hero__slack-button {
    display: flex;
    width: 253px;
    justify-content: center;
    align-items: center;
    height: 56px;
    font-size: 20px;
    font-weight: 900;
    border-radius: 3px;
    color: $white;
    box-shadow: 3px 3px 0 0 #4929cc;
    background-color: $main-color;
    cursor: pointer;
    border: none;
    outline: none;
    font-family: Avenir, 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR',
      sans-serif;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    &:hover {
      background-color: #623ff0;
    }
  }
}

.hero__block-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__block1 {
  width: 400px;
  position: absolute;
  top: 658px;
  left: calc(((100% - 1680px) / 2) + 140px);
}

@media (max-width: $screen-1680) {
  .hero__block1 {
    left: 60px;
  }

  .hero--slack {
    max-width: calc(1280px - (1680px - 100%));
  }
}

@media (max-width: $screen-1440) {
  .hero--slack .hero__main-phrases-wrapper {
    padding: 44px;
  }

  .hero--slack {
    max-width: calc(100% - 188px);
  }
}

@media (max-width: $screen-1280) {
  .hero--slack .hero__main-phrases-wrapper {
    padding: 44px;
  }
}

@media (max-width: 1140px) {
  .hero--slack {
    max-width: calc(100% - 64px);
  }

  .hero--slack {
    .hero__main-phrases-wrapper {
      min-width: 426px;
      height: 460px;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

    .hero__h1 {
      font-size: 42px;
    }

    .hero__description {
      font-size: 22px;
    }

    .hero__img-wrapper {
      display: none;
    }
  }

  .hero__block1 {
    left: 0;
    top: 560px;
  }
}

@media (max-width: $sm-break-max) {
  .hero--slack {
    max-width: calc(100% - 32px);
    margin-bottom: 82px;
  }

  .hero--slack {
    .hero__main-phrases-wrapper {
      height: 468px;
      min-width: initial;
    }

    .hero__h1 {
      font-size: 32px;
    }

    .hero__description {
      font-size: 20px;
      margin: 0 0 30px 0;
    }
  }

  .hero__block1 {
    width: 335px;
    top: 582px;
  }
}

@media (max-width: $xs-break-max) {
  .hero--slack {
    .hero__main-phrases-wrapper {
      padding: 30px;
    }

    .hero__slack-button {
      width: 237px;
      margin-left: -10px;
    }
  }
}

@media (max-width: 360px) {
  .hero--slack {
    .hero__main-phrases-wrapper {
      height: 468px;
      min-width: initial;
    }

    .hero__h1 {
      font-size: 28px;
    }

    .hero__description {
      font-size: 18px;
      margin: 0 0 30px 0;
    }
  }
}

.s-grab-idea {
  &__description {
    font-size: 22px;
    margin: 0 0 80px 0;
  }

  &__video-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 680px;
    position: relative;
    padding: 0 2px;
    margin-left: -8px;
    border-radius: 5px;
    box-shadow: 3px -3px 0 0 #222426;
    border: solid 2px #000000;
    background-color: #ffffff;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: -20px;
      right: -20px;
      border-radius: 5px;
      border: solid 2px #000000;
      background-color: #dad1ff;
      z-index: -1;
    }

    video {
      width: 100%;
    }
  }
}

@media (max-width: $screen-1280) {
  .s-grab-idea__video-wrapper {
    padding: 10px 2px;
    height: auto;
  }
}

@media (max-width: $sm-break-max) {
  .s-grab-idea__description {
    font-size: 15px;
    margin: 0 0 40px 0;
  }
  .s-grab-idea__video-wrapper:before {
    top: -10px;
    right: -10px;
  }
}

.s-message-to-document {
  position: relative;

  &__description {
    font-size: 22px;
    margin: 0 0 80px 0;
  }

  &__img-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }
  }

  &__block1 {
    position: absolute;
    right: -201px;
    bottom: -97px;
  }
}

@media (max-width: $screen-1680) {
  .s-message-to-document {
    &__block1 {
      //display: none;
    }
  }
}

@media (max-width: $sm-break-max) {
  .s-message-to-document__description {
    font-size: 15px;
    margin: 0 0 40px 0;
  }
}

.s-note-command {
  position: relative;

  &__description {
    font-size: 22px;
    margin: 0 0 80px 0;
  }

  &__img-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }
  }

  &__block1 {
    position: absolute;
    left: -199px;
    bottom: -176px;
  }
}

@media (max-width: $screen-1680) {
  .s-note-command {
    &__block1 {
      //display: none;
    }
  }
}

@media (max-width: $sm-break-max) {
  .s-note-command__description {
    font-size: 15px;
    margin: 0 0 40px 0;
  }
}

.s-launch-page {
  &__description {
    font-size: 22px;
    margin: 0 0 80px 0;
  }

  &__img-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }
  }
}

@media (max-width: $sm-break-max) {
  .s-launch-page__description {
    font-size: 15px;
    margin: 0 0 40px 0;
  }
}

.s-get-noti {
  display: flex;
  justify-content: space-between;
  position: relative;

  &__description {
    font-size: 22px;
  }

  &__phrase-wrapper {
    max-width: 480px;
    min-width: 320px;
    margin-right: 50px;
  }

  &__img-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 670px;
    position: relative;

    img {
      width: 100%;
    }
  }

  &__block1 {
    position: absolute;
    top: 301px;
    left: 80px;
  }
}

@media (max-width: $screen-1280) {
  .s-get-noti__block1 {
    display: none;
  }
}

@media (max-width: 1024px) {
  .s-get-noti {
    display: block;
    text-align: center;
    &__phrase-wrapper {
      width: 100%;
      max-width: initial;
    }

    &__description {
      margin-bottom: 30px;
    }

    &__img-wrapper {
      max-width: initial;
    }
  }
}

@media (max-width: $sm-break-max) {
  .s-get-noti__description {
    font-size: 15px;
    margin: 0 0 40px 0;
  }
}

.slack__footer {
  width: 100%;
  height: 450px;
  background-color: #493185;
  padding: 130px 40px 0;

  &__navi-wrapper {
    width: 100%;
    color: $white;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 200px 200px 200px 200px 200px;
    grid-template-rows: auto;
  }

  &__navi--logo {
    svg path {
      fill: $white;
    }
  }

  &__navi--product {
    p {
      margin: 0 0 20px 0;
      font-size: 18px;
      font-weight: 900;
    }

    a {
      display: block;
      height: 24px;
      line-height: 24px;
      margin: 0 0 20px 0;
      font-size: 17px;
      font-weight: 500;
      color: $white;
    }

    a[disabled] {
      pointer-events: none;

      &:after {
        content: 'Coming Soon';
        display: inline-block;
        padding: 0 4px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-left: 24px;
        border-radius: 3px;
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }

  &__navi--social {
    p {
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 20px 0;
      text-align: right;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    .logo--linkedin {
      width: 20px;
    }

    .logo--twitter {
      padding-top: 3px;
      width: 20px;
    }

    .logo--facebook {
      width: 11px;
    }

    .logo--producthunt {
      width: 20px;
    }
  }

  &__social-menu {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: $screen-1280) {
  .slack__footer__navi-wrapper {
    grid-template-columns: 200px 200px 0 0 200px;
  }
}

@media (max-width: $md-break-max) {
  .slack__footer {
    padding-top: 50px;

    &__navi-wrapper {
      grid-template-columns: 100%;
      grid-template-rows: 70px auto 0 0 auto;
    }

    &__navi--social {
      p {
        margin-top: 10px;
        text-align: initial;
      }
    }

    &__social-menu {
      justify-content: initial;

      a:first-child {
        margin-left: 0;
      }
    }
  }
}
