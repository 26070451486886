
// padding
.p0 {
  padding: 0;
}

.p1 {
  padding: .5rem;
}

.p2 {
  padding: 1rem;
}

.p3 {
  padding: 2rem;
}

.p4 {
  padding: 4rem;
}

.p5 {
  padding: 8rem;
}

// padding-top
.pt0 {
  padding-top: 0;
}

.pt1 {
  padding-top: .5rem;
}

.pt2 {
  padding-top: 1rem;
}

.pt3 {
  padding-top: 2rem;
}

.pt4 {
  padding-top: 4rem;
}

.pt5 {
  padding-top: 8rem;
}

// padding-bottom
.pr0 {
  padding-right: 0;
}

.pr1 {
  padding-right: .5rem;
}

.pr2 {
  padding-right: 1rem;
}

.pr3 {
  padding-right: 2rem;
}

.pr4 {
  padding-right: 4rem;
}

.pr5 {
  padding-right: 8rem;
}

// padding-bottom
.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: .5rem;
}

.pb2 {
  padding-bottom: 1rem;
}

.pb3 {
  padding-bottom: 2rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pb5 {
  padding-bottom: 8rem;
}

// padding-left
.pl0 {
  padding-left: 0;
}

.pl1 {
  padding-left: .5rem;
}

.pl2 {
  padding-left: 1rem;
}

.pl3 {
  padding-left: 2rem;
}

.pl4 {
  padding-left: 4rem;
}

.pl5 {
  padding-left: 8rem;
}

// padding-left, padding-right
.px0 {
  padding-left: 0;
  padding-right: 0;
}

.px1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px3 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px4 {
  padding-left: 8rem;
  padding-right: 8rem;
}

// padding-top, padding-bottom
.py0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py5 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
