.sign-up-page {
  height: 100vh;
  min-height: 900px;
  display: flex;
  justify-content: center;
  font-family: Avenir, 'Open Sans', 'Apple SD Gothic Neo', 'Noto Sans KR',
  sans-serif;

  &__namelogo-wrap {
    width: 80%;
    position: absolute;
    top: 28px;
    max-width: 400px;
  }

  &__namelogo {
    display: flex;
  }

  &__menu-wrap {
    width: 80%;
    position: absolute;
    top: 30px;
    max-width: 890px;
  }

  &__list {
    position: absolute;
    right: 0;
    @media (max-width: 940px) {
      display: none;
    }
  }
  &__list--mobile {
    position: absolute;
    right: 0;
    display: none;
    @media (max-width: 940px) {
      display: flex;
    }
    .sign-up-page__listitem:last-child {
      margin-right: 10px;
    };
    .sign-up-page__link--signin {
      display: block;
      margin-right: 0;
      font-weight: $font-weight-bolder;
      color: $white;
      background-color: $Brand-500;
      padding: 0 24px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      &:hover {
        opacity: 1;
        background-color: $Brand-700;
        text-decoration: none;
      }
    }
  }

  &__listitem {
    height: 38px;
    display: flex;
    align-items: center;
  }

  &__link, &__link--signin, &__link--signup {
    font-weight: $font-weight-bolder;
    padding: 0 40px 0 0;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 18px;
    color: $Blue-Gray-900;
    transition: opacity .15s ease-in-out;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
    }
  }

  &__link--signin {
    color: $Brand-500;
  }

  &__link--signup {
    background-color: $Brand-500;
    color: $white;
    padding: 0 20px;
  }

  &__title {
    position: relative;
    font-size: 32px;
    font-weight: 900;
    color: $Gray-900;
    margin: 0;
    z-index: 3;
  }

  &__desc {
    font-size: 18px;
  }

  &__title__deco {
    width: 134px;
    position: absolute;
    left: -2px;
    top: 5px;
    z-index: -1;
  }

  &__panel-wrap,
  &__main-image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__panel-wrap {
    position: relative;
    flex: 4;
  }

  &__main-image-wrap {
    flex: 6;
    position: relative;
    z-index: 1;
    img, video {
      width: 80%;
      max-width: 890px;
    }
  }

  &__main-image-wrap-bg {
    position: absolute;
    min-height: 900px;
    left: 40%;
    width: 60%;
    height: 100%;
    background: $Gray-80;
  }

  &__sign-in-wrapper {
    margin-top: 50px;
    font-size: 14px;
    color: $Gray-600;
  }

  &__sign-in-link {
    color: $Brand-500;
    font-weight: 600;
    margin-left: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__google-sign-in-btn {
    border: none;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 12px;
    background: $White;

    > .icon {
      width: 14px;
      height: 14px;
    }
  }
}

.sign-up-page__panel-wrap {
  .sign-up-page__menu-wrap {
    display: none;
    max-width: 400px;
  }
}

.sign-up-page__panel {
  max-width: 400px;
  width: 80%;
  &__form-group {
    margin-bottom: 25px;
  }

  &__form-group.form__group--error {
    margin-bottom: 2px;
  }

  &__form-control-wrap {
    display: flex;
    position: relative;
    align-items: center;

    > span {
      font-size: 20px;
      font-weight: 900;
      color: $Gray-900;
    }

    > .valid-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      color: $Brand-80;

      &--valid {
        color: $Brand-500;
      }

      &--valid {
        color: #ffe8e9;
      }
    }
  }

  &__label {
    line-height: 18px;
    font-size: 13px;
    font-weight: normal;
    margin: 0 0 8px;
  }

  &__form-control {
    flex: 1;
    font-size: 18px;

    & + span {
      margin-left: 12px;
    }
  }

  &__btn-submit {
    color: $White;
    background-color: $Brand-500;
    padding: 0;
    font-size: 16px;
    width: 100%;
    height: 45px;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    font-family: Avenir, 'Open Sans', sans-serif;
    font-weight: 600;

    &:hover {
      background-color: $Brand-700;
    }
  }
}

.sign-up-page__panel--signup {
  &__title {
    margin: 0 -80px;
  }

  &__url {
    line-height: 23px;
    font-size: 17px;
    text-align: center;
    color: #1065a3;
    margin: 2px 0 0;
  }

  &__form {
    margin-top: 40px;
  }

  &__form-group {
    > .form__group--error__wrap {
      margin-bottom: unset;

      .form__error {
        margin-top: 5px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__error {
    margin-top: -2px;
    margin-bottom: 10px;
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
    color: $Red-500-2;
  }

  &__or {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d9d9d9;
    margin: 20px 0;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 1px;
      flex: 1;
      background: #d9d9d9;
    }

    &:before {
      margin-right: 15px;
    }

    &:after {
      margin-left: 15px;
    }
  }

  &__btn-submit {
    margin-top: 13px;
  }

  &__btn-google, &__btn-slack, &__btn-apple {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $White;
    border: solid 1px #d9d9d9;
    border-radius: 3px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: $Gray-800;
    width: 100%;
    height: 45px;
    padding: 0;
    margin-bottom: 16px;
    cursor: pointer;
    font-family: Avenir, 'Open Sans', sans-serif;
    &:last-of-type {
      margin-bottom: 0;
    }

    > .icon {
      width: 20px;
      height: 20px;
      position: relative;
      display: inline-block;
      margin-right: 8px;
      vertical-align: bottom;

      > img {
        width: 100%;
      }
    }

    &:hover {
      background-color: $Gray-50;
    }
  }

  &__error-google {
    color: $Red-500;
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
    height: 18px;
    margin: 8px 0 0;
  }

  &__forgot {
    line-height: 22px;
    font-size: 16px;
    font-weight: 900;
    color: $Brand-500;
    margin-top: 40px;
    text-align: center;
  }
}

@media (max-width: $lg-break-min) {
  .sign-up-page {
    &__main-image-wrap-bg {
      left: 40%;
      width: 60%;
    }
  }
}

@media (max-width: 940px) {
  .sign-up-page {
    &__main-image-wrap {
      display: none;
    }

    &__main-image-wrap-bg {
      display: none;
    }
  }

  .sign-up-page__panel-wrap {
    .sign-up-page__menu-wrap {
      display: block;
    }
  }
}

@media (max-width: $xs-break-max) {
  .sign-up-page__panel {
    width: calc(100% - 60px);
  }

  .sign-up-page__panel-wrap {
    padding-top: 120px;
    align-items: initial;
  }
}
