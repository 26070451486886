input.form__control {
  height: 45px;
  min-width: 120px;
  object-fit: contain;
  border-radius: 3px;
  border: solid 1px $Gray-200;
  background-color: $White;
  box-sizing: border-box;
  padding: 10px 15px;
  font-size: 18px;
  caret-color: $Brand-500;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  transition-timing-function: ease;
  -webkit-appearance: none; // iOS safari 에서 내부 shadow 를 지우기 위함
  background-clip: padding-box; // iOS safari 에서 내부 shadow 를 지우기 위함

  &::placeholder {
    color: $Gray-400;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $Gray-400;
  }

  &::-ms-input-placeholder {
    color: $Gray-400;
  }

  &:active, &:focus {
    border-color: $Brand-500;
    outline: none;
  }
  &--error {
    border-color: $Red-400;
    outline: none;
    background: #fff8f9;
    &:active, &:focus {
      border-color: $Red-400;
    }
  }
}
