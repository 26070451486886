$screen-xs-min: 500px;
$screen-xxs-max: ($screen-xs-min - 1); // mobile
$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1); // tablet
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

div.modal-dialog.fs-modal.fs-privacy {
  padding: 0 60px;
  .main-wrapper {
    .content {
      width: 100%;
      padding-bottom: 60px;
      text-align: left;
    }

    .content h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .content p {
      margin: 10px 0;
    }

    .content a {
      color: $main-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Team login
div.modal-dialog.fs-modal {
  width: 100%;

  .header-line {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 5px;

    background-image: linear-gradient(90deg, #925aff, #684ef2);
  }

  &.fs-tab {
    // 탭이 있는 모달에서는 fixed 해제
    position: relative;
  }

  .main-wrapper {
    .main-logo {
      text-align: center;
    }

    .main-title-msg {
      margin-top: 18px;
      margin-bottom: 32px;

      text-align: center;
      font-size: 27px;

      span {
        font-weight: 400;
        color: $title;
      }

      strong {
        color: $title;
        font-weight: 600;
      }

      .main-msg {
        margin-top: 10px;
        font-size: 16px;

        p {
          font-size: 15px;
          color: $gray-1;
        }
      }
    }

    .main-body {
      text-align: center;

      .user-form {
        position: relative;
        width: 310px;
        margin: auto;
        text-align: left;
      }

      .user-form .pb-container {
        display: block;
        margin: 0 0 0 auto;

        .pb-button span {
          font-size: 13px;
        }
      }

      .additor-input ~ .btn-container {
        // additor input 아래의 버튼은 margin top: 30px
        margin-top: 30px;
      }

      a {
        color: $gray-4;
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
      }

      a.forget-passwd {
        position: absolute;
        left: 0;
        bottom: 14px;
        display: inline-block;
        margin-bottom: 9px;
        z-index: 25;

        &:hover {
          color: $main-color;
        }
      }
    }
  }

  // Footer CSS
  .main-footer {
    position: relative;
    text-align: center;

    .main-footer-wrapper {
      width: 310px;
      margin: 0 auto;
      text-align: left;

      .main-footer-msg {
        margin-top: 28px;

        text-align: left;
        color: $gray-1;

        a {
          color: $gray-4;
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline;
        }

        a.forget-passwd {
          position: relative;
          bottom: initial;
          display: inline-block;
          margin-bottom: 9px;
        }

        p {
          color: $gray-3;
          font-size: 14px;
        }

        p a {
          margin-left: 5px;
        }

        a:hover {
          color: $main-color;
        }
      }

      .privacy-policy {
        margin-top: 15px;
      }
    }
  }

  &.fluid .modal-content {
    width: 100%;
  }

  &.join-team-signin {
    .main-wrapper .main-msg {
      line-height: 30px;
    }

    .main-wrapper .main-body {
      margin-top: 60px;
    }
  }
}

.fs-tab {
  .tab-wrapper {
    border-bottom: solid 1px #f2f2f2;
  }

  .tab-header {
    width: 170px;
    margin: 0 auto -1px;
    -webkit-font-smoothing: antialiased;

    .tab-selector {
      display: inline-block;
      width: 50px;
      height: 30px;
      margin: 0 17.5px;
      cursor: pointer;
      color: #989ea5;
      font: {
        size: 12px;
        weight: 600;
      }
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .tab-selector.selected {
      color: $main-color;
      border-bottom: solid 1px $main-color;
    }
  }

  .tab-body {
    min-height: 480px;
    padding-top: 35px;
  }
}
