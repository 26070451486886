
.input-group {
  width: 100%;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  margin-bottom: 8px;

  &__label {
    top: 0;
    left: 0;
    position: absolute;
    transform-origin: top left;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    font-size: 18px;
    font-weight: 500;
    transform: translate(0, 24px) scale(1);
    color: $gray300;
    padding: 0;
    line-height: 1.2;
    user-select: none;
  }

  &__label + &__input-wrap {
    margin-top: 24px;
  }

  &__input-wrap {
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    font-size: 1rem;
    line-height: 1.1875em;
    align-items: center;

    &:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid #f3f3f3;
      pointer-events: none;
    }

    &:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid $main-color;
      pointer-events: none;
    }
  }

  &__input-wrap__input {
    font: inherit;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    color: $gray800;
    border: 0;
    margin: 0;
    padding: 6px 10px 9px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }

  &__helper-text {

  }

  &.is-filled &__input-wrap:after {
    transform: scaleX(1);
    border-bottom: 2px solid $gray200;
  }

  &.is-filled &__label {
    transform: translate(0, 0) scale(0.778);
  }

  &.is-focused &__input-wrap:after {
    transform: scaleX(1);
    border-bottom: 2px solid $main-color;
  }

  &.is-focused &__label {
    transform: translate(0, 0) scale(0.778);
    color: $main-color;
  }
}
