.start-verification {
  height: calc(100vh - 96px);
  min-height: 680px;
  position: relative;
}

.start-verification__panel-wrap {
  width: 650px;
  position: absolute;
  top: calc(50% - 48px);
  left: 50%;
  transform: translate(-50%, -50%);

  .bg-texture {
    position: absolute;
    background-size: cover;

    &--triangle {
      top: -35px;
      right: -50px;
      width: 220px;
      height: 219px;
    }

    &--circle {
      bottom: 103px;
      left: -125px;
      width: 299px;
      height: 299px;
    }

    &--square {
      bottom: -30px;
      left: -60px;
      width: 244px;
      height: 289px;
    }
  }
}

.start-verification__panel {
  background: $white;

  box-sizing: border-box;
  border-radius: 10px;
  color: #222426;
  padding: 63px 123px 10px 123px;
  z-index: 1;
  position: relative;

  h1 {
    font-size: 32px;
    margin: 0 0 30px;
    font-weight: 900;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 5px;

    strong {
      color: inherit;
    }
  }

  button {
    background: transparent;
    border: none;
    font-size: 14px;
    line-height: 19px;
    color: $main-color;
    font-weight: 900;
    text-decoration: underline;
    padding: 0;
    font-family: Avenir, Open Sans, sans-serif;
    cursor: pointer;

    &:hover {
      color: #5634e0;
    }
  }

  &__banner {
    display: block;
    max-width: 329px;
    height: 325px;
    position: relative;
    margin: 32px auto 0;
    overflow: hidden;

    > video {
      position: absolute;
      margin-top: -35px;
      width: 100%;
    }
  }

  &__send-again {
    width: 100%;
    position: relative;
    margin: 7px 0 0;

    > span {
      position: absolute;
      line-height: 19px;
      z-index: 1;
    }
  }
}

.start-verification__create-ws {
  text-align: center;
  margin: 30px 0 0;
  line-height: 22px;
  font-size: 14px;
  color: $Brand-80;
  position: absolute;
  width: 100%;

  > a {
    margin-left: 8px;
    color: $white;
    font-weight: bold;
  }
}

@media (max-width: $sm-break-max) {
  .start-verification__panel-wrap {
    width: 100%;
    position: relative;
    top: initial;
    left: initial;
    transform: none;
    padding-top: 40px;

    .bg-texture {
      display: none;
    }
  }

  .start-verification__panel {
    padding: 0 30px 10px;
    margin-bottom: 24px;
  }

  .start-verification__create-ws {
    margin-top: 0;
    margin-bottom: 73px;
    color: $Gray-900;

    & > a {
      display: block;
      color: $Gray-900;
      margin: 0;
    }
  }
}
