
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border-radius: 4px;
  color: $white;
  background: $main-color;
  font-family: Avenir, 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1em;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &--icon {
    padding: 1px 1.5rem 2px;

    .icon {
      margin-right: .75rem;
    }
  }

  &.text {
    &--left {
      text-align: left;
    }
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
  }

  &:active {
    background: darken($main-color, 2%);
  }

  &--white {
    background: $white;
    border: 1px solid $main-color;
    color: $main-color;

    &:hover {
      background: $main-color;
      color: $white;
    }

    &:active {
      background: darken($main-color, 2%);
    }
  }

  &--full-white {
    background: $white;
    border: 1px solid $gray100;
    color: $gray300;

    &:hover {
      background: darken($main-color, 2%);
    }

    &:active {
      background: darken($main-color, 2%);
    }
  }

  &--gray {
    background: $gray600;

    &:hover {
      background: $main-color;
    }

    &:active {
      background: darken($main-color, 2%);
    }
  }

  &--round {
    border-radius: 2.5rem;
  }

  &--minimal {
    background: $white;
    color: $gray600;
    font-weight: normal;
    justify-content: flex-start;
    padding-left: .875rem;

    &:hover {
      background: rgba($gray200, .2);
    }

    &:active {
      background: rgba($gray200, .2);
    }
  }

  &--shadow-on-hover {
    transition: box-shadow .25s ease;
    &:hover {
      @include shadow(0, 1px, 7px,rgba(117, 82, 246, 0.6));
    }
  }

  &--shadow-on-hover.button--full-white {
    &:hover {
      @include shadow(0, 1px, 7px, $gray200);
    }
  }
}
