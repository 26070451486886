.form__group {
  position: relative;
  &--error {
    &__wrap {
      margin-bottom: -26px;
    }
  }
}

.form__label {
  font-size: 14px;
  font-weight: 900;
  line-height: 14px;
  color: $Gray-900;
}

.form__error {
  color: $Red-500-2;
  line-height: 18px;
  font-size: 13px;
  font-weight: 500;
  height: 18px;
  margin: 8px 0 0;
}