$main-color: #7552f6;
$main-color50: #b1a0f6;
$white: #fff;

$gray25: #f7f7f7;
$gray40: #fbfbfb;
$gray50: #f9f9f9;
$gray80: #f5f5f5;
$gray100: #efeff0;
$gray200: #e7e7e8;
$gray300: #d6d6d8;
$gray400: #bfc0c2;
$gray500: #a2a3a7;
$gray600: #828488;
$gray700: #606367;
$gray800: #404346;
$gray900: #222426;

// new color

$Black: #000000;
$White: #ffffff;

$Brand-50: #f7f5ff;
$Brand-80: #dad1ff;
$Brand-100: #beadff;
$Brand-200: #a48dff;
$Brand-300: #9378ff;
$Brand-400: #8263ff;
$Brand-500: #7552f6;
$Brand-600: #6c49fa;
$Brand-700: #623ff0;
$Brand-800: #5634e0;
$Brand-900: #4929cc;

$Blue-Gray-50: #fafafc;
$Blue-Gray-80: #f8f8fb;
$Blue-Gray-100: #f2f3f6;
$Blue-Gray-200: #e8e9ee;
$Blue-Gray-300: #d9dbe1;
$Blue-Gray-400: #c5c8cf;
$Blue-Gray-500: #acb0b8;
$Blue-Gray-600: #90959d;
$Blue-Gray-700: #727880;
$Blue-Gray-800: #545a62;
$Blue-Gray-900: #383e44;

$Gray-50: #f9f9f9;
$Gray-80: #f5f5f5;
$Gray-100: #efeff0;
$Gray-200: #e7e7e8;
$Gray-300: #d6d6d8;
$Gray-400: #bfc0c2;
$Gray-500: #a2a3a7;
$Gray-600: #828488;
$Gray-700: #606367;
$Gray-800: #404346;
$Gray-900: #222426;

$Indigo-50: #eff1f2;
$Indigo-100: #cfd6dd;
$Indigo-200: #b1bcc8;
$Indigo-300: #95a4b3;
$Indigo-400: #7b8c9d;
$Indigo-500: #637688;
$Indigo-600: #4e6073;
$Indigo-700: #3a4c5e;
$Indigo-800: #293948;
$Indigo-900: #1b2733;

$Red-50: #ffe6e6;
$Red-100: #ffdedf;
$Red-200: #ffcecf;
$Red-300: #feb2b5;
$Red-400: #fd8c91;
$Red-500: #fa626a;
$Red-500-2: #fa5760;
$Red-600: #f23c47;
$Red-700: #e3202d;
$Red-800: #c50f1d;
$Red-900: #990814;

$Blue-50: #e6f4ff;
$Blue-100: #d7edff;
$Blue-200: #bee2fd;
$Blue-300: #98d0fa;
$Blue-400: #67b8f6;
$Blue-500: #349dee;
$Blue-600: #0e86e3;
$Blue-700: #016dd1;
$Blue-800: #0259b8;
$Blue-900: #084799;

$Green-50: #f2fffb;
$Green-100: #e9fcf6;
$Green-200: #d2f6ea;
$Green-300: #adecd8;
$Green-400: #7ddfc0;
$Green-500: #4ccca4;
$Green-600: #26b489;
$Green-700: #0f9572;
$Green-800: #04725a;
$Green-900: #014d41;

/*
 * 정의되지 않은 컬러
 */
$Brand-950: #3b314d;

// TODO: TEMP
/*
 * Color 값 변수를 다룬다.
 */
$main-color: #7552f6;
$main-color2: #8363fb;
$highlight-main-color: #ebe6ff;
//$main-color-lighter: rgba(117, 82, 246, 0.1);
//$main-color-light: rgba(117, 82, 246, 0.15);
//$main-color-light-1: rgba(117, 82, 246, 0.2);
//$main-color-light-2: rgba(117, 82, 246, 0.4);

$main-color-lightest: #fcfbfe;
$main-color-lighter: #f1eefe;
$main-color-light: #eae5fe;
$main-color-light-1: #e3dcfd;
$main-color-light-2: #c8bafb;
$main-color-dark: #5641c3;

$warning-color: #f04e47;
$warning-color-dark: #e81c13;
$warning-color-800: #d23832;
$warning-color-lightest: #fffcfc;

$white0: #f9f9f9;
$white1: #e4e4e4;

$gray: #7f7f7f;
$gray0: #8f8f8f;
$gray1: #cccccc;
$gray2: #e5e5e5;
$light-gray: #f2f2f2;
$lighter-gray: #f9f9f9;
$light-gray-2: #f8f8f8;
$black1: #5b656f;

$font-light-black: black;
$font-gray: #8f8f8f;
$font-hover: #5f5f5f;

$hovering-gray: #e4e4e4;
$dark-gray: #cccccc;

$border-light-gray: #e4e4e4;

$font-gray: #8f8f8f;
$font-hover: #5f5f5f;

$font-basic: #3a434c;
$font-sub: #252b32;

// v1.3.3
$title: #314151;
$sub-title: #465563;
$black-2: #475563;

$white-2: #f2f2f4;
$white-3: #f5f5f8;
$white-4: #ededf0;
$white-5: #f7f7f8;
$white-6: #f8f8f9;
$white-7: #f9f9fa;

$gray-0: #eaeaee;
$gray-1: #939ca6;
$gray-2: #6c7883;
$gray-3: #c7cbd0;
$gray-4: #b1b7bd;
$gray-5: #989ea5;
$gray-6: #6b7d8f;

$temp-gray: #d2d2d2;

$silver-0: #e8e9eb;
$silver-1: #bbc0c5;
$silver-2: #c6cbd0;
$silver-3: #c7cbd0;
$silver-4: #d4d8dc;

$steel-1: #7e8892;
$steel-2: #637282;

$dark-gray-blue: #253b51;
$dark-gray-blue-2: #324251;
$dark-blue-gray: #233d57;

$purple-1: #8363fc;

//$background-1: #fbfbfc;
$background-1: #fff;
$hl-mark-color: #dcd4fd;

$warning-red: #cc0000;
$red-3: #f74e63;
